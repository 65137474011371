import React from 'react'
import Logo from '../Logo'
import TopFilters from './TopFilters'
import AddressInput from './AddressInput'
import DatumInput from './DatumInput'
import { Link } from '../Link'
import TopMenu from './TopMenu'
import SearchInput from './SearchInput'
import { useDictionary } from '../../dictionary'
import { useMobileUi } from '../../hooks/ui'

const Header = () => {
  const dictionary = useDictionary()
  const isMobile = useMobileUi()

  if (isMobile) {
    return (
      <header className="App-header mobile print-hide">
        <TopMenu />

        <div className="menu-middle menu-middle--mobile">
          <DatumInput mobileVersion={true} />
          <AddressInput mobileVersion={true} />
        </div>

        <div className="form-group w-100 m-0">
          <SearchInput />
        </div>
      </header>
    )
  }

  return (
    <header className="App-header">
      <TopMenu />

      <div className="content text-center">
        <div className="menu position-relative">
          <Link to="/" className="menu-left d-inline-block">
            <Logo />
          </Link>

          <div className="menu-middle">
            <div className="menu-upper text-left position-relative">
              <div className="form-group">
                <label>{dictionary.labels.datum}</label>
                <DatumInput mobileVersion={false} />
              </div>

              <div className="form-group">
                <label>{dictionary.labels.where}</label>
                <AddressInput mobileVersion={false} />
              </div>

              <div className="form-group">
                <SearchInput />
              </div>
            </div>
          </div>

        </div>

        <div className="menu-bottom text-left position-relative">

          <div className="text-left d-inline-block">
            <ul className="header-list m-0">
              <TopFilters />
            </ul>
          </div>

          {
            // <SearchInput />
          }

        </div>
      </div>
    </header>
  )
}

export default Header
