import React, { useState } from 'react'
import { length, replace } from 'ramda'
import { useDictionary } from '../../dictionary'

const CHAR_NUM_TO_HIDE = 300
// @ts-ignore
const displayMore = (text: string | undefined | null) => text && length(text) > CHAR_NUM_TO_HIDE

type Props = {
  text: string | null | undefined,
}

const MoreText = ({ text }: Props) => {
  const [opened, open] = useState(false)
  const dictionary = useDictionary()

  return (
    <React.Fragment>
    {
      !text ? null :
      <div className={`category-info-content-wrapper${(opened || !displayMore(text)) ? ' is-open' : ''}`}>
        <div
          className="category-info-content"
          dangerouslySetInnerHTML={{__html: replace(/(\\r|\\n)/g, '', text)}}
        />
      </div>
    }
    {
      !displayMore(text) ? null :
      <div className="clickable see-more" onClick={() => open(!opened)}>
        <span className="mr-decent">
          {
            opened ? dictionary.actions.showLess : dictionary.actions.showMore
          }
        </span>
        <i className={`fas fa-angle-${opened ? 'up' : 'down'}`} />
      </div>
    }
    </React.Fragment>
  )
}

export default MoreText
