import React from 'react'
import { useSelector } from 'react-redux'
import { selectCatalog } from '../store/selectors/api'

const CatalogContent = ({ children }: any) => {
  const catalog = useSelector(selectCatalog)

  if (!catalog) {
    return null
  }

  return children
}

export default CatalogContent
