import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { selectTheme } from '../store/selectors/ui'
import { useDictionary } from '../dictionary'
import { Link } from '../components/Link'
import { getLogout } from '../store/actions/api'

const Page404 = () => {
  const dictionary = useDictionary()
  const dispatch = useDispatch()
  const location = useLocation()
  // const themeSegment = useSelector(selectTheme) === 'family' ? 'r' : 'b'

  return (
    <div className="page-404">
      <div className="mt-2 mb-2">
        <i className="fas fa-search" />
      </div>
      {
        // themeSegment !== segment
        // ? dictionary.errors.page404WrongSegment({
        //   segment: themeSegment,
        //   Link: ({ children }) => <RouterLink to={location.pathname} onClick={() => dispatch(getLogout())}>{children}</RouterLink>,
        // })
        // : <>
        //   <span> {dictionary.errors.page404} </span>
        //   <Link
        //     to={'/'}
        //   >
        //     {dictionary.errors.page404Link}.
        //   </Link>
        // </>
      }
      <span> {dictionary.errors.page404} </span>
      <Link
        to={'/'}
      >
        {dictionary.errors.page404Link}.
      </Link>
    </div>
  )
}

export default Page404
