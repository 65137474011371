import React, { useEffect } from 'react'
import { path, map } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderHistoryItem } from '../../store/actions/api'
import { useRouteMatch } from 'react-router-dom'
import { selectOrderHistoryItem } from '../../store/selectors/api'
import { useDictionary, provideDictionary } from '../../dictionary'
import { createTable } from '../../hocs/table'
import { PreservingLink as Link } from '../Link'
import { useRetail } from '../../hooks/api'
import PayOrderButton from './PayOrderButton'
import { formatPrice } from '../../utils'

const OrderLink = ({ id, item }: { id: string, item: any }) => {
  const match = useRouteMatch('/me/history/:id')
  // return (
  //   <Link className="link" to={`/me/history/${path(['params', 'id'], match)}/${item['url']}`}>
  //     {item['kod']}
  //   </Link>
  // )
  // @TODO not in production yet
  return (
    <div>
      {item['kod']}
    </div>
  )
}

const Table = createTable({
  itemKey: path(['url']),
  columns: [{
    id: 'kod',
    header: provideDictionary(({ dictionary }) => <div>{dictionary.tables.orderHistoryItemList.code}</div>),
    renderer: OrderLink,
  },{
    id: 'label',
    header: provideDictionary(({ dictionary }) => <div>{dictionary.tables.orderHistoryItemList.label}</div>),
    renderer: ({ id, item }) => <div>{item['label']}</div>,
  },{
    id: 'amount',
    header: provideDictionary(({ dictionary }) => <div className="m-text-left text-right">{dictionary.tables.orderHistoryItemList.amount}</div>),
    renderer: ({ id, item }) => <div className="m-text-left text-right">{item['qty']}</div>,
  },{
    id: 'unit',
    header: provideDictionary(({ dictionary }) => <div className="m-text-left text-right">{dictionary.tables.orderHistoryItemList.unit}</div>),
    renderer: ({ id, item }) => <div className="m-text-left text-right">{item['unit']}</div>,
  },{
    id: 'netprice',
    header: provideDictionary(({ dictionary }) => {
      return (
        <div className="m-text-left text-right">
          {dictionary.tables.orderHistoryItemList.price}
          {' '}
          {dictionary.labels.netPrice}
        </div>
      )
    }),
    renderer: ({ id, item }) => <div className="m-text-left text-right">{formatPrice(item['netprice'])} Kč</div>
  },{
    id: 'grossprice',
    header: provideDictionary(({ dictionary }) => {
      return (
        <div className="m-text-left text-right">
          {dictionary.tables.orderHistoryItemList.price}
          {' '}
          {dictionary.labels.grossPrice}
        </div>
      )
    }),
    renderer: ({ id, item }) => <div className="m-text-left text-right">{formatPrice(item['grossprice'])} Kč</div>
  },
  ]
})

const OrderDetail = () => {
  const dispatch = useDispatch()
  const dictionary = useDictionary()
  const isRetail = useRetail()
  const match = useRouteMatch('/me/history/:id')
  const id = path(['params', 'id'], match) as string | undefined
  const item = useSelector(selectOrderHistoryItem(id || ''))

  useEffect(() => {
    if (id) {
      dispatch(getOrderHistoryItem(id))
    }
  }, [id, dispatch])

  if (!item) {
    return null
  }
  
  return (
    <div className="table">
      <div className="table-head">
        <h1>{dictionary.tables.orderHistoryItemList.orderNumber} {item.docnum}</h1>
        <div className="table-head-actions">
          <div className="table-head-action button-back">
            <Link to="/me/history"><i className="fas fa-chevron-left"/> {dictionary.tables.orderHistoryItemList.back}</Link>
          </div>
          {
            isRetail
            ? <div className="mr-1 table-head-action">
              <PayOrderButton order={item} />
            </div>
            : null
          }
        </div>
      </div>

      <div>
        {
          map(order => {
            return (
              <div key={order.id}>
                <div className="table-content mt-2 mb-2">
                  <div className="d-flex">
                    <div className="table-content-item">{dictionary.labels.address}</div>
                    <div>{order.address}</div>
                  </div>
                  <div className="d-flex">
                    <div className="table-content-item">{dictionary.labels.deliveryTime}</div>
                    <div>{order.delivery}</div>
                  </div>
                </div>

                <Table list={order.items} ignore={isRetail ? 'netprice' : 'grossprice'}/>
                <div className="table-head">
                  <div className="table-content">
                    {dictionary.labels.priceTotal}
                  </div>
                  <div className="table-head-actions">
                    <div className="mr-1">{formatPrice(isRetail ? order.grossprice : order.netprice)} Kč</div>
                  </div>
                </div>

              </div>
            )
          }, item.orders)
        }
      </div>
    </div>
  )
}

export default OrderDetail
