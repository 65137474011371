import React from 'react'
import { connect } from 'react-redux'
import { path, map, reduce } from 'ramda'
import { fetchOnMount } from '../../hocs/fetch'
import { getOrder, deleteDeliveryTime } from '../../store/actions/api'
import { OrderType } from '../../store/types/order'
import Loader from '../Loading'
import MinOrder from './MinOrder'
import DeliveryTime from '../DeliveryTime'
import ListItem from './ListItem'
import ToOrderButton from '../buttons/ToOrder'
import EditDeliveryTimeButton from './EditDeliveryTimeButton'
import { formatPrice } from '../../utils'
import Price from './Price'
import { useDictionary, cleanText } from '../../dictionary'
import { useRetail } from '../../hooks/api'

// Empty state
const EmptyOrder = () => {
  const dictionary = useDictionary()
  return (
    <div className="empty-order centered position-absolute">
      <div className="empty-order-icon">
        <i className="fas fa-receipt" />
      </div>
      <div className="empty-order-content">
        {dictionary.info.emptyOrder}
      </div>
    </div>
  )
}

// Order state
type Props = {
  cart: undefined | OrderType,
  deleteDeliveryTime: (params: { cartId: number, timeCode: string }) => void,
}

const Content = ({ cart, deleteDeliveryTime }: Props) => {
  const dictionary = useDictionary()
  const isRetail = useRetail()

  if (!cart || !cart.dlv_times) {
    return <EmptyOrder />
  }
  return (
    <div className="order-box-item">
      {
        map(delivery => {
          return (
            <React.Fragment key={delivery.timecode}>
              <div className="order-box-item-time">
                <strong>{dictionary.labels.deliveryTime}: </strong>
                <DeliveryTime code={delivery.timecode} />
                <EditDeliveryTimeButton code={delivery.timecode} />
                <i
                  className="fas fa-info-circle ml-decent"
                  title={cleanText(isRetail ? dictionary.html.addProductToCartInfoRetail : dictionary.html.addProductToCartInfo)}
                />
                <i className="fas fa-times float-right clickable" onClick={() => {
                  if (global.confirm('Opravdu chcete smazat celou objednávku v tomto čase doručení?')) {
                    deleteDeliveryTime({
                      cartId: cart.id,
                      timeCode: delivery.timecode,
                    })
                  }
                }}/>
              </div>
              {
                map(product => {
                  return (
                    <ListItem key={product.id} product={product} cart={cart} delivery={delivery} />
                  )
                }, delivery.items)
              }

              <div className="order-box-item-row order-box-item-row--grey">
                <div className="order-box-item-row-content">
                  <div className="order-item-content">
                    <div className="bold">{dictionary.labels.subtotal}</div>
                    <div className="text-right d-block">
                      <div className="d-inline-block">
                        <div>
                          <strong>
                            <Price gross={reduce((sum, product) => sum + product.grossprice, 0, delivery.items)} net={reduce((sum, product) => sum + product.netprice, 0, delivery.items)} />
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <MinOrder endPrice={delivery.minordtot} />
            </React.Fragment>
          )
        }, cart.dlv_times || [])
      }

      <h3> {dictionary.labels.orderSummary} </h3>

      <div className="order-box-summary cart-sum">
        <strong className="d-block"> {dictionary.labels.priceTotal} </strong>
        <table>
          <tbody>
            <tr>
              <td>{dictionary.labels.price} {dictionary.labels.netPrice}</td>
              <td className="text-right">{formatPrice(cart.endpricenet)} Kč </td>
            </tr>
            <tr>
              <td>{dictionary.labels.price} {dictionary.labels.grossPrice}</td>
              <td className="text-right">{formatPrice(cart.endpricegross)} Kč </td>
            </tr>
            {
            // <tr>
            //   <td><i className="fas fa-truck" /> Standard</td>
            //   <td className="text-right">125 Kč bez DPH</td>
            // </tr>
            }
          </tbody>
        </table>
      </div>

      <div className="order-box-summary cart-sum text-center mb-1 mr-1">
        <ToOrderButton />
      </div>
    </div>
  )
}

const ConnectedOrder = connect(state => ({
  cart: path(['api', 'order', 'cart'], state),
  // @ts-ignore @TODO we need to make State app type
}), { deleteDeliveryTime })(Content)

const OrderContent = () =>
  <Loader id="order">
    <ConnectedOrder />
  </Loader>

export default fetchOnMount({
  fetchAction: () => getOrder()
})(OrderContent)
