import React from 'react'
import { connect } from 'react-redux'
import { path } from 'ramda'
import { setUi } from '../../store/actions/ui'
import { DialogType } from '../../store/types/ui'
import DialogWrapper from './DialogWrapper'
import ImageCarousel from '../ImageCarousel'

type Props = {
  dialog: DialogType,
  setUi: (ui: { openedDialog: DialogType }) => void,
}

const ProductPreview = ({ dialog, setUi }: Props) =>
  <DialogWrapper className="dialog-content dialog-content--large dialog-content-v-90vw">
    <ImageCarousel
      large
      useKeyboard
      className="preview-zoom"
      images={path(['product', 'img_set'], dialog)}
      alt={path(['product', 'label'], dialog)}
      onClick={() => setUi({ openedDialog: { id: null }})}
      defaultSlide={dialog.imageIndex}
    />
  </DialogWrapper>

export default connect(null, { setUi })(ProductPreview)
