// App's store connectors according to url
import React from 'react'
import { path } from 'ramda'
import { connect } from 'react-redux'
import { useParams, Route, Switch } from 'react-router-dom'

const connectCategory = connect((state, props) => path(['category'], props)
  ? ({ category: path(['api', 'categories', 'register', path(['api', 'categories', 'byUrl', path(['category'], props) || ''], state) || ''], state) })
  // @ts-ignore
  // : ({ category: path(['api', 'categories', 'register', head(path(['api', 'categories', 'list'], state) || [])], state) })
  : {}
)

export const provideCategory = <P extends Record<string, unknown>>(Component: React.ComponentType) => {
  // @ts-ignore
  const Connector = connectCategory(Component)
  const Router = (props: P) => {
    const { category } = useParams()
    // @ts-ignore
    return <Connector category={category} {...props} />
  }

  const Wrapper = (props: any) =>
    <Switch>
      <Route path="/:category">
        <Router {...props} />
      </Route>
      <Route path="">
        <Router {...props} />
      </Route>
    </Switch>
  
  return Wrapper
}