import React from 'react'
import { max } from 'ramda'
import { useHistory } from 'react-router-dom'
import { setSearchParam } from '../utils'

type Props = {
  offset: number,
  limit: number,
  currentLength: number,
  maxLength: number | undefined,
}

const Paginator = (props: Props) => {
  const history = useHistory()
  const pageNumber = props.offset/props.limit

  return (
    <div className="paginator">
      {
        props.offset <= 0 ? <div className="paginator-item paginator-item--fake" /> :
        <div
          className="paginator-item"
          onClick={() => {
            history.push(`${setSearchParam('offset', max(0, props.offset - props.limit))(history.location.search)}`)
          }}
        >
          {pageNumber}
        </div>
      }
      <div className="paginator-item paginator-item--active">
        {pageNumber+1}
      </div>
      {
        (props.currentLength < props.limit) || (props.maxLength && (props.maxLength <= props.offset + props.limit)) ? <div className="paginator-item paginator-item--fake" /> :
        <div
          className="paginator-item"
          onClick={() => {
            history.push(`${setSearchParam('offset', props.offset + props.limit)(history.location.search)}`)
          }}
        >
          {pageNumber+2}
        </div>
      }
    </div>
  )
}

export default Paginator
