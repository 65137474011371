import React, { useEffect } from 'react'
import Image from './Image'
import { map, addIndex } from 'ramda'

import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

const mapIndexed = addIndex(map)

type Props = {
  images: undefined | string[],
  large?: boolean,
  useKeyboard?: boolean,
  [imageProp: string]: any,
}

const ImageCarousel = ({ images, className, large, onClick, useKeyboard, defaultSlide, ...props }: Props) => {
  useEffect(() => {
    const simulateClick = (orientation: 'left' | 'right') => {
      const el = document.getElementById(`carousel-${orientation}`)
      if (el && typeof el.click === 'function') {
        el.click()
      }
    }

    const callback = (e: KeyboardEvent) => {
      if (e.keyCode === 37) {
        simulateClick('left')
      } else if (e.keyCode === 39) {
        simulateClick('right')
      }
    }

    if (useKeyboard) {
      window.addEventListener('keyup', callback)
    }

    return () => {
      if (useKeyboard) {
        window.removeEventListener('keyup', callback)
      }
    }
  }, [ useKeyboard ])

  if (!images) {
    return null
  }

  return (
    <CarouselProvider
      naturalSlideWidth={large ? window.innerWidth : 100}
      naturalSlideHeight={large ? window.innerHeight : 60}
      totalSlides={images.length}
      className={className}
      currentSlide={defaultSlide || undefined}
    >
      <Slider>
        {
          mapIndexed(
            // @ts-ignore
            (imageSrc: string, index: number) =>
              <Slide key={index} index={index}>
                <Image code={imageSrc} onClick={onClick ? (() => onClick(index)) : undefined} {...props} />
              </Slide>,
            images
          )
        }
      </Slider>
      <ButtonBack
        id={useKeyboard ? 'carousel-left' : undefined}
        className="carousel-button carousel-button--back"
      >
        <i className="fas fa-angle-left" />
      </ButtonBack>
      <ButtonNext
        id={useKeyboard ? 'carousel-right' : undefined}
        className="carousel-button carousel-button--next"
      >
        <i className="fas fa-angle-right" />
      </ButtonNext>
    </CarouselProvider>
  )

  // return (
  //   <Image code={images[0]} {...props} />
  // )
}

export default ImageCarousel
