import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import './styles/index.sass'
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import { render } from './app'
import { isDevelopment } from './config'

Sentry.init({
  dsn: "https://795f5fea5f0a4647bd0a2e07add6431e@o461503.ingest.sentry.io/5463381",
  environment: isDevelopment ? "development" : "production",
  integrations: [
    new Integrations.BrowserTracing(),
  ],
})

// @TODO resolve! Bluebird for some reason is giving
// Warning: .then() only accepts functions but was passed: [object Undefined], [object Undefined]
// with redux-state-sync middleware
// global.Promise = require('bluebird')

render(App)

// Hot-reloading
// @ts-ignore
if (isDevelopment && module.hot) {
  // @ts-ignore
  module.hot.accept('./components/App', () => {
    const NextApp = require('./components/App').default
    render(NextApp)
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
