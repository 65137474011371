import { assocPath, dissocPath, path } from 'ramda'
import { FETCH_RESOURCE_START, FETCH_RESOURCE_COMPLETE, FETCH_RESOURCE_ERROR, SIGN_OUT, DISMISS_ERROR } from '../actions/fetch'
import { RESET_ENV } from '../actions/ui'
import { StateType, FetchStatusType } from '../types/fetch'
import { FetchActionType } from '../types/actions'

const initialState: StateType = {
  status: {
    get: {

    },
    put: {

    },
    post: {

    },
    delete: {

    }
  }
}

const setStatus = (action: FetchActionType, status: FetchStatusType) => assocPath([action.payload.method, ...action.payload.resourcePath], {
  type: status,
  code: action.payload.status,
  reason: path(['data', 'Reason'], action.payload),
  status: path(['data', 'Status'], action.payload),
  detail: path(['data', 'detail'], action.payload),
})

export default (state: StateType = initialState, action: FetchActionType) => {
  switch(action.type) {
    default: return state

    case DISMISS_ERROR:
      return {
        ...state,
        status: {
          ...state.status,
          put: dissocPath(path(['formPath'], action.payload) || [], state.status.put),
          post: dissocPath(path(['formPath'], action.payload) || [], state.status.post),
        }
      }

    case RESET_ENV:
      return {
        ...state,
        status: dissocPath(['get', 'order'], state.status),
      }

    case SIGN_OUT:
      return {
        ...initialState,
        status: {
          ...initialState.status,
          get: {
            languages: path(['languages'], state.status.get),
            config: path(['config'], state.status.get),
          }
        }
      }

    case FETCH_RESOURCE_START:
      return ({
        ...state,
        status: setStatus(action, 'pending')(state.status)
      })

    case FETCH_RESOURCE_COMPLETE: {
      return ({
        ...state,
        status: setStatus(action, 'saved')(state.status)
      })
    }

    case FETCH_RESOURCE_ERROR:
      return ({
        ...state,
        status: setStatus(action, 'error')(state.status)
      })
  }
}
