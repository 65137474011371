import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppStateType } from '../../store/types'
import { useDictionary } from '../../dictionary'
import { setUi } from '../../store/actions/ui'
import { useMobileUi } from '../../hooks/ui'

const ProductListAppearanceSwitch = (): JSX.Element | null => {
  const dictionary = useDictionary()
  const dispatch = useDispatch()
  const isMobile = useMobileUi()
  const appearance = useSelector((state: AppStateType) => state.ui.productListAppearance)

  if (isMobile) {
    return null
  }

  return (
    <div className="appearance-container">
      <div className="appearance-label">
        { dictionary.labels.appearance }:
      </div>
      <div className="appearance-switch">
        <div
          className={`appearance-switch-item${!appearance || appearance === 'list' ? ' is-active' : ''}`}
          onClick={() => {
            if (appearance !== 'list') {
              dispatch(setUi({ productListAppearance: 'list' }))
            }
          }}
        >
          <i className="fas fa-grip-lines" />
        </div>
        <div
          className={`appearance-switch-item${appearance === 'grid' ? ' is-active' : ''}`}
          onClick={() => {
            if (appearance !== 'grid') {
              dispatch(setUi({ productListAppearance: 'grid' }))
            }
          }}
        >
          <i className="fas fa-grip-vertical" />
        </div>
      </div>
    </div>
  )
}

export default ProductListAppearanceSwitch
