import React from 'react'
import { path, compose, sum, map } from 'ramda'
import DialogWrapper from './DialogWrapper'
import { DialogType } from '../../store/types/ui'
import { useDictionary } from '../../dictionary'
import OrderError from '../order/OrderError'

type Props = {
  dialog: DialogType,
}

const PayOrder = ({ dialog }: Props) => {
  const dictionary = useDictionary()

  const price = path(['price'], dialog.data) || compose(
    sum,
    // @ts-ignore
    map((order: any) => path(['price'], order))
  )(path(['orders'], dialog.data) || []) || ''

  return (
    <DialogWrapper className="dialog-content dialog-content--no-overflow">
      <div>
        <h1>
          {dictionary.actions.pay} {path(['docnum'], dialog.data)}
        </h1>
        <h2 className="mt-2">
          {dictionary.labels.pay} {price} Kč
        </h2>

        <OrderError payId={`${path(['pay_id'], dialog.data) || path(['id'], dialog.data)}`} newPaymentType />
      </div>
    </DialogWrapper>
  )
}

export default PayOrder
