import React from 'react'
import { path, map } from 'ramda'
import { useLocation } from 'react-router-dom'
import Link from '../Link'
import { extractSearchParams } from '../../utils'
import { connect, useDispatch } from 'react-redux'
import { postLogin } from '../../store/actions/api'
import { createForm } from '../../hocs/form'
import Logo from '../Logo'
import { anonymousLoginEnabled } from '../../config'
import { selectLanguage } from '../../store/selectors/api'
import { useDictionary } from '../../dictionary'
import { Form as EnvForm, languageInputComponents } from './EnvironmentForm'
import Email from '../../contact/Email'
import ChangeEnvironmentButton from '../header/ChangeEnvironmentButton'

const LoginForm = createForm(['login'], {
  Email: { type: 'email', key: 'email' },
  Password: { type: 'password', key: 'password' },
})

type Props = {
  // rememberMe: boolean,
  languages: object[] | undefined,
  defaultLanguage: undefined | string,
}

const Login = ({ languages, defaultLanguage }: Props) => {
  const dictionary = useDictionary()
  const dispatch = useDispatch()
  const params = extractSearchParams(useLocation().search || '')
  return (
    <div className="dialog-content position-relative">
      <div className="dialog-content-inner">
        <div className="login-switch-env">
          <ChangeEnvironmentButton instant />
        </div>
        <EnvForm.Language
          className="select-language"
          isSearchable={false}
          components={languageInputComponents}
          defaultValue={{
            label: params.lang || defaultLanguage,
            value: params.lang || defaultLanguage,
          }}
          options={map(lan => ({
            label: path(['lang'], lan),
            value: path(['lang'], lan),
          }), languages || [])}
        />
        <LoginForm.Error defaultMessage={dictionary.errors.credentials} />
        <div className="heading">
          <h1> {dictionary.forms.login.heading} </h1>
          <a href="/">
            <Logo />
          </a>
        </div>

        <form id="login-form" onSubmit={e => e.preventDefault()}>
          <div className="text-left">
            <label> {dictionary.forms.login.email} </label>
            <div className="input-group input-group--login mb-1">
              <LoginForm.Email autoComplete="username"/>
            </div>
            <label> {dictionary.forms.login.password} </label>
            <div className="input-group input-group--login mb-1">
              <LoginForm.Password autoComplete="current-password" />
            </div>
            <div>
              {
                // Now deprecated
                // Let users browser decide
                // <RememberMe />
              }
              <Link className="lost-password-link" to="/password-reset-request">
                {dictionary.labels.lostPassword}
              </Link>
            </div>
          </div>

          <LoginForm.Submit className="btn w-100" type="submit" value={dictionary.forms.login.submit} />

        </form>


        <div className="register">
          {
            anonymousLoginEnabled() ? null :
            <div className="text"> {dictionary.forms.login.info} </div>
          }
          {
            !anonymousLoginEnabled() ?
              <div className="login-contact">
                <Email>{dictionary.actions.contact}</Email>
              </div>
            : <>
              <div className="btn btn--primary btn--no-reg mb-1" onClick={() => {
                dispatch(postLogin({}, true))
              }}>
                { dictionary.buttons.withoutRegistration }
              </div>
              <div className="text">
                { dictionary.buttons.withoutRegistrationDetail }
              </div>
            </>
          }
        </div>

      </div>
    </div>
  )
}

export default connect(state => ({
  // rememberMe: Boolean(path(['ui', 'rememberMe'], state)),
  languages: selectLanguage(state).options,
  defaultLanguage: selectLanguage(state).chosen,
  // @ts-ignore
}))(Login)
