import React, { useState, useEffect } from 'react'
import { map, path, isEmpty } from 'ramda'
import { connect } from 'react-redux'
import { selectDeliveryTimes } from '../../store/selectors/api'
import { setUi } from '../../store/actions/ui'
import { putDeliveryTime, resourcePaths } from '../../store/actions/api'
import { DialogType } from '../../store/types/ui'
import { DeliveryTimeFrameType } from '../../store/types/api'
import DialogWrapper from './DialogWrapper'
import Select from 'react-select'
import { PutLoader } from '../Loading'
import { useDictionary } from '../../dictionary'
import { convertArrayToObject } from '../../utils'
import { createFetchStatusAlert } from '../../hocs/alert'

type Props = {
  dialog: DialogType,
  setUi: (ui: { openedDialog: DialogType }) => void,
  putDeliveryTime: (a: any) => void,
  deliveryTimes: DeliveryTimeFrameType[] | undefined,
  activeDeliveryTimes: undefined | null | {timecode: string}[],
  cartId: number | undefined,
}

const loaderPath = resourcePaths.order()
const Alert = createFetchStatusAlert({ method: 'put', formPath: loaderPath })

const ChangeDeliveryTimeDialog = ({ dialog, setUi, deliveryTimes = [], putDeliveryTime, activeDeliveryTimes = [], cartId }: Props) => {
  const dictionary = useDictionary()
  const [chosen, setState] = useState('')
  const timeMap = convertArrayToObject((t: DeliveryTimeFrameType) => t.id)(deliveryTimes)
  // set default value
  useEffect(() => {
    setState({
      // @ts-ignore
      value: dialog.timeCode,
      label: path([`${dialog.timeCode}`, 'text'], timeMap) || '',
    })
  }, [])

  return (
    <DialogWrapper className="dialog-content dialog-content--large dialog-content--with-min-width dialog-content--no-overflow">
      <div>

        <Alert />

        {
          isEmpty(activeDeliveryTimes || []) ? null :
          <React.Fragment>
            <div className="mb-decent"> {dictionary.labels.activeDeliveryTimes}: </div>
            {
              map(deliveryTime =>
                <div
                  key={deliveryTime.timecode}
                  className={`active-delivery${path(['value'], chosen) === deliveryTime.timecode ? ' is-selected' : ''}`}
                  onClick={() => {
                    setState({
                      // @ts-ignore
                      value: path([deliveryTime.timecode, 'id'], timeMap) || '',
                      label: path([deliveryTime.timecode, 'text'], timeMap) || '',
                    })
                  }}
                >
                  {
                    path([deliveryTime.timecode, 'text'], timeMap)
                  }
                </div>,
                activeDeliveryTimes || []
              )
            }
          </React.Fragment>
        }
        <div className="mb-decent"> {dictionary.labels.newDeliveryTime}: </div>
        {
          // @ts-ignore
          <Select
            options={map(time => ({ value: time.id, label: time.text }), deliveryTimes)}
            placeholder={dictionary.placeholders.deliveryTime}
            onChange={setState}
            value={chosen||''}
          />
        }

        <PutLoader path={loaderPath}>
          <div className="mt-2 text-center">
            <button
              className={`btn${chosen ? '' : ' btn--secondary'}`}
              disabled={!chosen}
              onClick={() => {
                putDeliveryTime({
                  cartId,
                  timeCode: dialog.timeCode,
                  newTimeCode: path(['value'], chosen),
                })
              }}
            >
              {dictionary.buttons.changeDeliveryTime}
            </button>
          </div>
        </PutLoader>
      </div>
    </DialogWrapper>
  )
}

export default connect(state => ({
  deliveryTimes: selectDeliveryTimes(state),
  activeDeliveryTimes: path(['api', 'order', 'cart', 'dlv_times'], state),
  cartId: path(['api', 'order', 'cart', 'id'], state),
  // @ts-ignore
}), { setUi, putDeliveryTime })(ChangeDeliveryTimeDialog)
