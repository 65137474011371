import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import moment from 'moment'
import { changeValue, submitForm, validateValue, clearForm } from '../store/actions/fields'
import { FieldParamsType } from '../store/types/fields'
import { selectAnonym } from '../store/selectors/api'
import { path, append, mapObjIndexed, values } from 'ramda'
import { areAllItemsTrue } from '../utils'
import { createFetchStatusAlert } from './alert'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import Select from 'react-select'
import SubmitButton from '../components/fields/SubmitButton'
import SelectRadios from '../components/fields/SelectRadios'
import Password from '../components/fields/Password'
import Hint from '../components/fields/Hint'
import DropDown from '../components/header/DropDown'
import validators from '../config/validators'
import { useDictionary } from '../dictionary'

type Props = {
  type: string,
  key: string,
  notRequired?: boolean,
  notRequiredBusiness?: boolean,
}

type FormInputs = {
  [prop: string]: Props,
}

interface FormType {
  [prop: string]: Function
}

interface InputProps {
  changeValue: (params: FieldParamsType) => void,
  defaultValue?: any,
  value: any,
  [props: string]: any,
}

export const CalendarWithLocale = (props: any) => {
  const dictionary = useDictionary()
  const [opened, open] = useState(false)

  return (
    <div
      className="input-calendar-select-wrapper"
      onClick={() => {
        open(true)
      }}
    >
      <div className="input-calendar-select">
        <div className="w-100 d-flex">
          <div className="flex-1">
            {
              (props.value && moment(props.value).format('DD.MM.yyyy')) || null
            }
            </div>
          <div className="calendar-icon-wrapper">
            <span className="separator" />
            <div className="calendar-icon">
              <i className="fas fa-calendar-alt" />
            </div>
          </div>
        </div>
      </div>
      <div className={`input-calendar-option ${opened ? '' : ' d-none'}`}>
        {
          !opened ? null : <DropDown ignoreClassName="react-calendar__navigation" open={open}>{null}</DropDown>
        }
        <Calendar locale={dictionary.id === 'czech' ? 'cs' : 'en'} {...props} />
      </div>
    </div>
  )
}

export const createInput = ({ key, type }: Props) => (formPath: string[]) =>
  connect(
    (state) => ({
      value: path(['fields', 'values', ...append(key, formPath)], state)
        || (type === 'calendar' ? new Date() : '')
    }),
    {
      changeValue, // because of defaultValue
      onChange:
        type === 'select'
        ? (e: any) => changeValue({ formPath, value: e, key })
        : type === 'calendar'
        ? (e: any) => changeValue({ formPath, value: e, key })
        : type === 'hint'
        ? (value: string) => changeValue({ formPath, value, key })
        : (e: React.ChangeEvent<HTMLInputElement>) => changeValue({ formPath, value: e.target.value, key })
    }
  )(({ changeValue, defaultValue, ...props}: InputProps) => {
    useEffect(() => {
      if (defaultValue && (!props.value || type === 'calendar')) {
        changeValue({
          formPath,
          value: defaultValue,
          key,
        })
      }
    }, []) // intentionally empty, we want to call it only on mount!
    return (
      type === 'select'
      ? <Select {...props} />
      : type === 'select-radios'
      // @ts-ignore
      ? <SelectRadios {...props} />
      : type === 'calendar'
      ? <CalendarWithLocale {...props} />
      : type === 'textarea'
      ? <textarea {...props} />
      : type === 'hint'
      ? <Hint {...props} />
      : type === 'password'
      ? <Password type={type} {...props} />
      : <input type={type} {...props} />
    )
  }
)

export const createForm = (formPath: string[], inputs: FormInputs): FormType => ({
  Submit: connect((state, pprops) => ({
    formPath,
    isDisabled: path(['isDisabled'], pprops) || !areAllItemsTrue(
      (input: Props) => {
        const validator = path(append(input.key, formPath), validators) as (a: any, i?: any) => any

        if (validator) {
          const validated = validator(
            path(['fields', 'values', ...append(input.key, formPath)], state),
            selectAnonym(state),
          )
          if (validated) {
            return false
          }
        }

        if (input.notRequired) {
          return true
        }

        if (input.notRequiredBusiness && !selectAnonym(state)) {
          return true
        }

        return path(['fields', 'values', ...append(input.key, formPath)], state)
      })(values(inputs))
  }), { submitForm })(({ submitForm, onClick, additional, ...props }: any) => <SubmitButton onClick={() => (onClick && onClick()) || submitForm(formPath, additional)} {...props} />),
  Error: createFetchStatusAlert({
    formPath,
    method: 'post',
  }),
  // Component for clearing form on unmount
  ClearOnUnmount: () => {
    const dispatch = useDispatch()
    useEffect(() => {
      return () => {
        dispatch(clearForm(formPath))
      }
    }, [])
    return null
  },
  ...mapObjIndexed((inputData: Props) => createInput(inputData)(formPath))(inputs)
})

export const createValidator = (inputPath: string[]) => connect(state => ({
  value: path(['fields', 'values', ...inputPath], state),
  state: path(['fields', 'validates', ...inputPath], state),
  isAnonym: selectAnonym(state),
}), { validateValue })(({ value, dispatch, children, className, state, isAnonym, validateValue, ...props }: { value: unknown, children: any, [prop: string]: any }) => {
  const dictionary = useDictionary()
  const [activated, activate] = useState<null | boolean>(null)
  let validate = path(inputPath, validators) as (val: any, i?: any) => any
  if (!validate) {
    validate = (val: any) => {
      if (val) {
        return false
      }
      return ['notEmpty']
    }
  }

  useEffect(() => {
    if (activated === false || value) {
      validateValue({ inputPath, value: validate(value, isAnonym) })
    }
  }, [activated, value])

  return (
    <React.Fragment>
      <div
        // className={`${className}${!state ? (value && !activated) ? ' with-validate-success' : '' : ' with-validate-error'}`}
        className={`${className}${activated ? '' : (!state && value) ? ' with-validate-success' : state ? ' with-validate-error' : ''}`}
        {...props}
      >
        {
          React.Children.map(children,
            child => React.cloneElement(child, {
              onFocus: () => {
                // if (state) {
                //   validateValue({ inputPath, value: false })
                // }
                // if (activated) {
                //   activate(false)
                // }
                activate(true)
              },
              onBlur: () => {
                // if (validate(value)) {
                //   // @ts-ignore
                //   validateValue({ inputPath, value: validate(value) })
                // }
                // if (!activated) {
                //   activate(Boolean(value && true))
                // }
                activate(false)
              }
            })
          )
        }
        {
          !state
            ? (value && !activated)
              ? <i className="fas fa-check-circle input-icon input-icon--success" />
              : null
            : (!activated) ? <i className="fas fa-times-circle input-icon input-icon--error" /> : null
        }
      </div>
      {
        !state || activated ? null :
          <div className="validate-error">
            {
              // @ts-ignore
              state && state.params ? path([state.path], dictionary.formValidators)(state.params) : path([state], dictionary.formValidators)
            }
          </div>
      }
    </React.Fragment>
  )
})
