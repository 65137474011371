import React from 'react'
import { path, map, isEmpty } from 'ramda'
import { provideCategory } from '../../hocs/appRoutes'
import SubmenuItem from './SubmenuItem'

type Props = {
  category?: object | undefined
}

const Submenu = ({ category }: Props) => {
  if (!category || isEmpty(path(['categories'], category) || [])) {
    return null
  }

  return (
    <div className="submenu">
      <div className="content">
        {
          map(cat =>
            <SubmenuItem key={path(['id'], cat)} category={cat} url={path(['url'], category)} />,
            path(['categories'], category) || []
          )
        }
      </div>
    </div>
  )
}

// @ts-ignore
export default provideCategory(Submenu)
