import React, { useState } from 'react'

type Props = {
  [props: string]: any,
}

const Password = ({ type, ...props }: Props) => {
  const [displayed, display] = useState(false)
  return (
    <React.Fragment>
      <input type={displayed ? 'text' : 'password'} {...props} />
      <span
        className="input-right-icon clickable"
        onMouseDown={() => {
          display(true)
        }}
        onMouseUp={() => {
          display(false)
        }}
        onMouseLeave={() => {
          display(false)
        }}
      >
        <i className="fas fa-eye" />
      </span>
    </React.Fragment>
  )
}

export default Password
