import React from 'react'
import { useRouteMatch } from 'react-router'
import { Link } from '../Link'
import { useDictionary } from '../../dictionary'

const BackToEshopButton = () => {
  const dictionary = useDictionary()
  const matchRecap = useRouteMatch('/order')

  return (
    <Link
      to={matchRecap ? '/cart#topMenu' : '/#topMenu'}
      className="ml-0 btn btn--secondary"
    >
      <i className="fas fa-angle-left ml-decent" />
      <span className="pl-1 mr-1">
        {
          matchRecap
          ? dictionary.buttons.backToCart
          : dictionary.buttons.backToEshop
        }
      </span>
    </Link>
  )
}

export default BackToEshopButton
