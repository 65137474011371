import { StateType } from '../types/ui'

export const SET_UI = 'SET_UI'
export const SET_AMOUNT = 'SET_AMOUNT'
export const UNSET_AMOUNT = 'UNSET_AMOUNT'
export const SET_SECTION_FILTER = 'SET_SECTION_FILTER'
export const RESET_ENV = 'RESET_ENV'

export const resetEnvironment = () => ({
  type: RESET_ENV,
})

export const setAmount = (productId: number, amount: number) => ({
  type: SET_AMOUNT,
  payload: {
    productId,
    amount,
  }
})

export const unsetAmount = (productId: number) => ({
  type: UNSET_AMOUNT,
  payload: {
    productId,
  }
})

export const setSectionFilter = (categoryId: number, sectionId: number, active: boolean) => ({
  type: SET_SECTION_FILTER,
  payload: { categoryId, sectionId, active },
})

export const setUi = (uiMap: Partial<StateType>) => ({
  type: SET_UI,
  payload: uiMap,
})

type uiCallbackType = (ui: Function) => object
export const setUiWithCallback = (uiCallback: uiCallbackType) => ({ getState }: {getState: Function}) => ({
  type: SET_UI,
  payload: uiCallback(getState),
})
