import React from 'react'
import { path, addIndex, map, replace, tail } from 'ramda'
import { connect } from 'react-redux'
import { makeHtml } from '../../utils'
import { isDevelopment } from '../../config'

const mapIndexed = addIndex(map)

type Props = {
  category: string,
  url: string,
  data?: unknown,
}

const PageContent = ({ data, category }: Props) => {
  if (isDevelopment) {
    console.log('TODO remove category !== news here')
  }
  return (
    mapIndexed((content, index) => {
      return (
        <React.Fragment key={index}>
          { !path(['name'], content) || category !== 'news' ? null : <h1> {`${path(['name'], content)}`} </h1> }
          <div className="ml-1 mr-1">
            {
              !path(['perex'], content) ? null :
              <div dangerouslySetInnerHTML={{__html: makeHtml(path(['perex'], content) || '')}} />
            }
            {
              !path(['content'], content) ? null :
              <div dangerouslySetInnerHTML={{__html: makeHtml(path(['content'], content) || '')}} />
            }
          </div>
        </React.Fragment>
      )
      // @ts-ignore
    }, data || [])
  )
}

export default connect((state, props: Props) => ({
  data: path(['api', 'content', props.category, props.url, 'data'], state),
}))(PageContent)
