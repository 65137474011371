import React from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { map } from 'ramda'
import { useSelector } from 'react-redux'
import { fetchOnMount } from '../../hocs/fetch'
import { getContentDataList } from '../../store/actions/api'
import { selectContentDataList, selectContentDataListMoreDataEnabled } from '../../store/selectors/api'
import { useDictionary } from '../../dictionary'
import { Link } from '../Link'

const LIMIT = 5

const ActualityList = () => {
  const dictionary = useDictionary()
  const dispatch = useDispatch()
  const list = useSelector(selectContentDataList('news'))
  const noMoreData = useSelector(selectContentDataListMoreDataEnabled('news'))

  if (!list || list.length < 1) {
    return null
  }

  return (
    <div className="p-1">
      <h1> { dictionary.footer.actuality } </h1>
      <div className="hor-line-after" />
      {
        map(actuality => {
          return (
            <div key={actuality.id} className="mb-1 mt-1 pb-1">
              <Link to={`/content/news/${actuality.url}`}>
                <div className="mb-decent">
                  <strong>{actuality.name}</strong>
                </div>
                <div className="mb-decent">
                  {moment(actuality.published).format('DD. MM. YYYY')}
                </div>
                <div dangerouslySetInnerHTML={{ __html: actuality.perex || '' }} />
              </Link>
            </div>
          )
        }, list)
      }

      {
        list.length % LIMIT === 0 && !noMoreData
        ? <div className="link link--underline" onClick={() => {
          dispatch(getContentDataList({ category: 'news', limit: LIMIT, offset: list.length }, true))
        }}>{dictionary.actions.loadMore}</div>
        : null
      }

    </div>
  )
}

export default fetchOnMount({
  fetchAction: () => getContentDataList({ category: 'news', limit: LIMIT })
})(ActualityList)
