import React from 'react'
import TopRightMenu from './TopRightMenu'
import Email, { useEmail } from '../../contact/Email'
import { useFacebookLink } from '../../contact/Facebook'
import Phone from '../../contact/Phone'
import { useMobileUi } from '../../hooks/ui'
import MobileTopMenu from './MobileTopMenu'
import { useConfigParam } from '../../hooks/config'

const TopMenu = () => {
  const isMobile = useMobileUi()
  const email = useEmail()
  const otime = useConfigParam('contact_phone_otime')
  const fbLabel = useConfigParam('contact_fb_label')
  const fbLink = useFacebookLink()

  if (isMobile) {
    return (
      <MobileTopMenu />
    )
  }

  return (
    <div id="topMenu" className="menu-top position-relative">
      <ul className="header-list header-list--small">
        <li> <i className="fas fa-phone-alt" /> <Phone /> {otime} </li>
        {
          email &&
          <li>
            <Email>
              <i className="fas fa-envelope" />
              <span className="mid-desktop-hide pl-1">{email}</span>
            </Email>
          </li>
        }
        {
          fbLink &&
          <li>
            <a href={fbLink}>
              <i className="fab fa-facebook-square" />
              <span className="mid-desktop-hide pl-1">{fbLabel}</span>
            </a>
          </li>
        }
      </ul>

      <TopRightMenu />
    </div>
  )
}

export default TopMenu
