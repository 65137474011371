import React from 'react'
import { path, length, map } from 'ramda'
import { connect } from 'react-redux'
import { LoadingComponent } from '../Loading'
import { ProductType } from '../../store/types/products'
import { SearchResultCategoryType } from '../../store/types/categories'
import Product from '../products/ListItem'
import CategorySearchResultItem from './CategorySearchResultItem'
import { useDictionary } from '../../dictionary' 

type Props = {
  phrase: string,
  products: ProductType[] | undefined | null,
  categories: SearchResultCategoryType[] | undefined | null,
}

const SearchResults = ({ phrase, products, categories }: Props) => {
  const dictionary = useDictionary()
  // @ts-ignore
  if (length(phrase) < 3) {
    return <div><p> Hledaný výraz musí obsahovat alespoň 3 znaky. </p></div>
  }

  if (!products || categories === undefined) {
    return <LoadingComponent />
  }

  return (
    <div className="mobile-text-content">
      <p dangerouslySetInnerHTML={{__html: dictionary.search.info(phrase, length(products || [])+length(categories || []))}} />

      {
        // @ts-ignore
        length(categories || []) > 0
          ?
            <React.Fragment>
              <h1> {dictionary.search.categories} </h1>
              <div className="subcategories-container">
                {
                  map(category => <CategorySearchResultItem key={path(['id'], category)} category={category} />, categories || [])
                }
              </div>
            </React.Fragment>
          : null
      }

      {
        // @ts-ignore
        length(products || []) > 0
          ? <h1> {dictionary.search.products} </h1>
          : null
      }
      <ul>
      {
        map(product =>
          <li key={path(['id'], product)}>
            <Product product={product} section={null} />
          </li>,
          products || []
        )
      }
      </ul>
    </div>
  )
}

export default connect((state, { phrase }: Props) => ({
  products: path(['api', 'search', 'product', phrase, 'data'], state),
  categories: path(['api', 'search', 'category', phrase, 'data'], state),
  // @ts-ignore
}))(SearchResults)
