import { path } from 'ramda'
import { connect } from 'react-redux'

type Props = {
  code: string,
  timeLabel: string,
}

const DeliveryTime = ({ timeLabel }: Props) => timeLabel

export default connect((state, props: Props) => ({
  timeLabel: path(['api', 'lists', 'deliveryTimesRegister', props.code, 'text'], state) || '',
  // @ts-ignore
}))(DeliveryTime)
