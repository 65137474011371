import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { getLogout } from '../../store/actions/api'
import { useDictionary } from '../../dictionary'
import { useAnonym } from '../../hooks/api'
import { Link } from '../Link'

type Props = {
  catalog?: boolean,
  getLogout: (a?: any) => void,
}

const LogoutButton = ({ getLogout, catalog }: Props) => {
  const dictionary = useDictionary()
  const isAnonym = useAnonym()

  if (isAnonym) {
    return (
      <Link
        to={'/'}
        className="pr-1 va-middle bold"
        onClick={() => getLogout()}
      >
        <span>{dictionary.actions.anonymLogout} </span>
        <i className="fas fa-times font-smaller" />
      </Link>
    )
  }

  return (
    <Link
      to={'/'}
      className="pr-1"
      onClick={() => getLogout()}
    >
      <i className="fas fa-sign-out-alt" />
      <span>{ catalog ? dictionary.actions.logoutCatalog : dictionary.actions.logout }</span>
    </Link>
  )
}

export default connect(null, { getLogout })(LogoutButton)
