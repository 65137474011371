import React from 'react'
import { connect } from 'react-redux'
import { selectMinOrder } from '../../store/selectors/api'
import { formatPrice } from '../../utils'
import { useDictionary } from '../../dictionary'

type Props = {
  minOrder: number,
  endPrice: number,
}

const MinOrder = ({ minOrder, endPrice }: Props) => {
  const dictionary = useDictionary()
  const left = minOrder - endPrice
  return (
    left < 0
    ? null
    : <div className="order-box-summary pb-2">
      <div className="fill-bar">
        <div
          className="fill-bar-percent"
          style={{
            width: minOrder === 0 ? '0' : `${100-(left/minOrder)*100}%`,
          }}
        />
      </div>
      <p dangerouslySetInnerHTML={{__html: dictionary.info.minOrder(formatPrice(left))}} />
    </div>
  )
}

export default connect(state => ({
  minOrder: selectMinOrder(state) || 0,
  // @ts-ignore
}))(MinOrder)
