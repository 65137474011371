import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { path, map, find } from 'ramda'
import Select from 'react-select'
import { useHistory } from 'react-router-dom'
// import { Link } from '../Link'
import { postNewPayment, resourcePaths, getPayMethods } from '../../store/actions/api'
import { fetchOnMount } from '../../hocs/fetch'
import { useDictionary } from '../../dictionary'
import { PostLoader, ResourceLoader } from '../Loading'
import { extractSearchParams } from '../../utils'

type Props = {
  payId: string,
  h1: string,
  h2: string,
  p: string,
  defaultPaymethod?: string,
  infoType?: boolean,
  newPaymentType?: boolean
}

const OrderError = ({ payId, h1, h2, p, defaultPaymethod, infoType, newPaymentType }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const dictionary = useDictionary()
  const [chosen, setState] = useState(undefined)
  const error = useSelector(path(['fetch', 'status', 'post', 'newpayment', payId, 'status']))
  const paymethods = useSelector(path(['api', 'payMethods', 'paymethods']))
  const alreadyPaid = error === 'ERR-PAID'
  const paymentNotFound = error === 'ERR-PAYNF'
  const params = extractSearchParams(path(['location', 'search'], history) || '')

  const paymethodsOptions = map(option => ({
    label: path(['label'], option),
    value: path(['id'], option),
    action: path(['action'], option),
    // @ts-ignore
  }), paymethods || [])

  // Fill default value
  const chosenValue = chosen
    ||find(option => path(['value'], option) === defaultPaymethod, paymethodsOptions)
    || find(option => path(['action'], option) === path(['action'], params), paymethodsOptions)

  return (
    <div className="thank-you">
      <h1>{h1}</h1>
      <div>
        {
          alreadyPaid
            ? <i className="fas fa-check big-icon" />
            : infoType
            ? <i className="fas fa-info-circle big-icon info pl-0" />
            : newPaymentType
            ? null
            : <i className="fas fa-times-circle big-icon error" />
        }
      </div>
      <h2>{alreadyPaid ? dictionary.errors.PAID : paymentNotFound ? dictionary.errors.PAYNF : h2}</h2>
      <p>{alreadyPaid || paymentNotFound ? null : p}</p>

      <ResourceLoader method="get" path={resourcePaths.payMethods()}>
        <PostLoader path={resourcePaths.newPayment(payId)}>
          <div className="mt-2">
            {
              // <Link to="/reset" className="btn btn--secondary ml-0">
              //   { dictionary.buttons.createNewOrder }
              // </Link>
            }
            {
              alreadyPaid || paymentNotFound ? null :
              <>
                <p>{dictionary.forms.order.paymentMethods}</p>
                <Select
                  className="mr-1 mb-2"
                  placeholder={newPaymentType ? dictionary.placeholders.paymethod : dictionary.placeholders.anotherPaymethod }
                  options={paymethodsOptions}
                  // @ts-ignore
                  onChange={(a) => setState(a)}
                  value={chosenValue}
                />
              
                <a
                  href="#repay"
                  className={`btn btn--primary ml-0${Boolean(path(['value'], chosenValue)) ? '' : ' is-disabled'}`}
                  onClick={() => Boolean(path(['value'], chosenValue)) && dispatch(postNewPayment({ payId, paymethod: path(['value'], chosenValue), onSuccess: ({ data, status }) => {
                    if (status < 300 && !path(['redir'], data)) {
                      history.push(`/thank-you?order=${path(['orderId'], params) || payId}`)
                    }
                  }}))}
                >
                  { newPaymentType ? dictionary.actions.pay : dictionary.buttons.paymentRepeat }
                </a>
              </>
            }
          </div>
        </PostLoader>
      </ResourceLoader>
    </div>
  )
}

export default fetchOnMount({
  fetchAction: () => getPayMethods(),
})(OrderError)
