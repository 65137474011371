import { reduce, path } from 'ramda'
import { FieldParamsType, FieldValidateType } from '../types/fields'
import { LoginFormType } from '../types/api'
import { extractSearchParams } from '../../utils'
import { selectAnonym } from '../selectors/api'
import { selectTheme } from '../selectors/ui'
import { postLogin, postEnv, postOrder, putPassword, putPasswordReset, postSendPasswordLogin } from './api'
import moment from 'moment'

const buildPath = reduce((a, b) => `${a}/${b}`, '')
const getFormData = <P extends object>(formPath: string[], getState: () => object) => path<P>(['fields', 'values', ...formPath], getState())

export const CHANGE_VALUE = 'CHANGE_VALUE'
export const SUBMIT_FORM = 'SUBMIT_FORM'
export const VALIDATE_VALUE = 'VALIDATE_VALUE'
export const VALIDATE_FORM = 'VALIDATE_FORM'
export const CLEAR_FORM = 'CLEAR_FORM'

export const changeValue = (params: FieldParamsType) => ({
  type: CHANGE_VALUE,
  payload: params,
})

export const validateValue = (params: FieldValidateType) => ({
  type: VALIDATE_VALUE,
  payload: params,
})

export const validateForm = (formPath: string[]) => ({ getState }: { getState: () => any }) => ({
  type: VALIDATE_FORM,
  payload: {
    formPath,
    isAnonym: selectAnonym(getState()),
  }
})

export const clearForm = (formPath: string[]) => ({
  type: CLEAR_FORM,
  payload: {
    formPath,
  }
})

export const submitForm = (formPath: string[], additional?: any) => ({ getState, dispatch }: { getState: () => object, dispatch: Function }) => {
  const apiPath = buildPath(formPath)

  // @TODO wtf why switch doesn't work here? :)
  if (apiPath === '/login') {
    const formData = getFormData<LoginFormType>(formPath, getState)
    if (formData) {
      dispatch(postLogin(formData))
    }
  } else if (apiPath === '/choose') {
    const chooseData = getFormData(formPath, getState)
    if (chooseData) {
      dispatch(postEnv({
        lang: path(['lang', 'value'], chooseData) || path(['api', 'login', 'lang'], getState()) || '',
        id_customer: path(['id_customer', 'value'], chooseData) || 0,
        id_serving: path(['id_serving'], chooseData) || '',
        id_dlv_addr: path(['id_dlv_addr', 'value'], chooseData) || 0,
        date: moment(path(['date'], chooseData) || '').format('YYYY-MM-DD'),
      }))
    }
  } else if (apiPath === '/order') {
    const orderData = getFormData(formPath, getState)
    // const isAnonym = selectAnonym(getState())

    // const otherData = dissoc('to_person_name', orderData)

    // @ts-ignore
    dispatch(postOrder({
      // @ts-ignore
      ...orderData,
      // ...(
      //   isAnonym
      //     ? { address: `${path(['street'], orderData)}, ${path(['city'], orderData)}`}
      //     : {}
      // ),
      phone: path(['to_person_phone'], orderData),
      name: path(['to_person_name'], orderData),
      id_cart: path(['api', 'order', 'cart', 'id'], getState()) || 0,
      to_person_phone: `${path(['to_person_name'], orderData) || ''}, ${path(['to_person_phone'], orderData) || ''}`,
    }, additional))
  } else if (apiPath === '/password/change') {
    const passwordData = getFormData(formPath, getState)
    dispatch(putPassword({
      oldPassword: path(['old'], passwordData) || '',
      newPassword: path(['new'], passwordData) || '',
    }))
  } else if (apiPath === '/password/reset') {
    const passwordData = getFormData(formPath, getState)
    dispatch(putPasswordReset({
      token: path(['token'], extractSearchParams(window.location.search)) || '',
      newPassword: path(['new'], passwordData) || '',
    }))
  } else if (apiPath === '/password/send') {
    const passwordData = getFormData(formPath, getState)
    dispatch(postSendPasswordLogin({
      username: path(['username'], passwordData) || '',
      seg: selectTheme(getState()),
    }))
  }

  return ({
    type: SUBMIT_FORM,
    payload: {
      formPath,
    }
  })
}
