import React from 'react'
import { path } from 'ramda'
// import { getCustomer } from '../../store/actions/api'
import { useSelector } from 'react-redux'
import { AppStateType } from '../../store/types'
import { CustomerType } from '../../store/types/customer'
import { useDictionary } from '../../dictionary'
import { selectSignedUser, selectCustomerData } from '../../store/selectors/api'

const Invoice = () => {
  const dictionary = useDictionary()
  const customer = useSelector(selectCustomerData)
  // useEffect(() => {
  //   if (customerId || customerId === 0) {
  //     getCustomer(customerId as number)
  //   }
  // }, [customerId, getCustomer])

  if (!customer) {
    return null
  }

  return (
    <div className="invoice">
      <div>{customer.name}</div>
      { customer.ico ? <div>{dictionary.labels.id}: {customer.ico}</div> : null }
      { customer.dic ? <div>{dictionary.labels.id2}: {customer.dic}</div> : null }
      { customer.email ? <div>{customer.email}</div> : null }
      { customer.addr1 ? <div>{customer.addr1}</div> : null }
      { customer.addr2 ? <div>{customer.addr2}</div> : null }
      { customer.addr3 ? <div>{customer.addr3}</div> : null }
    </div>
  )
}

export default Invoice
// export default connect((state: AppStateType) => ({
//   customerId: path(['id'], selectSignedUser(state) || ''),
//   customer: selectCustomerData(state),
// }), { getCustomer })(Invoice)
