import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { path, length } from 'ramda'
import { useRouteMatch } from 'react-router-dom'
import { getProductSearch, getCategorySearch } from '../store/actions/api'
import SearchResults from '../components/search/SearchResults'
import { useDictionary } from '../dictionary'

type Props = {
  getProductSearch: ({ phrase }: { phrase: string }) => void,
  getCategorySearch: ({ phrase }: { phrase: string }) => void,
}

const SearchPage = ({ getProductSearch, getCategorySearch }: Props) => {
  const dictionary = useDictionary()
  const match = useRouteMatch('/search/:phrase')
  const phrase = path(['params', 'phrase'], match)
  useEffect(() => {
    // @ts-ignore
    if (phrase && length(`${phrase}`) >= 3) {
      getProductSearch({ phrase: `${phrase}` })
      getCategorySearch({ phrase: `${phrase}` })
    }
  }, [phrase, getProductSearch, getCategorySearch])
  return (
    <React.Fragment>
      <h1>{dictionary.search.heading}</h1>
      <SearchResults phrase={`${phrase||''}`} />
    </React.Fragment>
  )
}

export default connect(null, { getProductSearch, getCategorySearch })(SearchPage)
