import React from 'react'
import { connect } from 'react-redux'
import { setSectionFilter } from '../../store/actions/ui'
import { path } from 'ramda'
import Checkbox from '../fields/Checkbox'

export type SectionType = {
  id: number,
  name: string,
}

type Props = {
  section: SectionType,
  isSelected: boolean,
  categoryId: number | undefined,
  setSectionFilter: (categoryId: number, sectionId: number, active: boolean) => void
}

const Section = ({ section, isSelected, categoryId, setSectionFilter }: Props) =>
  <div className="subcategory-item">
    <Checkbox
      id={section.name}
      checked={!isSelected}
      onChange={(val) => setSectionFilter(categoryId || 0, section.id, !val)}
    />
    <a href={`#${section.id}`}>{section.name}</a>
  </div>

export default connect((state, props: Props) => ({
  isSelected:
    Boolean(path(['ui', 'filterCategory', props.categoryId || 0, props.section.id], state)),
}), { setSectionFilter })(Section)
