import React, { useEffect } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { path } from 'ramda'
import { setUi } from '../../store/actions/ui'
import { getContentData } from '../../store/actions/api'
import { DialogType, StateType as UiStateType } from '../../store/types/ui'
import { AppStateType } from '../../store/types'
import { selectTheme } from '../../store/selectors/ui'
import DialogWrapper from './DialogWrapper'
import { useDictionary } from '../../dictionary'
import PageContent from '../content/PageContent'

const ActualityDialog = () => {
  const dictionary = useDictionary()
  const dispatch = useDispatch()
  const actuality = useSelector(path(['api', 'footer', 'news', 'data', 0])) as undefined | {url: string}
  const lastOpened = useSelector((state: AppStateType) => state.ui.lastNewDayOpened)
  const theme = useSelector(selectTheme)

  useEffect(() => {
    if (actuality) {
      const datum = moment().format('DD')
      dispatch(getContentData('news', actuality.url))
      dispatch(setUi({
        lastNewDayOpened: {
          R: theme === 'R' ? datum : ((path(['R'], lastOpened) as string | undefined) || null),
          B: theme === 'B' ? datum : ((path(['B'], lastOpened) as string | undefined) || null),
        }
      }))
    }
  }, [actuality, dispatch, theme])

  if (!actuality) {
    return null
  }

  return (
    <DialogWrapper className="dialog-content dialog-actuality">
      <div>
        <PageContent url={actuality.url} category="news" />
        <div className="text-center mt-2 m-text-center">
          <a
            href="#close"
            className="btn btn--primary ml-0"
            onClick={() => dispatch(setUi({ openedDialog: { id: null }}))}
          >
            { dictionary.actions.close }
          </a>
        </div>
      </div>
    </DialogWrapper>
  )
}

export default ActualityDialog
