import React from 'react'
// import { useSelector } from 'react-redux'
import { path } from 'ramda'
import { provideCategory } from '../../hocs/appRoutes'
// import { path, isEmpty  } from 'ramda'
import IndexPageContent from './IndexPageContent'
import { Route, Switch, Redirect } from 'react-router-dom'
import FetchAndDisplayProductList from './FetchAndDisplayProductList'
import FetchAndDisplayProductDetail from './FetchAndDisplayProductDetail'
import OrderPage from '../../pages/order'
import SearchPage from '../../pages/search'
import PaymentLandingPage from '../../pages/paymentLanding'
import OrderSuccessPage from '../../pages/orderSuccess'
import ContentPage from '../../pages/content'
// import OrderCompleted from '../order/OrderCompleted'
import MePage from '../../pages/me'
import CartPage from '../../pages/cart'
import Page404 from '../../pages/404'
// import { useSegmentUrl } from '../../hooks/url'
// import { selectTheme } from '../../store/selectors/ui'

const ContentRouter = ({ category }: { category?: object | null }) => {
  // const segment = useSegmentUrl()
  // const themeSegment = useSelector(selectTheme) === 'family' ? 'r' : 'b'

  return (
    <div className="position-relative">
      {
        !category
        // ? <div> Ups, nastala chybka :O </div>
        // : isEmpty(path(['categories'], category) || [])
        ?
          <Switch>
            <Route path={`/thank-you`} exact>
              <OrderSuccessPage />
            </Route>
            <Route path={`/payment`} exact>
              <PaymentLandingPage />
            </Route>
            <Route path={`/order`} exact>
              <OrderPage />
            </Route>
            <Route path={`/search`}>
              <SearchPage />
            </Route>
            <Route path={`/me`}>
              <MePage />
            </Route>
            <Route path={`/content`}>
              <ContentPage />
            </Route>
            <Route path="/cart">
              <CartPage />
            </Route>
            <Route path={`/autologin`}>
              <Redirect to={`/`} />
            </Route>
            <Route path="/:category">
              <Page404 />
            </Route>
            <Route path={`/`} exact>
              <IndexPageContent />
            </Route>
          </Switch>
        :
          <Switch>
            <Route path={`/:category/category/:categoryId/subcategory/:subcategoryId`} exact>
              <FetchAndDisplayProductList />
            </Route>
            <Route path={`/:category/category/:categoryId/section/:section/product/:productUrl`} exact>
              <FetchAndDisplayProductDetail />
            </Route>
            <Route path={`/:category/category/:categoryId/product/:productUrl`} exact>
              <FetchAndDisplayProductDetail />
            </Route>
            <Route path={`/:category/category/:categoryId`} exact>
              <FetchAndDisplayProductList />
            </Route>
            <Route path={`/:category`} exact>
              {
                path(['categories', 0, 'id'], category)
                ? <Redirect to={`/${path(['url'], category)}/category/${path(['categories', 0, 'id'], category)}`} />
                : <FetchAndDisplayProductList mainCategoryId={path(['id'], category)} />
              }
            </Route>
            <Route path="/">
              <Page404 />
            </Route>
          </Switch>
      }
    </div>
  )
}

// @ts-ignore
export default provideCategory(ContentRouter)
