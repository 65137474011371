import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDictionary } from '../dictionary'
import { setUi } from '../store/actions/ui'
import { AppStateType } from '../store/types'

const CookieConsent = () => {
  const dictionary = useDictionary()
  const dispatch = useDispatch()
  const cookieConsent = useSelector((state: AppStateType) => state.ui.cookieConsent)

  if (cookieConsent) {
    return null
  } else {
    return (
      <div className="cookie-consent">
        <div className="w-100 d-flex">
          <p className="d-inline-block mr-1">
            <span role="img" aria-label="cookie">🍪</span>
            <span className="pl-1"> {dictionary.cookie.consent} </span>
          </p>
          <div className="d-inline-block ml-auto mt-auto mb-auto mr-2">
            <button className="btn btn--primary ml-0" onClick={() => dispatch(setUi({ cookieConsent: true }))}>
              {dictionary.cookie.ok}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default CookieConsent
