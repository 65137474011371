import { path } from 'ramda'
import { connect } from 'react-redux'

type Props = {
  id: number,
  label: string,
}

const Unit = ({ label }: Props) => label

// @ts-ignore
export default connect((state, props: Props) => ({ label: path(['api', 'lists', 'Units', props.id, 'code'], state) || '' }))(Unit)
