import React from 'react'
import OrderContent from './OrderContent'
import { useDictionary } from '../../dictionary'
import { StickyContainer, Sticky } from 'react-sticky'
import Help from './Help'

export const OrderBoxContent = ({ style, hideHeading }: { style?: any, hideHeading?: boolean }) => {
  const dictionary = useDictionary()
  return (
    <div>
      <div className="order-box position-relative">
        {
          hideHeading ? null :
          <h2>{dictionary.labels.order}</h2>
        }
        <div className="order-box-content">
          <OrderContent />
        </div>
      </div>
      <Help />
    </div>
  )
}

const OrderBox = () =>
  <StickyContainer className="right-panel-container">
    <Sticky>
      {
        ({ style }) =>
          <div className="right-panel-scrollable" style={style}>
            <OrderBoxContent />
          </div>
      }
    </Sticky>
  </StickyContainer>

export default OrderBox
