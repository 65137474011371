import React from 'react'
import { ProductType } from '../../../store/types/products'
import { useDictionary } from '../../../dictionary'
import Unit from '../../Unit'

type Props = {
  product: ProductType
}

const Info = ({ product }: Props) => {
  const dictionary = useDictionary()
  return (
    <div className="product-content-row product-info">
      {
        !product.tags.new ? null :
        <span className="badge badge--new">
          { dictionary.tags.new }
        </span>
      }
      {
        !product.props.adv_days ? null :
        <span>
          <i className="fas fa-calendar-day" /> {dictionary.tags.advDays(product.props.adv_days)}
        </span>
      }
      {
        !product.props.collection ? null :
        <span title={dictionary.tags.collectionHint}>
          <i className="fas fa-truck" /> {dictionary.labels.cartage}
        </span>
      }
      {
        !product.props.granularity || product.props.granularity === 1 ? null :
        <span>
          <i className="fas fa-box-open" /> {dictionary.tags.granularity} {product.props.granularity} <Unit id={product.id_unit} />
        </span>
      }
      {
        !product.props.minorder || product.props.minorder === 1 ? null :
        <span>
          <i className="fas fa-greater-than-equal" /> {dictionary.tags.minOrder} {product.props.minorder} <Unit id={product.id_unit} />
        </span>
      }
      {
        !product.props.maxorder ? null :
        <span>
          <i className="fas fa-less-than-equal" /> {dictionary.tags.maxOrder} {product.props.maxorder} <Unit id={product.id_unit} />
        </span>
      }
      {
        !product.props.timecond ? null :
        <span title={dictionary.tags.timeHint(product.props.timecond)}>
          <i className="fas fa-clock" /> {dictionary.tags.time} {product.props.timecond}
        </span>
      }
      {
        !product.props.pers_from ? null :
        <span>
          {
          // <i className="fas fa-users" /> Vhodné pro {product.props.pers_from} {product.props.pers_to ? ` - ${product.props.pers_to}` : null} {(product.props.pers_from === 1 && !product.props.pers_to) ? 'osobu' : 'osob'}
          }
          <i className="fas fa-users" /> {dictionary.labels.suitableFor(product.props.pers_from, product.props.pers_to || 0)}
        </span>
      }
    </div>
  )
}

export default Info
