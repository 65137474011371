import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { replace } from 'ramda'

const ScrollHandler = () => {
  const location = useLocation()
  useEffect(() => {
    if (location.hash === '#topMenu') {
      window.scrollTo(0, 0)
    }

    // Scroll to the section
    // Use of replace :)
    replace(/\d+/, (digit: any) => {
      const el = document.getElementById(digit)
      if (el) {
        el.scrollIntoView()
      }
      return ''
    }, location.hash)

  }, [location.hash, location.pathname])

  return null
}

export default ScrollHandler
