import React from 'react'
import { useSelector } from 'react-redux'
import { Link as ReactRouterLink, useLocation } from 'react-router-dom'

type Props = {
  to: string,
  exact?: boolean,
  [prop: string]: any,
}

export const Link = ({ to, exact, ...props }: Props) => {
  // const themeSegment = useSelector(selectTheme)
  // return <ReactRouterLink to={exact ? to : `/${themeSegment}${to}`} {...props} />
  return <ReactRouterLink to={to} {...props} />
}

// Local links preserving segment
export const PreservingLink = ({ to, ...props }: Props) => {
  const location = useLocation()
  return <Link to={location.search ? `${to}${location.search}` : to} {...props} />
}

export default PreservingLink
