import React, { useState } from 'react'
import { path, map } from 'ramda'
import { useSelector } from 'react-redux'
import Image from '../Image'
import { useRouteMatch } from 'react-router-dom'
import { Link } from '../Link'
import SubmenuItem from '../header/SubmenuItem'
import { selectCategoryByUrl, selectSubcategory } from '../../store/selectors/api'

export const Item = ({ id, onClick }: { id: number, onClick: () => void }) => {
  const match = useRouteMatch('/:category')
  const category = useSelector(state => path(['api', 'categories', 'register', id], state))
  const isActive = path(['params', 'category'], match) === path(['url'], category)

  return (
    <React.Fragment>
      <div
        className={`mobile-top-filter-item mobile-top-filter-item--subitem ${isActive ? ' is-selected' : ''}`}
        onClick={onClick}
      >
        <Link
          key={path(['id'], category)}
          to={`/${path(['url'], category)}`}
        >
          <div className={`top-filter-item${isActive ? ' is-selected':''}`}>
            <Image width="30" height="30" code={path(['image'], category)} />
            <span> {path(['name'], category)} </span>
          </div>
        </Link>
      </div>
    </React.Fragment>
  )
}

const MobileTopFilters = () => {
  const [opened, open] = useState(false)
  const [openedSub, openSub] = useState(false)
  const categories = useSelector(state => path(['api', 'categories', 'list'], state)) || []
  const match = useRouteMatch('/:category')
  const subcategoryMatch = useRouteMatch('/:category/category/:subcategory')
  const productSectionMatch = useRouteMatch('/:category/category/:subcategory/section/:section/product/:productUrl')
  const productMatch = useRouteMatch('/:category/category/:subcategory/product/:productUrl')
  const activeCategory = useSelector(state => selectCategoryByUrl(state, path(['params', 'category'], match)))
  const activeSubcategory = useSelector(state => selectSubcategory(state, path(['params', 'subcategory'], subcategoryMatch)))

  if (!activeCategory) {
    return null
  }

  if (productMatch || productSectionMatch) {
    return (
      <div className="mobile-submenu-container">
        <Link
          to={
            path(['name'], activeSubcategory)
              ? `/${path(['params', 'category'], match)}/category/${path(['params', 'subcategory'], subcategoryMatch)}`
              : `/${path(['params', 'category'], match)}`
          }
        >
          <div className="mobile-top-filter-item">
            <i className={`fas fa-angle-left`} />
            <span> {path(['name'], activeSubcategory) || path(['name'], activeCategory)} </span>
          </div>
        </Link>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="mobile-submenu-container">
        <div className={`mobile-top-filter-item${opened ? ' opened' : ''}`} onClick={() => open(!opened)}>
          <div className="top-filter-item">
            <Image width="30" height="30" code={path(['image'], activeCategory)} />
            <span> {path(['name'], activeCategory)} </span>

            <i className={`fas fa-angle-${opened ? 'up' : 'down'}`} />
          </div>
        </div>
        {
          !opened ? null :
          // @ts-ignore
          map(categoryId => <Item key={categoryId} id={categoryId} onClick={() => open(false)}/>, categories)
        }
      </div>
      {
        !path(['categories'], activeCategory) || !activeSubcategory ? null :
        <div className="mobile-submenu-container">
          <div className={`mobile-top-filter-item${openedSub ? ' opened' : ''}`} onClick={() => openSub(!openedSub)}>
            <div className="top-filter-item">
              <Image width="30" height="30" code={path(['image'], activeSubcategory)} />
              <span> {path(['name'], activeSubcategory)} </span>

              <i className={`fas fa-angle-${openedSub ? 'up' : 'down'}`} />
            </div>
          </div>

          {
            !openedSub ? null :
            <div className="submenu">
              <div className="content">
                {
                  map(cat => <SubmenuItem key={path(['id'], cat)} category={cat} url={path(['url'], activeCategory)} onClick={() => openSub(false)}/>, path(['categories'], activeCategory) || [])
                }
              </div>
            </div>
          }
        </div>
      }
    </React.Fragment>
  )
}

export default MobileTopFilters
