import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { selectCanProceedToOrder } from '../../store/selectors/api'
import { setUi } from '../../store/actions/ui'
import { useMobileUi } from '../../hooks/ui'
import { Link } from '../Link'
import { useDictionary } from '../../dictionary'

type Props = {
  display: boolean
}

const ToOrderButton = ({ display }: Props) => {
  const matchOrderPage = useRouteMatch('/order')
  const matchCartPage = useRouteMatch('/cart')
  const dispatch = useDispatch()
  const isMobile = useMobileUi()
  const dictionary = useDictionary()
  if (!display || matchOrderPage) {
    return null
  }

  return (
    <Link
      to={matchCartPage ? '/order#topMenu' : '/cart#topMenu'}
      className="btn d-block mr-0"
      onClick={() => {
        if (isMobile) {
          dispatch(setUi({ mobileOrderOpened: false }))
        }
      }}
    >
      <i className="fas fa-truck mr-decent"/>
      {
        matchCartPage
        ? dictionary.buttons.finishOrder
        : dictionary.buttons.toCartPage
      }
    </Link>
  )
}

export default connect(state => ({
  display: selectCanProceedToOrder(state),
}))(ToOrderButton)
