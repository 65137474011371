import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { resetEnvironment } from '../store/actions/ui'

type Props = {
  resetEnvironment: () => void,
}

const Reset = ({ resetEnvironment }: Props) => {
  const history = useHistory()

  useEffect(() => {
    resetEnvironment()
    setTimeout(() => history.push('/'), 200)
  }, [])
  return (
    <div className="login-page position-relative">
      <div className="dialog-overlay">
      </div>
    </div>
  )
}

export default connect(null, { resetEnvironment })(Reset)
