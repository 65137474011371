import React from 'react'
import { path } from 'ramda'
import { useSelector } from 'react-redux'
import { useLocation, Redirect } from 'react-router-dom'
import { extractSearchParams } from '../utils'
import { useDictionary } from '../dictionary'
import OrderError from '../components/order/OrderError'
import OrderCompleted from '../components/order/OrderCompleted'
import { selectCreatedOrder } from '../store/selectors/api'

// A page after paygate redirect
const PaymentLandingPage = () => {
  const dictionary = useDictionary()
  const location = useLocation()
  const params = extractSearchParams(location.search || '')
  const createdOrder = useSelector(selectCreatedOrder)
  
  // This happens when order is created and we want to initialize payment
  if (params.type === 'outgoing') {
    return (
      <OrderCompleted
        preventAutoRedirect={!Boolean(path(['pay_id'], createdOrder))}
        redir={path(['redir'], createdOrder) || global.decodeURIComponent((params.redir || '') as string)}
        action={path(['action'], createdOrder) || params.action}
        payId={path(['pay_id'], createdOrder) || params.pay_id}
        docnum={path(['docnum'], createdOrder) || params.docnum}
      />
    )
  }

  if (params.done === 'true') {
    return (
      <Redirect to={`/thank-you?order=${path(['docnum'], createdOrder)}`} />
    )
  }

  if (path(['resultCode'], params) === '0' && path(['paymentStatus'], params) === '3') {
    return (
      <OrderError
        payId={`${path(['pay_id'], params)}`}
        defaultPaymethod={path(['pm'], params)}
        h1={dictionary.thankYou.headingCanceled}
        h2={dictionary.thankYou.descriptionCanceled}
        p={dictionary.thankYou.descriptionCanceledInstructions}
      />
    )
  }

  if (path(['resultCode'], params) !== '0' || (
      path(['paymentStatus'], params) !== '4' &&
      path(['paymentStatus'], params) !== '7' &&
      path(['paymentStatus'], params) !== '8'
    )) {
    return (
      <OrderError
        payId={`${path(['pay_id'], params)}`}
        defaultPaymethod={path(['pm'], params)}
        h1={dictionary.thankYou.headingError}
        h2={dictionary.thankYou.orderPaidError}
        p={dictionary.thankYou.orderPaidDescriptionError}
      />
    )
  }

  return (
    <Redirect to={`/thank-you?order=${path(['orderId'], params)}`} />
  )
}

export default PaymentLandingPage
