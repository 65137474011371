import React from 'react'
import ReactDOM from 'react-dom'
import { map } from 'ramda'
import { useConfigParam } from '../hooks/config'

const Favicon = () => {
  const favicon = useConfigParam('favicon')
  const title = useConfigParam('title')
  // Favicon is in JSON format?? Who cares...
  const faviconObject = typeof favicon === 'string' ? JSON.parse(favicon) : favicon

  return ReactDOM.createPortal(
    <>
      {
        map((favicon: any) => {
          return <link key={favicon.href} {...favicon} />
        }, faviconObject?.links || [])
      }
      <title>{title||''}</title>
    </>,
    document.head,
  )
}

export default Favicon
