import React from 'react'
import { useDispatch } from 'react-redux'
// import { Link }  from 'react-router-dom'
import { setUi } from '../../store/actions/ui'
import { getLogout } from '../../store/actions/api'
import DialogWrapper from './DialogWrapper'
import { useDictionary } from '../../dictionary'
import { useLanguage } from '../../hooks/api'
import { switchSegmentLabel, switchSegmentLink, buildAbsoluteLink } from '../../config'

const ChangeEnvironmentDialog = () => {
  const dictionary = useDictionary()
  const dispatch = useDispatch()
  const language = useLanguage()

  return (
    <DialogWrapper className="dialog-content dialog-content--large">
      <div>
        {
          // <p className="text-center">{dictDialog.info}</p>
        }
        <h1>{switchSegmentLabel(language)}</h1>

        <div className="mt-2 text-center">
          <a
            // to={`/${isRetail ? 'b' : 'r/autologin'}`}
            href={switchSegmentLink(language)}
            className="link link--underline mr-1"
            onClick={() => {
              dispatch(setUi({ openedDialog: { id: null } }))
              dispatch(getLogout())
            }}
          >
            {dictionary.dialogs.changeDeliveryAddress.yes}
          </a>

          <button className="btn btn--primary ml-1" onClick={() => dispatch(setUi({ openedDialog: { id: null } }))}>
            {dictionary.dialogs.changeDeliveryAddress.no}
          </button>
        </div>
      </div>
    </DialogWrapper>
  )
}

export default ChangeEnvironmentDialog

