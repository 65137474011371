import React from 'react'
import { useDispatch } from 'react-redux'

const showFile = (blob: any, name: string = "file") => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  var newBlob = new Blob([blob], {type: "application/pdf"})

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  } 

  // For other browsers: 
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement('a');
  link.href = data;
  link.download=`${name}.pdf`;
  link.click();
  setTimeout(function(){
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}


export const providePDFDownload = (Component: any) => (props: any) => {
  const dispatch = useDispatch()

  const downloadPDF = (url: string, name: string) => {
    dispatch(({ fetch }: { fetch: (a: any) => Promise<any> }) => {
      fetch({ resource: url })
        .then(res => res.blob())
        .then(r => showFile(r, name))

      return ({
        type: 'DOWNLOAD_PDF'
      })
    })
  }

  return <Component downloadPDF={downloadPDF} {...props} />
}
