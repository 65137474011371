import { ActionType } from '../store/types/actions'
import { Dispatch } from 'redux'
import { AppStateType } from '../store/types'
import { isMobile } from '../utils'
import { setUi } from '../store/actions/ui'

// Creates listener for window's size change event
export default ({ getState, dispatch }: { getState: () => AppStateType, dispatch: Dispatch<ActionType> }): void => {
  const onResize = () => {
    const isMobileNow = isMobile()
    const wasMobile = getState().ui.layoutType === 'mobile'

    if (isMobileNow !== wasMobile) {
      dispatch(setUi({ layoutType: isMobileNow ? 'mobile' : 'desktop' }))
    }
  }
  window.addEventListener('resize', onResize)
}
