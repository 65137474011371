import { path } from 'ramda'
import { selectLanguage } from './api'
import { AppStateType } from '../types'

export const selectConfigParam = (key: string) => (state: AppStateType): string => {
  return path([
    'api',
    'config',
    'data',
    'params_lang',
    key,
    selectLanguage(state).chosen as string,
  ], state) || ''
}
