import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { path } from 'ramda'
import { getCategory } from '../../store/actions/api'
import MoreText from './MoreText'

type Props = {
  url: string | undefined
  getCategory: (url: string) => void,
  categoryId: string | undefined,
  name: string | undefined | null,
  text: string | undefined | null,
}

const CategoryInfo = ({ getCategory, url, name, text }: Props) => {
  useEffect(() => {
    if (url) {
      getCategory(url)
    }
  }, [url, getCategory])

  return (
    <div className={`category-info${text ? ' with-text' : ''}`}>
      {
        !name ? null :
        <h1>{name}</h1>
      }
      <MoreText text={text} />
    </div>
  )
}

export default connect((state, props: Props) => {
  const url = path(['api', 'categories', 'subRegister', props.categoryId || '', 'url'], state) as string | undefined
  return ({
    url,
    name: path(['api', 'categories', 'urlRegister', url || '', 'category', 'name'], state) || path(['api', 'categories', 'subRegister', props.categoryId || '', 'name'], state),
    text: path(['api', 'categories', 'urlRegister', url || '', 'category', 'text'], state),
  })
  // @ts-ignore
}, { getCategory })(CategoryInfo)
