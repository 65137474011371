import React, { useEffect } from 'react'
import { extractSearchParams } from '../utils'
import { path } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { postLogin } from '../store/actions/api'
import { LoadingComponent } from '../components/Loading'
import { changeValue } from '../store/actions/fields'

type Props = {
  redirect?: boolean,
}

const Autologin = ({ redirect }: Props) => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(state => path(['api', 'login', 'token'], state))
  const history = useHistory()

  useEffect(() => {
    if (!isLoggedIn) {
      // Extract lang param and use it before signin in
      const params = extractSearchParams(window.location.search)
      if (params.lang === 'CSY' || params.lang === 'ENU') {
        dispatch(changeValue({
          key: 'lang',
          formPath: ['choose'],
          value: {label: params.lang, value: params.lang}
        }))
      }
      // log anonym user in
      dispatch(postLogin({}, true))
    } else {
      // redirect user if logged in
      if (redirect) {
        history.push('/')
      }
    }
  }, [isLoggedIn, redirect])

  return (
    <div>
      <LoadingComponent />
    </div>
  )
}

export default Autologin
