import React, { useEffect } from 'react'
import { connect } from 'react-redux'

type Props = {
  fetch: Function,
  [prop: string]: any,
}

export const fetchOnMount = ({ fetchAction }: {fetchAction: (p: any) => void}) => (Component: any) => connect(null, { fetch: fetchAction })(
  ({ fetch, ...props }: Props) => {
    useEffect(() => {
      fetch(props)
    }, [fetch])
    return <Component {...props} />
  }
)
