import React, { useEffect } from 'react'
import { isEmpty, path, map, dissoc, split } from 'ramda'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderHistory } from '../../store/actions/api'
import { selectOrderHistoryList, selectOrderHistoryListLength } from '../../store/selectors/api'
import { provideDictionary, useDictionary } from '../../dictionary'
// import { OrderHistoryListItem } from '../../store/types/order'
import { createTable } from '../../hocs/table'
import { providePDFDownload } from '../../hocs/pdfDownload'
import { useRetail } from '../../hooks/api'
import { PreservingLink as Link } from '../Link'
import Paginator from '../Paginator'
import { extractSearchParams, formatPrice } from '../../utils'
import PayOrderButton from './PayOrderButton'
import HistoryFilterButton from './HistoryFilterButton'
import HistoryFilterList from './HistoryFilterList'
import { useConfigParam } from '../../hooks/config'

const PaystatusOrActionName = ({ id, item }: { id: string, item: any }): JSX.Element => {
  const isRetail = useRetail()

  if (isRetail && !item.paid) {
    return (
      <PayOrderButton order={item} />
    )
  }

  return (
    <div> {isRetail ? item['paystatus'] : item['actionname']} </div>
  )
}

const getHeader = (label: string) =>
  // @ts-ignore
  provideDictionary(({ dictionary }) => dictionary.tables.orderHistoryList[label]
    // @ts-ignore
    ? <div className={label === 'grossprice' || label === 'netprice' ? 'text-right' : undefined}>{dictionary.tables.orderHistoryList[label]}</div>
    : null)

const getRenderer = (label: string) =>
  label === 'docnum'
    ? ({ id, item }: any) => !item['id'] ? item[id] : <Link className="link" to={`/me/history/${item['id']}`}> {item[id]} </Link>
    : label === 'paystatus'
    ? PaystatusOrActionName
    : label === 'grossprice' || label === 'netprice'
    ? ({ id, item }: any) => <div className="text-right"><strong>{formatPrice(item[id])} Kč</strong></div>
    : label === 'invlist' ? providePDFDownload(
      // @ts-ignore
      ({ id, item, downloadPDF }) => {
        const dictionary = useDictionary()

        if (!item.invlist) {
          return null
        }

        return (
          <div>
            {
              map(invoice => (
                <div key={invoice.invid} className="link" onClick={() => downloadPDF(`/invoices/${invoice.invid}`, invoice.invnum)}>
                  <i className="fas fa-file-pdf"/>
                  {` ${dictionary.tables.orderHistoryList.invoice} ${invoice.invnum}`}
                </div>
              ), item.invlist)
            }
          </div>
        )
      }
    )
    : ({ id, item }: any) => <div> {item[id]} </div>

const Table = createTable({
  // @ts-ignore
  itemKey: (item, index) => index,
  columns: [],
  // columns: [{
  //   id: 'docnum',
  //   header: provideDictionary(({ dictionary }) => <div>{dictionary.tables.orderHistoryList.docnum}</div>),
  //   renderer: ({ id, item }) => !item['id'] ? item[id] : <Link className="link" to={`/me/history/${item['id']}`}> {item[id]} </Link>,
  // },{
  //   id: 'created',
  //   header: provideDictionary(({ dictionary }) => <div>{dictionary.tables.orderHistoryList.created}</div>),
  //   renderer: ({ id, item }) => <div> {item[id]} </div>,
  // },{
  //   id: 'delivery',
  //   header: provideDictionary(({ dictionary }) => <div>{dictionary.tables.orderHistoryList.delivery}</div>),
  //   renderer: ({ id, item }) => <div> {item[id]} </div>,
  // },{
  //   id: 'price',
  //   header: provideDictionary(({ dictionary }) => {
  //     const isRetail = useRetail()
  //     return (
  //       <div className="text-right">
  //         {dictionary.tables.orderHistoryItemList.price}
  //         {' '}
  //         {isRetail ? dictionary.labels.grossPrice : dictionary.labels.netPrice }
  //       </div>
  //     )
  //   }),
  //   renderer: ({ id, item }) => <div className="text-right"><strong>{formatPrice(item[id])} Kč</strong></div>,
  // },{
  //   id: 'state',
  //   header: provideDictionary(({ dictionary }) => <div>{dictionary.tables.orderHistoryList.state}</div>),
  //   renderer: ({ id, item }) => <div> {item[id]} </div>,
  // },{
  //   id: 'actionname', // or paystatus on retail :)
  //   header: provideDictionary(({ dictionary }) => {
  //     const isRetail = useRetail()
  //     return (
  //       <div>{isRetail ? dictionary.tables.orderHistoryList.paymentState : dictionary.forms.order.event}</div>
  //     )
  //   }),
  //   renderer: PaystatusOrActionName,
  // },{
  //   id: 'invoice',
  //   header: () => null,
  //   renderer: providePDFDownload(
  //     // @ts-ignore
  //     ({ id, item, downloadPDF }) => {
  //       const dictionary = useDictionary()

  //       if (!item.invlist) {
  //         return null
  //       }

  //       return (
  //         <div>
  //           {
  //             map(invoice => (
  //               <div key={invoice.invid} className="link" onClick={() => downloadPDF(`/invoices/${invoice.invid}`, invoice.invnum)}>
  //                 <i className="fas fa-file-pdf"/>
  //                 {` ${dictionary.tables.orderHistoryList.invoice} ${invoice.invnum}`}
  //               </div>
  //             ), item.invlist)
  //           }
  //         </div>
  //       )
  //     }
  //   ),
  // }]
})

const History = () => {
  const dictionary = useDictionary()
  const dispatch = useDispatch()
  const list = useSelector(selectOrderHistoryList)
  const listLength = useSelector(selectOrderHistoryListLength)
  const location = useLocation()
  const params = extractSearchParams(location.search)
  const { offset = '0', limit = '10' } = params
  const withoutFilters = isEmpty(dissoc('offset', dissoc('limit', params)))
  const orderString = useConfigParam('ord_list_cols')
  const items = split(',', orderString || '')

  // fetch order history on mount
  useEffect(() => {
    dispatch(getOrderHistory({
      offset: parseInt(offset, 10),
      limit: parseInt(limit, 10),
      ...extractSearchParams(location.search)
    }))
  }, [dispatch, offset, limit, location.search])

  return (
    <div className="table">
      <div className="history-table-head">
        <h1>{dictionary.tables.orderHistoryList.heading}</h1>
        <HistoryFilterButton />
      </div>
      <div className="text-right">
        <HistoryFilterList />
      </div>
      {
        !list || isEmpty(list)
          ? offset === '0'
            ? <p className="pl-2">{withoutFilters ? dictionary.tables.orderHistoryList.emptyLabel : dictionary.tables.orderHistoryList.emptyFilter}</p>
            : null
          : <Table
              list={list}
              cols={map((columnName: string) => {
                return ({
                  id: columnName,
                  renderer: getRenderer(columnName),
                  header: getHeader(columnName)
                })
              }, items)}
            />
      }
      <Paginator
        offset={parseInt(offset, 10)}
        limit={parseInt(limit, 10)}
        currentLength={(list && list.length) || 0}
        maxLength={listLength}
      />
    </div>
  )
}

export default History
