import React from 'react'
import { connect } from 'react-redux'
import { setUi } from '../../store/actions/ui'
import { DialogType } from '../../store/types/ui'

type Props = {
  setUi: (ui: { openedDialog: DialogType }) => void,
  code: string,
}

const EditDeliveryTimeButton = ({ setUi, code }: Props) =>
  <i
    className="fas fa-pencil-alt clickable ml-decent"
    onClick={() => {
      setUi({ openedDialog: { id: 'change-delivery-time', timeCode: code }})
    }}
  />

export default connect(null, { setUi })(EditDeliveryTimeButton)
