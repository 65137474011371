import fetch from 'isomorphic-fetch'
import { head, last, values, zip, keys, map, isEmpty, concat, replace, join, dissoc } from 'ramda'
import { getApiPath } from '../../config'
import { headers as defaultHeaders } from '../../config/api'
import UrlPattern from 'url-pattern'

const formData = JSON.stringify

// Transform query object to query string functions:
const keyedMap = (func, object) => map(
  (obj) => func(head(obj), last(obj)),
  zip(keys(object), values(object)),
);

const createValues = (object) =>
  keyedMap((key, value) => join(
     '=',
     [key, replace(/"/g, '', JSON.stringify(value) || '')],
   ),
   object);

const makeRequestParams = query => {
  if (isEmpty(query)) {
    return '';
  }
  return concat('?', join('&', createValues(dissoc('', query))));
};

// Formatter for multipart/form-data 
const formDataMultipart = data => {
  const form = new FormData()
  map(v => {
    const name = head(v)
    if (Array.isArray(last(v))) {
      map(va => form.append(`${name}[]`, va), last(v))
    }
    else
      form.append(name, last(v))
  }, zip(keys(data), values(data)))
  return form
}

// Provides a fetch method used in all fetch actions to request api
export const fetchMiddleware = ({ dispatch, getState }) => next => action => {
  const middlewareFetch = ({ resource, headers = {}, body = {}, query = {}, method, pathParams = {}, sameOrigin = true, multipart = false }) => {
    const pattern = isEmpty(pathParams) ? resource : new UrlPattern(resource).stringify(pathParams)
    const req = `${getApiPath()}${pattern}${makeRequestParams(query)}`
    const options = {
      headers: {
        ...defaultHeaders({ getState, method, pathParams, sameOrigin, multipart, query, body, headers, resource }),
        ...headers,
      },
      method,
      // same-origin to force fetch to send cookies
      ...(sameOrigin ? {credentials: 'same-origin'} : {}),
      ...(isEmpty(body) ? {} : {body: multipart ? formDataMultipart(body) : formData(body)}),
    }
    return fetch(req, options).catch(error => {
      console.log(req, options, error)
    })
  }

  return next(typeof action === 'function' ? action({ dispatch, getState, fetch: middlewareFetch}) : action)
}

export default fetchMiddleware
