import React from 'react'
import { useSelector } from 'react-redux'
import { AppStateType } from '../../store/types'
import { path, last, head, length, find } from 'ramda'
import { connect } from 'react-redux'
import { ProductType } from '../../store/types/products'
import Image from '../Image'
import ProductMiniDetail from './ProductMiniDetail'
import { useParams } from 'react-router-dom'
import { Link } from '../Link'
import Info from './product/Info'
import MakeOrder from './product/MakeOrder'
import Price from './product/Price'
import { useMobileUi } from '../../hooks/ui'

type Props = {
  product: ProductType,
  defaultCategory: unknown,
  defaultMainCategoryId: unknown,
  section: unknown,
}

const mobileImageStyle = {
  width: '100%',
  maxWidth: '360px',
}

const ProductListItem = ({ product, defaultCategory, defaultMainCategoryId, section }: Props) => {
  const { category, categoryId } = useParams()
  const isMobile = useMobileUi()
  const appearance = useSelector((state: AppStateType) => state.ui.productListAppearance)
  // link id can be given with url or as category prop if served as search result
  const categoryLinkId = categoryId || defaultMainCategoryId || product.category || product.cat_id
  const mainCategoryLinkId = category || defaultCategory

  const link = section
    ? `/${mainCategoryLinkId}/category/${categoryLinkId}/section/${section||0}/product/${product.url}#topMenu`
    : `/${mainCategoryLinkId}/category/${categoryLinkId}/product/${product.url}#topMenu`

  // @TODO unite mobile and desktop render
  if (isMobile) {
    return (
      <div className="product-list-item">
        <h2><Link to={link}>{product.code} | {product.label}</Link></h2>
        <Info product={product} />
        <div className="text-center">
          <Link to={link}>
            <Image className="preview" code={product.image} alt={product.label} middle style={mobileImageStyle} />
          </Link>
        </div>

        {
          !product.description ? null :
          <ProductMiniDetail description={product.description} />
        }
        <Price product={product} />
        <MakeOrder product={product} />
      </div>
    )
  }

  return (
    <div className="product-list-item">
      <div className="preview-container">
        <Link to={link}>
          <Image
            className="preview"
            code={product.image}
            alt={product.label}
            small={appearance !== 'grid'}
            middle={appearance === 'grid'}
            {...(appearance === 'grid' ? {} : {width: '150'})}
          />
        </Link>
      </div>
      <div className="product-content">
        <h2><Link to={link}>{product.code} | {product.label}</Link></h2>

        <Info product={product} />

        <div className="product-content-row">
          <Price product={product} />
          <MakeOrder product={product} />
        </div>

        {
          !product.description || appearance === 'grid' ? null :
          <ProductMiniDetail description={product.description} />
        }
      </div>
    </div>
  )
}

export const listItemConnection = (state: AppStateType, props: { product: ProductType, section: unknown }) => {
  const categoryPath = path(['api', 'categories', 'categoryPaths', props.product.category || props.product.cat_id || ''], state) || []
  return ({
    // @ts-ignore
    defaultCategory: path(['api', 'categories', 'register', last(categoryPath) || '', 'url'], state),
    // @ts-ignore
    defaultMainCategoryId: length(categoryPath) > 1 && head(categoryPath),
    section: props.section
      // @ts-ignore
      || path(['id'], find((cat: any) => path(['id'], cat) === (props.product.category || props.product.cat_id), path(['api', 'categories', 'subRegister', categoryPath[0] || '', 'categories'], state) || []))
  })
}

export default connect(listItemConnection)(ProductListItem)
