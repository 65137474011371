import React from 'react'
import Image from '../Image'
import { path } from 'ramda'
import { useRouteMatch } from 'react-router-dom'
import { Link } from '../Link'

type Props = {
  url: string | undefined,
  onClick?: () => void,
  category: {
    name: string | undefined,
    image: string | undefined,
    id: number | undefined,
  }
}

const SubmenuItem = ({ category, url, onClick }: Props) => {
  const match = useRouteMatch('/:category/category/:categoryId')
  const isSelected = path(['params', 'categoryId'], match) === `${category.id}`
  return (
    <Link
      className={`submenu-item${isSelected ? ' is-selected':''}`} to={`/${url}/category/${category.id}`}
      onClick={onClick}
    >
      <Image code={category.image} width="40" />
      <div> { category.name } </div>
    </Link>
  )
}

export default SubmenuItem
