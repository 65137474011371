import React, { useEffect } from 'react'
import { map, path, splitAt } from 'ramda'
import { connect } from 'react-redux'
import { Link } from '../Link'
import { getFooterData, GetFooterDataParams } from '../../store/actions/api'

const category = 'company'

type Props = {
  getFooterData: (params: GetFooterDataParams) => void,
  items: unknown,
}

const AboutCompanyList = ({ getFooterData, items }: Props) => {
  useEffect(() => {
    getFooterData({
      limit: 10,
      category
    })
  }, [getFooterData])

  // @ts-ignore
  const data = splitAt(5, items || [])

  return (
    <React.Fragment>
      <td>
        {
          // @ts-ignore
          map((item: unknown) => (
            <div key={path(['id'], item)} className="mb-1">
              <Link to={`/content/${category}/${path(['url'], item)}#topMenu`}>{path(['name'], item)}</Link>
            </div>
          ), data[0] || [])
        }
      </td>
      <td>
        {
          // @ts-ignore
          map((item: unknown) => (
            <div key={path(['id'], item)} className="mb-1">
              <Link to={`/content/${category}/${path(['url'], item)}#topMenu`}>{path(['name'], item)}</Link>
            </div>
          ), data[1] || [])
        }
      </td>
    </React.Fragment>
  )
}

export default connect(state => ({
  items: path(['api', 'footer', category, 'data'], state),
}), { getFooterData })(AboutCompanyList)
