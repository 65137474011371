import { useSelector } from 'react-redux'
import { selectTheme } from '../store/selectors/ui'
import { selectAnonym, selectLanguage } from '../store/selectors/api'

export const useAnonym = (): boolean => {
  return useSelector(selectAnonym)
}

export const useRetail = (): boolean => {
  return useSelector(state => selectTheme(state) === 'R')
}

export const useLanguage = (): string | undefined => {
  // @ts-ignore
  return useSelector(state => selectLanguage(state).chosen)
}
