import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import createReduxStore from './store/createStore'
import createWindowResizeListener from './events/windowResizeListener'
import * as Sentry from "@sentry/react"

const { store, persistor } = createReduxStore()
createWindowResizeListener(store as any)

export const render = (Component: Function) =>
  ReactDOM.render(
    <Sentry.ErrorBoundary fallback={"An error has occured"}>
      <React.StrictMode>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <Component />
          </PersistGate>
        </Provider>
      </React.StrictMode>
    </Sentry.ErrorBoundary>,
    document.getElementById('root')
  )
