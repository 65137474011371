import React from 'react'
import english from './icons/English.svg'
import czech from './icons/Czech.svg'

type Props = {
  language: unknown,
  [props: string]: any,
}

const Flag = ({ language, ...props }: Props) => {
  return <img alt="language" src={language === 'CSY' ? czech : english} width="30" {...props} />
}

export default Flag
