import React from 'react'
import { useDictionary } from '../dictionary'
import { createForm } from '../hocs/form'
import { PostLoader } from '../components/Loading'
import { resourcePaths } from '../store/actions/api'
import Link from '../components/Link'

const formPath = ['password', 'send']
const Form = createForm(formPath, {
  Email: { key: 'username', type: 'email' },
})

const resourcePath = resourcePaths.passwordSend()

export const PasswordResetForm = () => {
  const dictionary = useDictionary()

  return (
    <>
      <Form.Error method="post" defaultMessage={dictionary.errors.unknownEmail} />

      <label className="mb-decent d-block"> {dictionary.forms.password.resetRequestLabel} </label>
      <div className="input-group d-block mb-2">
        <Form.Email />
      </div>

      <PostLoader path={resourcePath}>
        <Form.Submit className="btn btn--primary w-100 ml-0">
          {dictionary.forms.password.resetRequestSubmit}
        </Form.Submit>
      </PostLoader>
    </>
  )
}

const PasswordResetPage = () => {
  const dictionary = useDictionary()
  return (
    <div className="password-reset">
      <div className="dialog-overlay">
        <div className="centered position-absolute text-center">
          <div className="dialog-content">
            <div className="dialog-content-inner">
              <Link className="topleft" to="/">
                <i className="fas fa-chevron-left" />
                <span> {dictionary.labels.back}</span>
              </Link>
              <div className="heading mb-2">
                <h1> {dictionary.forms.password.resetRequestTitle} </h1>
              </div>

              <PasswordResetForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PasswordResetPage
