import { assocPath, append, equals, dissocPath, mapObjIndexed, path } from 'ramda'
import { StateType, FieldActionType } from '../types/fields'
import { CHANGE_VALUE, VALIDATE_VALUE, CLEAR_FORM, VALIDATE_FORM } from '../actions/fields'
import { SIGN_OUT } from '../actions/fetch'
import validators from '../../config/validators'

const initialState: StateType = {
  values: {

  },
  validates: {

  }
}

// Usefull if we want to cleanup some fields after user interacted with another field
const handleValueEffects = (action: FieldActionType, state: StateType) => {
  if (equals(action.payload.formPath, ['choose']) && action.payload.key === 'id_customer') {
    return dissocPath(append('id_dlv_addr', action.payload.formPath), state.values)
  }
  return state.values
}

export default (state = initialState, action: FieldActionType) => {
  switch(action.type) {
    default: return state

    case SIGN_OUT:
      // We preserve the chosen language because of 401 race condition
      // After 401s the language is sometimes missing in the login form
      if (path(['choose', 'lang'], state.values)) {
        return {
          ...initialState,
          values: assocPath(['choose', 'lang'], path(['choose', 'lang'], state.values), {})
        }
      }

      return initialState

    case CLEAR_FORM:
      return {
        ...state,
        values: dissocPath(action.payload.formPath, state.values),
      }

    case CHANGE_VALUE:
      return {
        ...state,
        values: assocPath(append(action.payload.key, action.payload.formPath), action.payload.value, handleValueEffects(action, state)),
      }

    case VALIDATE_FORM:
      let form = {}
      const vals = path(action.payload.formPath, state.values)

      mapObjIndexed((validator, key) => {
        // @ts-ignore
        const validated = validator(path([key], vals), path(['isAnonym'], action.payload))
        if (validated) {
          form = assocPath([key], validated, form)
        }
      })(path(action.payload.formPath, validators) || {})

      return {
        ...state,
        validates:
          assocPath(action.payload.formPath, form, state.validates)
      }

    case VALIDATE_VALUE:
      return {
        ...state,
        validates:
          action.payload.value
            ? assocPath(action.payload.inputPath, action.payload.value, state.validates)
            : dissocPath(action.payload.inputPath, state.validates)
      }
  }
}
