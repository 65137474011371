import React, { useEffect } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { path } from 'ramda'
import { postEnv } from '../../store/actions/api'
import { useLocation } from 'react-router-dom'
import { extractSearchParams } from '../../utils'

const EnvironmentAutoSender = (): null => {
  const location = useLocation()
  const dispatch = useDispatch()
  const chooseForm = useSelector(path(['fields', 'values', 'choose']))
  const defaultLang = useSelector(path(['api', 'login', 'lang']))
  const catalog = useSelector(path(['api', 'login', 'catalog']))

  useEffect(() => {
    // We extract delivery address from url query
    // if it is defined we choose first delivery date and sign in user
    const params = extractSearchParams(location.search || '')

    const deliveryAddress = path(['address'], params) as undefined | string
    const lang = (path(['lang', 'value'], chooseForm) || defaultLang) as string | undefined
    const id_customer = path(['id_customer', 'value'], chooseForm) as number | undefined
    const id_serving = path(['id_serving'], chooseForm) as string | undefined
    const date = path(['date'], chooseForm) as undefined | Date

    if (catalog) {
      if (lang && id_customer && id_serving && params.ddate && params.address) {
        dispatch(postEnv({
          lang,
          id_customer,
          id_serving,
          id_dlv_addr: parseInt(params.address as string, 10),
          date: params.ddate,
        }))
      }
    } else if (lang && id_customer && id_serving && date && deliveryAddress) {
      dispatch(postEnv({
        lang,
        id_customer,
        id_serving,
        id_dlv_addr: parseInt(deliveryAddress as string, 10),
        date: moment(date || '').format('YYYY-MM-DD'),
      }))
    }

  }, [location, chooseForm, defaultLang, dispatch])

  return null
}

export default EnvironmentAutoSender
