import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from '../Link'
import moment from 'moment'
import { path, map } from 'ramda'
import { fetchOnMount } from '../../hocs/fetch'
import { getPayMethods, resourcePaths, getCustomer } from '../../store/actions/api'
import { setUi } from '../../store/actions/ui'
import { selectDeliveryAddress, selectCustomerData, selectSignedUser } from '../../store/selectors/api'
import { AppStateType } from '../../store/types'
import { CustomerType } from '../../store/types/customer'
import Loader, { PostLoader } from '../Loading'
import { createForm, createValidator } from '../../hocs/form'
import { useDictionary, passArguments } from '../../dictionary'
import { useMobileUi } from '../../hooks/ui'
import { useConfigParam } from '../../hooks/config'
import { useRetail, useAnonym } from '../../hooks/api'
import BackToEshopButton from '../buttons/BackToEshop'
import Invoice from './Invoice'

const formPath = ['order']

const Form = createForm(formPath, {
  PersonName: { type: 'text', key: 'to_person_name' },
  PersonNameHint: { type: 'hint', key: 'to_person_name' },
  PersonPhone: { type: 'text', key: 'to_person_phone' },
  PersonPhoneHint: { type: 'hint', key: 'to_person_phone' },
  PayMethods: { type: 'select-radios', key: 'paymethod' },
  Address: { type: 'text', key: 'address', notRequired: true },
  Date: { type: 'text', key: 'date', notRequired: true },
  Floor: { type: 'text', key: 'floor', notRequired: true },
  FloorHint: { type: 'hint', key: 'floor', notRequired: true },
  Room: { type: 'text', key: 'room', notRequired: true },
  RoomHint: { type: 'hint', key: 'room', notRequired: true },
  EventName: { type: 'text', key: 'event_name', notRequired: true },
  Code: { type: 'text', key: 'int_ord_num', notRequired: true },
  Note: { type: 'textarea', key: 'note', notRequired: true },

  // Retail extra:
  Email: { type: 'text', key: 'email', notRequiredBusiness: true },
  Street: { type: 'text', key: 'street', notRequiredBusiness: true },
  City: { type: 'text', key: 'city', notRequiredBusiness: true },
  // Postcode: { type: 'text', key: 'postcode', notRequiredBusiness: true },
})

const NameValidator = createValidator([...formPath, 'to_person_name'])
const PhoneValidator = createValidator([...formPath, 'to_person_phone'])
const FloorValidator = createValidator([...formPath, 'floor'])
const RoomValidator = createValidator([...formPath, 'room'])
const EventValidator = createValidator([...formPath, 'event_name'])
const PaymethodValidator = createValidator([...formPath, 'paymethod'])
const NoteValidator = createValidator([...formPath, 'note'])
const CodeValidator = createValidator([...formPath, 'int_ord_num'])
// retail:
const EmailValidator = createValidator([...formPath, 'email'])
const StreetValidator = createValidator([...formPath, 'street'])
const CityValidator = createValidator([...formPath, 'city'])
// const PostcodeValidator = createValidator([...formPath, 'postcode'])

type Props = {
  payMethods: unknown,
  datum: unknown,
  address: unknown,
  userName: unknown,
  userPhone: unknown,
  customerId: unknown,
  customer: CustomerType | undefined,
}

const postLoaderPath = resourcePaths.createdOrder()

const OrderForm = ({ payMethods, userName, userPhone, datum = '', address = '', customer, customerId }: Props) => {
  const dictionary = useDictionary()
  const isRetail = useRetail()
  const isAnonym = useAnonym()
  const isMobile = useMobileUi()
  const dispatch = useDispatch()
  const [wantsToRegister, registerCheck] = useState(false)
  const [agrees, makeAgreed] = useState(false)
  const shopName = useConfigParam('shop_name')

  useEffect(() => {
    dispatch(getCustomer(customerId as number))
  }, [dispatch])

  return (
    <React.Fragment>
      <Form.ClearOnUnmount />
      <PostLoader path={postLoaderPath}>
        <div className="form-section">
          <h2>{dictionary.forms.order.deliveryInformation}</h2>

          {
            isAnonym ? null :
            <React.Fragment>
              <label className="bold">{dictionary.labels.where}</label>
              <div className="input-group">
                <Form.Address
                  disabled
                  defaultValue={address}
                />
              </div>
            </React.Fragment>
          }

          {
            !isAnonym ? null :
            <React.Fragment>
              <label className="bold d-block mb-decent">{dictionary.labels.address}</label>
              <label>{dictionary.register.street}<span className="red">*</span></label>
              <StreetValidator className="input-group">
                <Form.Street />
              </StreetValidator>

              <div className="d-block">
                <label>{dictionary.register.city}<span className="red">*</span></label>
                <CityValidator className="input-group mr-decent">
                  <Form.City disabled defaultValue={address} />
                </CityValidator>
              </div>

              {
              // <div className="d-inline-block w-50 va-top">
              //   <label className="ml-decent">{dictionary.register.postcode}<span className="red">*</span></label>
              //   <PostcodeValidator className="input-group ml-decent">
              //     <Form.Postcode />
              //   </PostcodeValidator>
              // </div>
              }
            </React.Fragment>
          }

          <label className="bold">{dictionary.labels.datum}</label>
          <div className="input-group">
            <Form.Date
              disabled
              // @ts-ignore
              defaultValue={moment(datum, 'YYYY-MM-DD').format('DD. M. YYYY')}
            />
          </div>

          {
            isRetail ? null :
            <React.Fragment>
              <div className="w-100">
                <div className="d-inline-block w-50 va-top">
                  <label className="mr-decent">{dictionary.forms.order.floor}</label>
                  <FloorValidator className="input-group mr-decent">
                    <Form.Floor />
                  </FloorValidator>
                  <Form.FloorHint title={dictionary.labels.commonlyUsed} options={customer && customer.hist_floor} />

                </div>

                <div className="d-inline-block w-50 va-top">
                  <label className="ml-decent">{dictionary.forms.order.room}</label>
                  <RoomValidator className="input-group ml-decent">
                    <Form.Room />
                  </RoomValidator>
                  <Form.RoomHint title={dictionary.labels.commonlyUsed} options={customer && customer.hist_room} className="ml-decent" />
                </div>
              </div>

              <label>{dictionary.forms.order.event}</label>
              <EventValidator className="input-group">
                <Form.EventName />
              </EventValidator>
            </React.Fragment>
          }
        </div>

        <div className="form-section">
          <h2>{isRetail ? dictionary.forms.order.personRetail : dictionary.forms.order.person}</h2>

          <label>{dictionary.forms.order.personName}<span className="red">*</span></label>
          <NameValidator className="input-group">
            <Form.PersonName defaultValue={!isAnonym && userName ? `${userName}` : undefined} />
          </NameValidator>
          <Form.PersonNameHint title={dictionary.labels.commonlyUsed} options={customer && customer.hist_contpers} />

          {
            !isAnonym ? null :
            <React.Fragment>
              <label>{dictionary.forms.login.email}<span className="red">*</span></label>
              <EmailValidator className="input-group">
                <Form.Email />
              </EmailValidator>
            </React.Fragment>
          }

          <label>{dictionary.forms.order.personPhone}<span className="red">*</span></label>
          <PhoneValidator className="input-group">
            <Form.PersonPhone defaultValue={userPhone ? `${userPhone}` : undefined} />
          </PhoneValidator>
          <Form.PersonPhoneHint title={dictionary.labels.commonlyUsed} options={customer && customer.hist_contphones} />
        </div>

        {
          isRetail ? null :
          <div className="form-section">
            <h2>{dictionary.forms.order.invoiceInfo}</h2>
            <Invoice />
          </div>
        }

        <div className="form-section form-select">
          <h2>{dictionary.forms.order.paymentMethods}<span className="red">*</span></h2>
          <Loader id="payMethods">
            <PaymethodValidator className="validate-select">
              <Form.PayMethods
                options={map(method => ({
                  label: path(['label'], method),
                  value: path(['id'], method),
                  // @ts-ignore
                }), payMethods || [])}
              />
            </PaymethodValidator>
          </Loader>
        </div>

        <div className="form-section">
          <h2>{dictionary.forms.order.additionalData}</h2>

          {
            isRetail ? null :
            <React.Fragment>
              <label>{dictionary.forms.order.internalCode}</label>
              <CodeValidator className="input-group">
                <Form.Code />
              </CodeValidator>
            </React.Fragment>
          }

          <label>{dictionary.forms.order.note}</label>

          <NoteValidator className="input-group">
            <Form.Note rows="6"/>
          </NoteValidator>
        </div>

        {
          !isAnonym ? null :
          <React.Fragment>
            <div className="register-form">
              <div className="register-form-header">
                <div className="register-form-content">
                  <h2> {dictionary.register.heading} </h2>
                  <div className="register-content">
                    <div className="register-check">
                      <input
                        id="register"
                        type="checkbox"
                        checked={wantsToRegister}
                        onChange={(e) => {
                          registerCheck(e.target.checked)
                        }}
                      />
                    </div>
                    <div>
                      <label htmlFor="register">{passArguments(dictionary.register.checkboxCreateAccount, {s: shopName})}</label>

                      <p className="color-grey font-smaller">{dictionary.register.personalConfirm}</p>
                    </div>
                  </div>
                  {
                  // <div>
                  //   <input type="checkbox" disabled/> <label>{dictionary.register.checkboxFill}</label>
                  // </div>
                  }
                </div>
              </div>
            </div>
            <div className="hor-line-after hor-line-after--mt-2 mr-2"/>
          </React.Fragment>
        }

        {
          !isRetail ? null :
          <div className="mt-5 d-flex">
            <div className={`ml-auto${isMobile ? '' : ' mr-2'} w-50 text-right m-w-100`}>
              <input type="checkbox" id="agree" checked={agrees} onChange={e => makeAgreed(e.target.checked)} />
              <label htmlFor="agree">
                <span>{dictionary.register.termsAndConditionsIntro} </span>
              </label>
              <span
                className="link link--underline"
                onClick={() => {
                  dispatch(setUi({
                    openedDialog: {
                      id: 'terms-and-conditions',
                    }
                  }))
                }}
              >
                {dictionary.register.termsAndConditionsLink}
              </span>
            </div>
          </div>
        }
        <div className="mt-1 d-flex">
          {
            isMobile ? null : <BackToEshopButton />
          }
          <Form.Submit
            className={`btn ml-auto${isMobile ? '' : ' mr-2'} w-50 text-center m-w-100`} onClick={() => window.scrollTo(0, 0)}
            additional={wantsToRegister}
            {...(isRetail && !agrees ? {isDisabled: true} : {})}
          >
            {dictionary.forms.order.submit}
          </Form.Submit>
        </div>
        {
          !isMobile ? null :
          <div className="mt-2">
            <Link to="/#topMenu">
              <i className="fas fa-angle-left ml-decent" />
              <span className="pl-1 mr-1">
                {dictionary.buttons.backToEshop}
              </span>
            </Link>
          </div>
        }
      </PostLoader>
    </React.Fragment>
  )
}

export default fetchOnMount({
  fetchAction: () => getPayMethods(),
})(connect((state: AppStateType) => ({
  payMethods: path(['api', 'payMethods', 'paymethods'], state),
  datum: path(['api', 'env', 'data', 'date'], state),
  address: selectDeliveryAddress(state),
  userName: path(['api', 'login', 'user'], state),
  userPhone: path(['api', 'login', 'usertlf'], state),
  customer: selectCustomerData(state),
  customerId: path(['id'], selectSignedUser(state) || ''),
}))(OrderForm))
