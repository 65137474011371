import React from 'react'
import { withRouter } from 'react-router-dom'
import { Link } from '../Link'
import { connect } from 'react-redux'
import { setUi } from '../../store/actions/ui'
import { AppStateType } from '../../store/types'
import { useDictionary } from '../../dictionary'

type Props = {
  search: string,
  setUi: (ui: { search: string }) => void,
  history: {
    push: (where: string) => void,
  },
}

const SearchInput = ({ setUi, search, history }: Props) => {
  const dictionary = useDictionary()

  return (
    <form className="input-group input-search" onSubmit={e => e.preventDefault()}>
      <input
        type="text"
        autoComplete="search"
        name="search"
        placeholder={dictionary.placeholders.search}
        value={search}
        onChange={e => setUi({ search: e.target.value })}
        onKeyUp={e => {
          if (e.keyCode === 13) {
            history.push(`/search/${search}`)
          }
        }}
      />
      <Link to={`/search/${search}`} className="input-append d-flex">
        <span><i className="fas fa-search d-inline" /></span>
        <span className="input-append-text ml-decent pr-decent">{dictionary.buttons.search}</span>
      </Link>
    </form>
  )
}

export default connect((state: AppStateType) => ({
  search: state.ui.search,
  // @ts-ignore
}), { setUi })(withRouter(SearchInput))
