import React from 'react'
import { connect } from 'react-redux'
import { OrderItemType, OrderType, DeliveryTimeType } from '../../store/types/order'
import { putProductInCart } from '../../store/actions/api'
import Unit from '../Unit'

type Props = {
  product: OrderItemType,
  cart: OrderType,
  delivery: DeliveryTimeType,
  putProductInCart: (params: { cartId: number, timeCode: string, productId: number, body: object }) => void,
}

const AmountChanger = ({ putProductInCart, product, cart, delivery }: Props) => {
  const minOrderIsDisabled = product.qty <= (product.minorder || 1)
  const maxOrderIsDisabled = product.maxorder && product.qty >= product.maxorder
  return (
    !product.display['ctrl-qty'] ? null :
    <div className="order-amount-changer float-left">
      <div
        className={`order-amount-button${minOrderIsDisabled ? ' is-disabled' : ''}`}
        onClick={() => {
          if (minOrderIsDisabled) {
            return
          }
          putProductInCart({ cartId: cart.id, productId: product.id, timeCode: delivery.timecode, body: { quantity: product.qty - product.granularity }})
        }}
      >
        -
      </div>
      <div className="order-amount-number">{product.qty} <Unit id={product.id_unit}/></div>
      <div
        className={`order-amount-button${maxOrderIsDisabled ? ' is-disabled' : ''}`}
        onClick={() => {
          if (maxOrderIsDisabled) {
            return
          }
          putProductInCart({ cartId: cart.id, productId: product.id, timeCode: delivery.timecode, body: { quantity: product.qty + product.granularity }})
        }}
      >
        +
      </div>
    </div>
  )
}

export default connect(null, { putProductInCart })(AmountChanger)
