import React from 'react'
import { connect } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { Link } from '../Link'
import { path } from 'ramda'
import { setUi } from '../../store/actions/ui'
import { DialogType } from '../../store/types/ui'
import { ProductType } from '../../store/types/products'
import ImageCarousel from '../ImageCarousel'
import Info from './product/Info'
import Price from './product/Price'
import MakeOrder from './product/MakeOrder'
import { useDictionary } from '../../dictionary'
import { useMobileUi } from '../../hooks/ui'
import Allergens from './product/Allergens'

type Props = {
  url?: string | undefined,
  product?: ProductType | undefined,
  setUi: (ui: { openedDialog: DialogType }) => void,
}

const ProductDetail = ({ product, setUi }: Props) => {
  const dictionary = useDictionary()
  const isMobile = useMobileUi()
  const match = useRouteMatch('/:category/category/:categoryId')

  if (!product) {
    return null
  }
  return (
    <React.Fragment>
      <h1>{product.code} | {product.label}</h1>
      <div className={`${isMobile? '' : ' mr-1'}`}>
        <div className={isMobile ? 'mobile-product-detail' : 'd-flex'}>
          <div
            className="preview-container p-2"
          >
            <ImageCarousel
              className="preview preview--detail preview-big m-auto"
              images={product.img_set}
              alt={product.label}
              middle
              // width="360"
              height="240"
              onClick={
                isMobile
                ? undefined
                : ((index: number) => setUi({ openedDialog: { id: 'product-detail-zoom', imageIndex: index, product }}))
              }
            />
          </div>
          <div className="product-content w-100">
            <div className="p-2">
              <Info product={product} />
              <div className="product-content-row mt-1">
                <Price product={product} />
                {
                  !match ? null :
                  <MakeOrder product={product} />
                }
              </div>
            </div>
          </div>
        </div>
        {
          !product.description ? null :
          <div className="p-2">
            <h2> {dictionary.labels.productDescription} </h2>
            <div className="product-detail-web-content" dangerouslySetInnerHTML={{__html: product.description || ''}} />
            <Allergens product={product} />
          </div>
        }
      </div>
      <Link
        exact={Boolean((match && match.url))}
        to={(match && match.url) || '/'}
        className="mt-2 link link--underline d-block ml-m-1"
      >
        <i className="fas fa-angle-left" />
        <span> { dictionary.buttons.backToEshop } </span>
      </Link>
    </React.Fragment>
  )
}

export default connect((state, { url = '' }: Props) => ({
  product: path(['api', 'products', url, 'data'], state),
  // @ts-ignore
}), { setUi })(ProductDetail)
