import React, { useEffect } from 'react'
import { extractSearchParams } from '../utils'
import { path } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { postLogin } from '../store/actions/api'
import { LoadingComponent } from '../components/Loading'
import { changeValue } from '../store/actions/fields'
import { signOut } from '../store/actions/fetch'

type Props = {
  redirect?: boolean,
}

const Catalog = ({ redirect }: Props) => {
  const dispatch = useDispatch()
  const login = useSelector(state => path(['api', 'login'], state)) as any
  const history = useHistory()
  const match = useRouteMatch('/catalog/:catalogId')
  const catalogId = (path(['params', 'catalogId'], match || '') || '') as string

  useEffect(() => {
    if (login?.token && !login?.catalog) {
      dispatch(signOut())
    } else {
      if (!login?.token) {
        // Extract lang param and use it before signin in
        const params = extractSearchParams(window.location.search)
        if (params.lang === 'CSY' || params.lang === 'ENU') {
          dispatch(changeValue({
            key: 'lang',
            formPath: ['choose'],
            value: {label: params.lang, value: params.lang}
          }))
        }
        // log anonym user in
        dispatch(postLogin({
          catalog: catalogId,
        }, true))
      } else {
        // redirect user if logged in
        if (redirect) {
          history.push(`/${window.location.search}`)
        }
      }
    }
  }, [login, redirect, dispatch])

  return (
    <div>
      <LoadingComponent />
    </div>
  )
}

export default Catalog
