import { path, assoc, assocPath, dissocPath, dissoc } from 'ramda'
import { SET_UI, SET_AMOUNT, UNSET_AMOUNT, SET_SECTION_FILTER } from '../actions/ui'
import { SIGN_OUT } from '../actions/fetch'
import StateType from '../types/ui'
import { isMobile } from '../../utils'

const initialState: StateType = {
  rememberMe: false,
  cookieConsent: false,
  amounts: {

  },
  openedDialog: {
    id: null,
  },
  filterCategory: {
    // nothing
  },
  search: '',
  lastNewDayOpened: {
    R: null,
    B: null,
  },
  mobileOrderOpened: false,
  layoutType: isMobile() ? 'mobile' : 'desktop',
  productListAppearance: 'grid',
}

export default (state: StateType = initialState, action: any) => {
  switch(action.type) {
    case SET_UI:
      return ({
        ...state,
        ...action.payload,
      })

    case UNSET_AMOUNT:
      return ({
        ...state,
        amounts: dissoc(action.payload.productId, state.amounts),
      })

    case SET_AMOUNT:
      return ({
        ...state,
        amounts: assoc(path(['payload', 'productId'], action) || '', path(['payload', 'amount'], action), state.amounts),
      })

    case SET_SECTION_FILTER: {
      return {
        ...state,
        filterCategory: path(['payload', 'active'], action)
          ? assocPath([path(['payload', 'categoryId'], action) || '', `${path(['payload', 'sectionId'], action)}`], true, state.filterCategory)
          : dissocPath([path(['payload', 'categoryId'], action) || '', path(['payload', 'sectionId'], action) || ''], state.filterCategory)
      }
    }

    case SIGN_OUT: {
      if (path(['payload', 'languageAfterLogout'], action)) {
        return ({
          ...state,
          languageAfterLogout: path(['payload', 'languageAfterLogout'], action),
        })
      }
      return ({
        ...state,
      })
    }

    default:
      return state
  }
}
