import React from 'react'
import { path } from 'ramda'
import { connect } from 'react-redux'
import Login from '../components/login/LoginForm'
import SetEnvironment from '../components/login/EnvironmentForm'
import { useConfigParam } from '../hooks/config'
import { buildImageSource } from '../config'

type Props = {
  userIsLoggedIn: boolean
}

const LoginPage = ({ userIsLoggedIn }: Props) => {
  const backgroundImage = useConfigParam('bg_img_login')
  return (
    <div
      className="login-page position-relative"
      style={{
        backgroundImage: `url(${buildImageSource(backgroundImage)})`
      }}
    >
      <div className="dialog-overlay">
        <div className="centered position-absolute text-center m-w-100 m-h-100">
          {
            !userIsLoggedIn ?
            <Login />
            :
            <SetEnvironment />
          }
        </div>
      </div>
    </div>
  )
}

export default connect(state => ({
  userIsLoggedIn: Boolean(path(['api', 'login', 'token'], state)),
}))(LoginPage)
