import React, { useState } from 'react'
import { path } from 'ramda'
import { useRouteMatch } from 'react-router-dom'
import { Link } from '../Link'
import { useDictionary } from '../../dictionary'
import { useMobileUi } from '../../hooks/ui'

const Navigation = () => {
  const dictionary = useDictionary()
  const match = useRouteMatch('/me/:target')
  const isMobile = useMobileUi()
  const [openedSub, openSub] = useState(true)

  if (isMobile) {
    return (
      <div>
        <div className="mobile-submenu-container">
          <div className="mobile-top-filter-item">
            <Link to="/"><i className="fas fa-chevron-left mr-1"/> {dictionary.buttons.backToEshop}</Link>
          </div>
          <div className={`mobile-top-filter-item${openedSub ? ' opened' : ''}`} onClick={() => openSub(!openedSub)}>
            <div className="top-filter-item">
              {
                path(['params', 'target'], match) === 'password'
                ? dictionary.account.passwordTitle
                : dictionary.account.historyTitle
              }
              <i className={`fas fa-angle-${openedSub ? 'up' : 'down'}`} />
            </div>
          </div>
          {
            !openedSub ? null :
            <div className="submenu">
              <div className="content">
                <Link className="submenu-item" to="/me/password" onClick={() => openSub(false)}>
                  {dictionary.account.passwordTitle}
                </Link>
                <Link className="submenu-item" to="/me/history" onClick={() => openSub(false)}>
                  {dictionary.account.historyTitle}
                </Link>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }

  return (
    <div className="mb-2 mt-2">
      {
        // <Link className={`btn btn--secondary ml-0${path(['params', 'target'], match) ? ' btn--not-active' : ''}`} to="/me">
        //   {dictionary.account.accountTitle}
        // </Link>
      }
      <Link className={`btn btn--secondary ml-0${path(['params', 'target'], match) !== 'password' ? ' btn--not-active' : ''}`} to="/me/password">
        {dictionary.account.passwordTitle}
      </Link>
      {
        // <Link className={`btn btn--secondary${path(['params', 'target'], match) !== 'customers' ? ' btn--not-active' : ''}`} to="/me/customers">
        //   {dictionary.account.customersTitle}
        // </Link>
      }
      <Link className={`btn btn--secondary${path(['params', 'target'], match) !== 'history' ? ' btn--not-active' : ''}`} to="/me/history">
        {dictionary.account.historyTitle}
      </Link>
    </div>
  )
}

export default Navigation
