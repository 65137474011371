import React from 'react'
import { connect } from 'react-redux'
import { selectPriceType } from '../../store/selectors/api'
import { AppStateType } from '../../store/types'
import { useDictionary } from '../../dictionary'
import { formatPrice } from '../../utils'

type Props = {
  gross: number,
  net: number,
  displayNetPrice: boolean,
}

const Price = ({ gross, net, displayNetPrice }: Props) => {
  const dictionary = useDictionary()
  return (
    <React.Fragment>
      {
        displayNetPrice
        ? dictionary.labels.netPrice
        : dictionary.labels.grossPrice
      }
      {
        ' '
      }
      {formatPrice(displayNetPrice ? net : gross)} Kč
    </React.Fragment>
  )
}

export default connect((state: AppStateType) => ({
  displayNetPrice: selectPriceType(state) === 'netprice',
}))(Price)
