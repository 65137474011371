import React from 'react'
import { useSelector } from 'react-redux'
import { selectAllergens } from '../../../store/selectors/api' 
import { map, addIndex, compose, filter, isEmpty, path } from 'ramda'
import { ProductType } from '../../../store/types/products'
import { useDictionary } from '../../../dictionary'
import { convertArrayToObject } from '../../../utils'

const mapIndexed = addIndex(map)

type Props = {
  product: ProductType,
}

const Allergens = ({ product }: Props) => {
  const dictionary = useDictionary()
  const allList = useSelector(selectAllergens)
  const allRegister = convertArrayToObject(path(['id']))(allList || [])

  // @ts-ignore
  const allergens = filter(a => a, mapIndexed((char: string, index: number) => {
    if (char === '0') {
      return 0
    } else if (char === '1') {
      return index+1
    }
  }, product.alerg || ''))

  if (isEmpty(allergens)) {
    return null
  }

  return (
    <div>
      <div className="bold">
        { dictionary.labels.allergens }:
      </div>
      <div className="mt-1">
        {
          map(allergen => {
            return <div key={allergen} className="badge badge--allergen mr-1 mb-1" title={path([allergen, 'text'], allRegister)}>A{allergen}</div>
          }, allergens)
        }
      </div>
    </div>
  )
}

export default Allergens
