import React from 'react'
import { connect } from 'react-redux'
import { resourcePaths } from '../store/actions/api'
import { path, isEmpty } from 'ramda'
import Page404 from '../pages/404'

const getResource = (id: string, props: any) => {
  // @ts-ignore
  if (resourcePaths[id] === undefined)
    return []
  // @ts-ignore
  return resourcePaths[id](props)
}

export const LoadingComponent = () =>
  <div className="loader-back centered">
    <div className="loader" />
  </div>

type LoaderProps = {
  children: any,
  displayLoader: boolean,
  displayNotFound?: boolean,
}

type ConnectedProps = {
  id: string,
}

// @ts-ignore
const Loader = ({ children, displayLoader, displayNotFound }: LoaderProps) =>
  displayNotFound
    ? <Page404 />
    : displayLoader
    ? <LoadingComponent />
    : children

export const ResourceLoader = connect(
  (state, { method = 'get', path: pathRes }: { method?: string, path: string[] }) =>
    ({
      displayLoader: !isEmpty(pathRes) && path(['fetch', 'status', method, ...pathRes, 'type'], state) !== 'saved',
      displayNotFound:
        !isEmpty(pathRes)
          && path(['fetch', 'status', method, ...pathRes, 'type'], state) === 'error'
          && path(['fetch', 'status', method, ...pathRes, 'code'], state) === 404,
    }),
)(Loader)

// On post loader we only want to display loader if status is pending
export const PostLoader = connect(
  (state, { path: pathRes }: { method?: string, path: string[] }) =>
    ({ displayLoader: !isEmpty(pathRes) && path(['fetch', 'status', 'post', ...pathRes, 'type'], state) === 'pending', }),
)(Loader)

// The same thing as post loader :]
export const PutLoader = connect(
  (state, { path: pathRes }: { method?: string, path: string[] }) =>
    ({ displayLoader: !isEmpty(pathRes) && path(['fetch', 'status', 'put', ...pathRes, 'type'], state) === 'pending', }),
)(Loader)

// @ts-ignore
export default connect<ConnectedProps>((state, props: ConnectedProps & LoaderProps) => ({
  displayLoader: path(['fetch', 'status', 'get', ...getResource(props.id, props), 'type'], state) !== 'saved',
}))(Loader)
