import { path } from 'ramda'
// default headers for requests
export const headers = ({ getState, ...fetchParams }) => {
  const token = path(['api', 'login', 'token'], getState())
  return ({
    // Set desired headers for every request
    // Here you can set tokens etc. stored in redux state
    accept: 'application/json',
    ...((fetchParams.method === 'post' || fetchParams.method === 'put') ? {'Content-type': 'application/json'} : {}),
    ...(token ? {Authorization: `Bearer ${token}`} : {}),
  })
}
