import { combineReducers } from 'redux'
import ui from './ui'
import api from './api'
import fetch from './fetch'
import fields from './fields'

export default () => combineReducers({
  ui,
  api,
  fetch,
  fields,
})
