import { length, compose, replace, trim, isEmpty, reduce, test, toLower } from 'ramda'

const all = (...fn: any) => (value: string) => reduce((a, b: (a: any) => any) => a ? a : b(value), false, fn)

const notEmpty = (str: string) => !isEmpty(str || '') ? false : ['notEmpty']

const stringOfLength = (minLength: number, maxLength: number) => (str: string) => {
  if (!str) {
    return false
  }
  if (str.length >= minLength && str.length <= maxLength) {
    return false
  } else if (str.length >= minLength) {
    return ({ path: ['tooLong'], params: maxLength })
  } else return ({ path: ['tooShort'], params: minLength })
}

const passwordValidator = (value: string) => {
  // @ts-ignore
  // if (length(value as string || '') < minPasswordLength) {
  //   // return ['password']
  //   stringOfLength(minPasswordLength, minPasswordLength)(value)
  // }
  // return false
  return notEmpty(value) || stringOfLength(5, 60)(value)
}

const phoneValidator = compose(
  // @ts-ignore
  (str: string) => !isEmpty(str) ? { path: ['invalidChars'], params: str } : false,
  replace(/\+/g, ''),
  replace(/\d/g, ''),
  replace(/ /g, ''),
  trim,
)

const emailValidator = (str: string = '') => {
  if (isEmpty(str)) {
    return notEmpty(str)
  }
  if (test(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, trim(toLower(str)))) {
    return false
  } else {
    return ['invalidEmail']
  }
}

const streetOrCityValidator = (str: string = '') => {
  if (isEmpty(str)) {
    return notEmpty(str)
  }
  return stringOfLength(1, 40)(str)
}

// Form input validators
// Every validator returns {false} if validation is ok
// or an array representing a path in dictionary with a reason to display
export default {
  password: {
    change: {
      old: passwordValidator,
      new: passwordValidator,
    },
    reset: {
      new: passwordValidator,
    }
  },
  order: {
    paymethod: (m: any) => m ? false : ['paymethodSelect'],
    to_person_name: all(notEmpty, stringOfLength(0, 32)),
    to_person_phone: all(notEmpty, phoneValidator, stringOfLength(0, 40)),
    event_name: stringOfLength(0, 110),
    floor: stringOfLength(0, 3),
    room: stringOfLength(0, 80),
    int_ord_num: stringOfLength(0, 30),
    note: stringOfLength(0, 4096),

    email: (val: any, isAnonym?: boolean) => !isAnonym ? false : emailValidator(val),
    street: (val: any, isAnonym?: boolean) => !isAnonym ? false : streetOrCityValidator(val),
    city: (val: any, isAnonym?: boolean) => !isAnonym ? false : streetOrCityValidator(val),
    // postcode: (val: any, isRetail?: boolean) => !isRetail ? false : notEmpty(val),
  }
}
