import React from 'react'
import { path, find } from 'ramda'
import { connect } from 'react-redux'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { Link } from './Link'
import { useDictionary } from '../dictionary'

type ConnectedProps = {
  category: undefined | string,
  categoryId: undefined | number,
  productUrl: undefined | string,
  section: undefined | string,
}

type Props = {
  subcategory: string | undefined,
  categoryName: string | undefined,
  product: string | undefined,
  sectionName: string | undefined,
}

const connectNavigation = connect((state, { category = '', categoryId, productUrl = '', section }: ConnectedProps) => ({
  subcategory: path(['api', 'categories', 'subRegister', categoryId === undefined ? '' : categoryId, 'name'], state),
  categoryName: path(['api', 'categories', 'register',
      path(['api', 'categories', 'byUrl', category], state) || '',
    'name'], state),
  sectionName: path(['name'], find(
    (cat: any) => `${path(['id'], cat)||''}` === section,
    path(['api', 'categories', 'subRegister', categoryId === undefined ? '' : categoryId, 'categories'], state)
      || path(['api', 'categories', 'subRegister',
          // @ts-ignore
          path(['api', 'categories', 'categoryPaths', section || '', 0], state), 'categories'], state)
      || []
  ))
}))


// @ts-ignore
const Navigation = connectNavigation(({ subcategory, categoryName, category, categoryId, productUrl, section, sectionName }: Props & ConnectedProps) => {
  const dictionary = useDictionary()
  const meMatch = useRouteMatch('/me')

  // A lone home? Display nothing
  if (!meMatch && !categoryName && !subcategory) {
    return null
  }

  return (
    <ul className="navigation">
      <li>
        <Link to={`/`}>
          <i className="fas fa-home" />
        </Link>
      </li>
      {
        !meMatch ? null : <li>{dictionary.buttons.myAccount}</li>
      }
      {
        !categoryName ? null : <li><Link to={`/${category}`}>{categoryName}</Link></li>
      }
      {
        !subcategory ? null : <li><Link to={`/${category}/category/${categoryId}`}>{subcategory}</Link></li>
      }
      {
        !sectionName || !section ? null : <li><Link to={`/${category}/category/${categoryId}#${section}`}>{sectionName}</Link></li>
      }
      {
        /*!product ? null : <li>{product}</li>*/
      }
    </ul>
  )
})

const NavigationProvider = () => {
  const { category, productUrl, categoryId, section } = useParams()
  return (
    <Navigation
      category={category}
      categoryId={categoryId}
      productUrl={productUrl}
      section={section}
    />
  )
}

const NavigationRouter = () =>
  <Switch>
    <Route path="/:category/category/:categoryId/section/:section/product/:productUrl">
      <NavigationProvider />
    </Route>
    <Route path="/:category/category/:categoryId/product/:productUrl">
      <NavigationProvider />
    </Route>
    <Route path="/:category/category/:categoryId">
      <NavigationProvider />
    </Route>
    <Route path="/:category">
      <NavigationProvider />
    </Route>
    <Route path="">
      <NavigationProvider />
    </Route>
  </Switch>

export default NavigationRouter
