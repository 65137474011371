import React from 'react'
import { path } from 'ramda'
import { connect } from 'react-redux'
import Alert from '../components/Alert'

type Props = {
  formPath: string[],
  method: string,
}

export const createFetchStatusAlert = ({ formPath, method }: Props) => connect(
  (state, props) => {
    const statusObject = path(['fetch', 'status', path(['method'], props) || method, ...formPath], state)
    return ({
      type: path(['type'], statusObject),
      message: path(['status'], statusObject) || path(['reason'], statusObject) || path(['detail'], statusObject),
      status: path(['status'], statusObject),
      formPath,
      code: path(['code'], statusObject),
    })
  }
)(({ type, message, formPath, defaultMessage, code, codeMessages, status }) =>
  (type === 'alert' || type === 'error')
  ? <Alert
    type={type}
    status={status}
    // @ts-ignore
    message={(codeMessages && code && codeMessages(code)) || (message || defaultMessage || 'TODO unknown error')}
    formPath={formPath}
  />
  : null
)
