import React from 'react'
import { compose } from 'ramda'
import { fetchOnMount } from '../hocs/fetch'
import { getCategories, getLists } from '../store/actions/api'
import Loader from './Loading'

type Props = {
  children: any
}

const Preloader = ({ children }: Props) => <Loader id="categories">{children}</Loader>

export default compose(
  fetchOnMount({
    fetchAction: () => getCategories(),
  }),
  fetchOnMount({
    fetchAction: () => getLists(),
  }),
)(Preloader)
