import React, { useEffect } from 'react'
import { path } from 'ramda'
import { connect } from 'react-redux'
import { setAmount, unsetAmount } from '../../../store/actions/ui'
import { ProductType } from '../../../store/types/products'
import { useMobileUi } from '../../../hooks/ui'
import Unit from '../../Unit'

type Props = {
  product: ProductType,
  amount: undefined | number,
  setAmount: (productId: number, amount: number) => void,
  unsetAmount: (productId: number) => void,
}

export const fixAddAmountByGranularity = ({ product, newVal, lastVal }: { product: ProductType, newVal: number, lastVal?: number }) => {
  let val = newVal
  const lastValue = (lastVal || product.props.minorder || 1)

  // Fix granularity
  if (product.props.granularity) {
    if (val % product.props.granularity !== 0) {
      if (lastValue < val) {
        // val += val % product.props.granularity
        val = lastValue + product.props.granularity
      } else {
        // val -= val % product.props.granularity
        val = lastValue - product.props.granularity
      }
    }
  }

  // Fix max amount
  if (product.props.maxorder && val > product.props.maxorder) {
    val = product.props.maxorder
  }

  // Fix min order
  if (product.props.minorder && val < product.props.minorder) {
    val = product.props.minorder
  }

  return val
}

const Amount = ({ product, amount, setAmount, unsetAmount }: Props) => {
  const isMobile = useMobileUi()
  const value = amount || product.props.minorder || 1
  const setNewValue = (val: number) => setAmount(product.id, fixAddAmountByGranularity({
    product,
    newVal: val,
    lastVal: value,
  }))
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = parseInt(e.target.value, 10)
    setNewValue(val)
  }

  useEffect(() => () => {
    if (amount) {
      unsetAmount(product.id)
    }
  }, [amount === undefined, product.id, unsetAmount])

  if (isMobile) {
    return (
      <div className="mt-1">
        <i className="fas fa-minus" onClick={() => setNewValue(value - product.props.granularity)}/>
        <div className="ml-1 mr-1 d-inline-block">
          { value }
          <Unit id={product.id_unit} />
        </div>
        <i className="fas fa-plus" onClick={() => setNewValue(value + product.props.granularity)}/>
      </div>
    )
  }

  return (
    <React.Fragment>
      <input className="amount-input" type="number" value={value} onChange={onChange} />
      <Unit id={product.id_unit} />
    </React.Fragment>
  )
}

export default connect((state, props: Props) => ({
  amount: path(['ui', 'amounts', props.product.id], state),
  // @ts-ignore
}), { setAmount, unsetAmount })(Amount)
