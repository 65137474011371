import React, { useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { selectDeliveryAddress } from '../../store/selectors/api'
import { setUi } from '../../store/actions/ui'

type Props = {
  address: string | undefined,
  mobileVersion: boolean,
}

const AddressInput = ({ address = '', mobileVersion }: Props) => {
  const dispatch = useDispatch()
  const onClick = useCallback(() => dispatch(setUi({ openedDialog: {id: 'change-address' } })), [])

  if (mobileVersion) {
    return (
      <div className="input-group w-50 d-inline-flex clickable" onClick={onClick}>
        <span className="input-prepend"><i className="fas fa-map-marker-alt" /></span>
        <input className="clickable" type="text" value={address} readOnly />
      </div>
    )
  }
  else {
    return (
      <div className="input-group clickable" onClick={onClick}>
        <input className="clickable" type="text" value={address} readOnly />
        <span className="input-append"><i className="fas fa-map-marker-alt" /></span>
      </div>
    )
  }
}

export default connect(state => ({
  address: selectDeliveryAddress(state),
  // @ts-ignore
}))(AddressInput)
