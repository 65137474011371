import React from 'react'
import { connect } from 'react-redux'
import { validateForm } from '../../store/actions/fields'
// import { addWindowEventsOnMount } from '../../hocs/events'

type Props = {
  isDisabled: boolean | undefined,
  className: string | undefined,
  formPath: string[],
  validateForm: (a: any) => void,
  onClick: () => void,
  [prop: string]: any,
}

const SubmitButton = ({ onClick, className, validateForm, formPath, isDisabled, type, ...props }: Props) =>
  type === 'submit'
  ?
  <input
    type={type}
    className={!isDisabled ? className : `${className||''} is-disabled`}
    onClick={isDisabled ? (() => validateForm(formPath)) : onClick}
    {...props}
  />
  :
  <a
    href="#submit"
    onClick={isDisabled ? (() => validateForm(formPath)) : onClick}
    className={!isDisabled ? className : `${className||''} is-disabled`}
    {...props}
  />

export default connect(null, { validateForm })(SubmitButton)

// @TODO if want to use enter
// export default addWindowEventsOnMount([{
//   type: 'keyup',
//   callback: ({ dispatch, getProps }) => e => {
//     if (e.keyCode === 13) {
//       getProps().onClick()
//     }
//   }
//   // @ts-ignore @TODO how to connect this?
// }])(SubmitButton)
