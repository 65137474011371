import React from 'react'
import { path, map } from 'ramda'
import { connect, useSelector } from 'react-redux'
import Image from '../Image'
import { useRouteMatch } from 'react-router-dom'
import { useDictionary } from '../../dictionary'
import { useAnonym } from '../../hooks/api'
import { useConfigParam } from '../../hooks/config'
import { Link } from '../Link'

type Props = {
  categories: number[] | undefined
}

export const Item = ({ id }: { id: number }) => {
  const match = useRouteMatch('/:category')
  const category = useSelector(state => path(['api', 'categories', 'register', id], state))
  const isActive = path(['params', 'category'], match) === path(['url'], category)

  return (
    <li className={`top-filter-item${isActive ? ' is-selected':''}`}>
      <Link key={path(['id'], category)} to={`/${path(['url'], category)}`}>
          <Image width="30" height="30" code={path(['image'], category)} />
          <span> {path(['name'], category)} </span>
      </Link>
    </li>
  )
}

const demandStyle = {
  paddingTop: '1.2rem',
  paddingBottom: '1.2rem',
}

const TopFilters = ({ categories = [] }: Props) => {
  const dictionary = useDictionary()
  const isAnonym = useAnonym()
  const extraButtons = useConfigParam('cat_extra_btns')
  const extraButtonsData = typeof extraButtons === 'string' ? JSON.parse(extraButtons) : extraButtons

  return (
    <React.Fragment>
    {
      map(
        categoryId => <Item key={categoryId} id={categoryId} />,
        categories
      )
    }
    {
      map((button: any) => {
        if (
          (button.cond && button.cond === 'Anonym' && !isAnonym) ||
          (button.cond && button.cond === 'notAnonym' && isAnonym)) {
          return null
        }
        return (
          <li key={button.href} className={`top-filter-item`} style={demandStyle}>
            <a
              href={button.href}
              target="_new"
            >
              <span className="pl-1">{button.label}</span>
            </a>
          </li>
        )
      }, extraButtonsData?.btns || [])
    }
    </React.Fragment>
  )
}

export default connect(state => ({
  categories: path(['api', 'categories', 'list'], state),
  // @ts-ignore @TODO how to type component returning an array?
}))(TopFilters)
