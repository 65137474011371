import React from 'react'
import { map, addIndex, filter } from 'ramda'
import { useMobileUi } from '../hooks/ui'

const mapIndexed = addIndex(map)

type Props = {
  id: any,
  item: any,
}

type ColumnType = {
  id: any,
  renderer: (props: Props) => JSX.Element | null,
  header: () => JSX.Element | null,
}

type TableParams = {
  columns: ColumnType[],
  itemKey: (item: any, index?: number) => any,
}

export const createTable = ({ columns, itemKey }: TableParams) => ({ list, cols, ignore }: { list: any[], cols?: ColumnType[], ignore?: string }) => {
  const isMobile = useMobileUi()
  const items = filter((col: ColumnType) => col.id !== ignore, cols || columns)

  // // Mobile table
  if (isMobile) {
    return (
      <div>
      <div className="hor-line-after" />
      {
        mapIndexed(
          (item, index) => (
            <React.Fragment key={itemKey(item, index)}>
              <div className="table-mobile-item">
                {
                  map(column => {
                    return (
                      <div key={column.id} className="table-mobile-cell">
                        <div className="table-mobile-cell-left">
                          <strong>
                            <column.header />
                          </strong>
                        </div>
                        <div className="table-mobile-cell-right">
                          <column.renderer id={column.id} item={item} />
                        </div>
                      </div>
                    )
                  }, items)
                }
              </div>
              <div className="hor-line-after" />
            </React.Fragment>
          ), list
        )
      }
      </div>
    )
  }

  // Desktop table
  return (
    <table>
      <thead>
        <tr>
        {
          map(column => {
            return (
              <th key={column.id}>
                <column.header />
              </th>
            )
          }, items)
        }
        </tr>
      </thead>
      <tbody>
        {
          mapIndexed((item, index) => {
           return (
            <tr key={itemKey(item, index)}>
            {
              map(column => {
                return (
                  <td key={column.id}>
                    <column.renderer id={column.id} item={item} />
                  </td>
                )
              }, items)
            }
            </tr>
           ) 
          }, list)
        }
      </tbody>
    </table>
  )
}
