import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getProduct, resourcePaths } from '../../store/actions/api'
import { ResourceLoader } from '../Loading'
import ProductDetail from './ProductDetail'

type Props = {
  getProduct: (a: any) => void
}

const FetchAndDisplayProductDetail = ({ getProduct }: Props) => {
  const { productUrl } = useParams()
  
  // Fetch products
  useEffect(() => {
    if (productUrl !== undefined) {
      getProduct(productUrl)
    }
  }, [productUrl, getProduct])

  // TODO memoize this?
  const path = productUrl !== undefined ? resourcePaths.product(productUrl) : []

  return (
    <ResourceLoader
      path={path}
    >
      <ProductDetail url={productUrl} />
    </ResourceLoader>
  )
}

export default connect(null, { getProduct })(FetchAndDisplayProductDetail)
