import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getContentData } from '../../store/actions/api'
import { DialogType } from '../../store/types/ui'
import DialogWrapper from './DialogWrapper'
import PageContent from '../content/PageContent'

type Props = {
  dialog: DialogType,
}

const TermsAndConditions = (props: Props) => {
  const dispatch = useDispatch()
  const url = 'text-retail-obchodni-podminky'
  useEffect(() => {
    console.log('TODO make terms and conditions dynamic')
    dispatch(getContentData('company', url))
  }, [])
  return (
    <DialogWrapper className="dialog-content">
      <div>
        <PageContent category="company" url={url} />
      </div>
    </DialogWrapper>
  )
}

export default TermsAndConditions
