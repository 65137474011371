import React from 'react'
import { useSelector } from 'react-redux'
import { useDictionary } from '../../dictionary'
import { createForm, createValidator } from '../../hocs/form'
import { selectFetchResponse } from '../../store/selectors/api'
import Alert from '../Alert'

const formPath = ['password', 'change']

const PasswordForm = createForm(formPath, {
  Current: { key: 'old', type: 'password' },
  New: { key: 'new', type: 'password' },
})

const NewPasswordValidator = createValidator([...formPath, 'new'])
const OldPasswordValidator = createValidator([...formPath, 'old'])

const PasswordChange = () => {
  const dictionary = useDictionary()
  const response = useSelector(selectFetchResponse('put', formPath))
  return (
    <div className="account-page form-section">
      <div className="form">
        <h1 className="mt-2 mb-2">{dictionary.account.passwordTitle}</h1>

        {
          response?.code === 204 &&
          <Alert
            message={dictionary.forms.password.success}
            type="info"
            formPath={formPath}
          />
        }

        <PasswordForm.Error
          method="put"
          codeMessages={(code: number) => {
            if (code === 404) {
              return dictionary.errors.changePassword404
            } else if (code === 412) {
              return dictionary.formValidators.password
            }
          }}
        />

        {
          response?.type !== 'pending' &&
          <>
            <label className="mb-decent d-block"> {dictionary.forms.password.oldPassword} </label>
            <OldPasswordValidator className="input-group d-block mb-1">
              <PasswordForm.Current />
            </OldPasswordValidator>

            <label className="mb-decent d-block"> {dictionary.forms.password.newPassword} </label>
            <NewPasswordValidator
              key={response?.code}
              className="input-group d-block mb-1"
            >
              <PasswordForm.New />
            </NewPasswordValidator>

            <div className="mt-2">
              <PasswordForm.Submit className="btn btn--primary ml-0 mt-2 mb-2">
                {dictionary.forms.password.submit}
              </PasswordForm.Submit>
            </div>
          </>
        }
      </div>

    </div>
  )
}

export default PasswordChange
