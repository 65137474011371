import React from 'react'
import { buildImageSource } from '../config'
import { useConfigParam } from '../hooks/config'

const Logo = () => {
  const logo = useConfigParam('logo_header')
  return (
    <img
      src={buildImageSource(logo)}
      className="app-logo"
      alt="logo"
    />
  )
}

export default Logo
