import React from 'react'
import { path, map, isEmpty } from 'ramda'
import { connect } from 'react-redux'
import Section, { SectionType } from './Section'

type Props = {
  categoryId: number | undefined,
  sections: SectionType[] | undefined,
  categories: SectionType[] | undefined,
}

// @ts-ignore
const Sections = ({ categoryId, sections, categories }: Props) => {
  if (isEmpty(sections || []) && isEmpty(categories || [])) {
    return null
  }

  if (isEmpty(sections || [])) {
    return (
      <div className={`subcategories-container${categories!.length < 3 ? ' subcategories-container--tiny' : ''}`}>
      {
        // @ts-ignore
        map(section => <Section key={section.id} section={section} categoryId={categoryId} />, categories || [])
      }
      </div>
    )
  }

  return (
    <div className={`subcategories-container${sections!.length < 3 ? ' subcategories-container--tiny' : ''}`}>
    {
      // @ts-ignore
      map(section => <Section key={section.id} section={section} categoryId={categoryId} />, sections || [])
    }
    </div>
  )
}

export default connect((state, { categoryId }: Props) => ({
  sections: path(['api', 'products', categoryId || '', 'sections'], state),
  categories: path(['api', 'categories', 'subRegister', categoryId || '', 'categories'], state),
  // @ts-ignore
}))(Sections)
