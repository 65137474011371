import { path, test } from 'ramda'
import { ThemeType } from '../types/ui'
// import { extractSearchParams } from '../../utils'

// const queryTheme = () => {
//   if (test(/^\/r/, window.location.pathname)) {
//     return 'family'
//   } else if (test(/^\/r/, window.location.pathname)) {
//     return 'job'
//   }

//   // if (path(['seg'], extractSearchParams(window.location.search || '')) === 'B') {
//   //   return 'job'
//   // } else if (path(['seg'], extractSearchParams(window.location.search || '')) === 'R') {
//   //   return 'family'
//   // }
//   return undefined
// }

export const selectTheme = (state: any): ThemeType => {
  // const envTheme = path([
  //     'api',
  //     'login',
  //     'customerRegister',
  //     path(['api', 'env', 'data', 'id_customer'], state) || '',
  //     'seg'
  //   ],
  //   state
  // )

  // if (envTheme === 'R') {
  //   return 'family'
  // } else if (envTheme === 'B') {
  //   return 'job'
  // } else {
  //   return queryTheme() || 'job'
  // }
  // @ts-ignore
  return global.window.__CATERMAT_CONFIG__.segment
}
