import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getProductsFromCategory, resourcePaths } from '../../store/actions/api'
import { ResourceLoader } from '../Loading'
import Sections from './Sections'
import List from './List'
import CategoryInfo from './CategoryInfo'

type Props = {
  getProductsFromCategory: (a: any) => any,
  mainCategoryId?: unknown,
}

const FetchAndDisplayProductList = ({ getProductsFromCategory, mainCategoryId }: Props) => {
  const { categoryId, subcategoryId } = useParams()
  // Fetch products
  useEffect(() => {
    if (subcategoryId !== undefined) {
      getProductsFromCategory(subcategoryId)
    }
    if (categoryId !== undefined) {
      getProductsFromCategory(categoryId)
    }
    if (subcategoryId === undefined && categoryId === undefined && mainCategoryId) {
      getProductsFromCategory(mainCategoryId)
    }
  }, [categoryId, subcategoryId, getProductsFromCategory, mainCategoryId])

  // TODO memoize this?
  const categoryPath =
    categoryId !== undefined
    ? resourcePaths.productsFromCategory(categoryId)
    : Boolean(mainCategoryId)
    ? resourcePaths.productsFromCategory(mainCategoryId as number)
    : []

  const subcategoryPath =
    subcategoryId !== undefined
    ? resourcePaths.productsFromCategory(subcategoryId)
    : []

  return (
    <ResourceLoader
      path={categoryPath}
    >
      <CategoryInfo categoryId={categoryId || mainCategoryId} />
      <Sections categoryId={categoryId || mainCategoryId} />
      <ResourceLoader path={subcategoryId !== undefined ? subcategoryPath : categoryPath}>
        <List path={subcategoryId !== undefined ? subcategoryPath : categoryPath} categoryId={categoryId} />
      </ResourceLoader>
    </ResourceLoader>
  )
}

export default connect(null, { getProductsFromCategory })(FetchAndDisplayProductList)
