import React, { useState, useEffect } from 'react'
import { assoc, dissoc, isEmpty } from 'ramda'
import { useDictionary } from '../../dictionary'
import { CalendarWithLocale } from '../../hocs/form'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { setSearchParam, extractSearchParams, buildSearchParams } from '../../utils'

const DateFormat = 'YYYY.MM.DD'

type InputProps = {
  name: string,
  state: {
    [prop: string]: any,
  },
  onChange: (key: string, val: any) => void,
  children: string,
  type?: string,
}

const Input = ({ name, state, onChange, children, type }: InputProps) => (
  <div className="filter-option-row">
    <div className="pr-1">{children}</div>
    {
      type === 'date'
      ?
        <CalendarWithLocale
          onChange={(val: any) => onChange(name, moment(val).format(DateFormat))}
          value={state[name] && moment(state[name], DateFormat).toDate()}
        />
      :
        <div className="input-group">
          <input type={type} value={state[name] || ''} onChange={(e) => onChange(name, e.target.value)} />
        </div>
    }
  </div>
)

const HistoryFilterOptions = ({ open }: { open: (b: boolean) => void }) => {
  const dictionary = useDictionary()
  const history = useHistory()
  const [state, updateState] = useState(!history.location.search ? {} : extractSearchParams(history.location.search))
  const onChange = (key: string, value: any) => value ? updateState(assoc(key, value, state)) : updateState(dissoc(key, state))

  // useEffect(() => {
  //   const event = () => {
  //     open(false)
  //   }
  //   window.addEventListener('click', event)
  //   return () => window.removeEventListener('click', event)
  // }, [])

  return (
    <div
      className="filter-options-container"
      onClick={e => {
        // e.stopPropagation()
      }}
    >
      <div className="options">
        <div className="filter-option">
          <div className="filter-option-head hor-line-after">
            <div className="mb-1">
              <i className="fas fa-calendar pr-1" />
              { dictionary.tables.orderHistoryList.delivery }
            </div>
          </div>
          <Input
            type="date"
            name="ddate_from"
            state={state}
            onChange={onChange}
          >
            {dictionary.labels.from}
          </Input>
          <Input
            type="date"
            name="ddate_to"
            state={state}
            onChange={onChange}
          >
            {dictionary.labels.to}
          </Input>
        </div>

        <div className="filter-option">
          <div className="filter-option-head hor-line-after">
            <div className="mb-1">
              <i className="fas fa-coins pr-1" />
              { dictionary.labels.price } { dictionary.labels.grossPrice }
            </div>
          </div>
          <Input
            type="number"
            name="grossprice_from"
            state={state}
            onChange={onChange}
          >
            {dictionary.labels.from}
          </Input>
          <Input
            type="number"
            name="grossprice_to"
            state={state}
            onChange={onChange}
          >
            {dictionary.labels.to}
          </Input>
        </div>

        <div className="filter-option">
          <div className="filter-option-head hor-line-after">
            <div className="mb-1">
              <i className="fas fa-coins pr-1" />
              { dictionary.labels.price } { dictionary.labels.netPrice }
            </div>
          </div>
          <Input
            type="number"
            name="netprice_from"
            state={state}
            onChange={onChange}
          >
            {dictionary.labels.from}
          </Input>
          <Input
            type="number"
            name="netprice_to"
            state={state}
            onChange={onChange}
          >
            {dictionary.labels.to}
          </Input>
        </div>
      </div>
      <div className="text-right">
        <div
          className="btn filter-btn-confirm mt-1 d-inline-block"
          onClick={() => {
            if (!isEmpty(state)) {
              history.push(buildSearchParams({
                ...state,
                offset: 0,
              }))
            }
            open(false)
          }}
        >
          { dictionary.buttons.filterUse }
        </div>
      </div>
    </div>
  )
}

const HistoryFilterButton = () => {
  const dictionary = useDictionary()
  const [opened, open] = useState(false)

  return (
    <div className="btn-filter">
      <div
        className="btn btn--secondary"
        onClick={() => open(!opened)}
      >
        { dictionary.buttons.filter }
        <i className="fas fa-filter pl-1" />
      </div>
      {
        !opened ? null :
        <HistoryFilterOptions open={open} />
      }
    </div>
  )
}

export default HistoryFilterButton
