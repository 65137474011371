import React from 'react'
import { compose, replace, trim, reduce, head, keys, values } from 'ramda'
import { useSelector } from 'react-redux'
import { DictionaryType } from './types'
import { selectLanguage } from '../store/selectors/api'
import czech from './czech'
import english from './english'

export const cleanText = compose(
  trim,
  replace(/<p>/g, ''),
  replace(/<\/p>/g, ''),
  replace(/<b>/g, ''),
  replace(/<\/b>/g, ''),
)

export const makeUnbreakable = compose(
  replace(/s /g, 's\u00a0'),
  replace(/v /g, 'v\u00a0'),
)

export const useDictionary = (): DictionaryType => {
  const chosenLanguage = useSelector(state => selectLanguage(state).chosen)

  if (chosenLanguage === 'CSY') {
    return czech
  }

  return english
}

export const passArguments = (text: string, ...args: any) => reduce((p: any, a: any) => {
  // @ts-ignore
  const toReplace = `%${head(keys(a))}`
  const re = new RegExp(toReplace, 'g')
  return replace(re, head(values(a)), p)
}, text, args)

export const provideDictionary = (Component: ({ dictionary }: { dictionary: DictionaryType }) => JSX.Element) => () => {
  const dictionary = useDictionary()

  return <Component dictionary={dictionary as DictionaryType} />
}
