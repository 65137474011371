import React from 'react'
import { useDispatch } from 'react-redux'
import { useDictionary } from '../../dictionary'
import { setUi } from '../../store/actions/ui'

type Props = {
  order: any,
}

const PayOrderButton = ({ order }: Props) => {
  const dictionary = useDictionary()
  const dispatch = useDispatch()

  return (
    <div
      className="btn text-center m-0"
      onClick={() => {
        dispatch(setUi({
          openedDialog: {
            id: 'pay-order',
            data: order,
          }
        }))
      }}
    >
      { dictionary.actions.pay }
    </div>
  )
}

export default PayOrderButton
