import { compose, reduce, map, assoc, prop, trim, replace, tail, mapObjIndexed, path, head, splitAt, split, test, values } from 'ramda'
import moment from 'moment'

// Converts an array to an object, where records are identified by {getKey} function.
// optional: initArray can be provided.
// Example: convertArrayToObject(x => x.id)([{id: 1, value: 9}]) => {1: {id: 1, value: 9}}
export const convertArrayToObject = (getKey: (a: any) => any, initArray = {}, forceUpdate = false, mergeItems = false) =>
  reduce(
    (a, b: object) => forceUpdate || !prop(getKey(b), initArray) ?
      assoc(getKey(b), b, a) :
      mergeItems ?
        assoc(getKey(b), {
          ...prop(getKey(b), initArray),
          ...b,
        }, a) :
        a,
    initArray
  )

// Returns true if truthFn is true for every item in the list
// Empty array returns true
export const areAllItemsTrue = (truthFn: (item: any) => any) => compose(
  reduce((a, b) => Boolean(a && b), true),
  map(truthFn)
)

export const formatDatum = (datumString: string) => moment(datumString, 'YYYY-MM-DD').format('DD. M. YYYY')

export const formatTimeCode = compose(
  replace(/-/, ' - '),
  replace(/\d\d-\d\d/, digit => `:${digit}:`),
  trim,
)

export const buildCategoryPaths = (data: any) => {
  let res: any[] = []
  const goThrough = (category: any, parents: any) => {
    if (path(['categories'], category)) {
      // @ts-ignore
      map(a => goThrough(a, [path(['id'], category), ...parents]), path(['categories'], category))
      res.push([path(['id'], category), ...parents])
    } else {
      res.push([path(['id'], category), ...parents])
    }
  }
  map(a => goThrough(a, []), data)
  return mapObjIndexed(tail)(convertArrayToObject(head)(res))
}

export const formatPrice = (num: number = 0): string => {
  if (num.toLocaleString) {
    return num.toLocaleString('cs-CS', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }
  return compose(
    // @ts-ignore
    priceString => test(/,/, priceString) ? replace(/,\d$/, d => `${d}0`, priceString) : `${priceString},00`,
    replace(/\./g, ','),
    replace(/\.\d\d+$/, digit => splitAt(3, digit)[0]),
  )(`${Math.round(num * 100) * 0.01}`)
}

export const extractSearchParams = compose(
  reduce((a: any, b: any) => !b[0] ? a : assoc(b[0], b[1], a), {}),
  map(split(/=/)),
  split(/&/),
  replace('?', ''),
)

export const setSearchParam = (param: string, value: string | number) => compose(
  (result: string) => result[0] === '?' ? result : `?${result}`,
  (result: string) => test(new RegExp(`${param}=${value}`), result) ? result : `${result}${result && '&'}${param}=${value}`,
  replace(new RegExp(`${param}=[^&]+`), `${param}=${value}`)
)

export const buildSearchParams = compose(
  reduce((a: string, b: string[]) => setSearchParam(b[0], b[1])(a), ''),
  // @ts-ignore
  values,
  mapObjIndexed((item, index) => [index, item])
)

export const makeHtml = compose(
  replace(/&lt;/g, '<'),
  replace(/&gt;/g, '>'),
  replace(/(\\r|\\n)/g, ''),
)

// Returns true if application should display mobile version
export const isMobile = (): boolean => window.innerWidth <= 1200
