import React from 'react'

const PrintPageButton = () => {
  return (
    <div
      className="print-page-btn clickable"
      onClick={() => {
        window.print()
      }}
    >
      <i className="fas fa-print" />
    </div>
  )
}

export default PrintPageButton
