import React from 'react'
import { useSelector } from 'react-redux'
import { path } from 'ramda'
import OrderContent from '../components/order/OrderContent'
import Help from '../components/order/Help'
import BackToEshopButton from '../components/buttons/BackToEshop'
import ToOrderButton from '../components/buttons/ToOrder'
import PrintPageButton from '../components/buttons/PrintPage'
import { useDictionary } from '../dictionary'
import { selectSignedUser, selectDeliveryAddress } from '../store/selectors/api'
import { formatDatum } from '../utils'
import Logo from '../components/Logo'

const CartPage = () => {
  const dictionary = useDictionary()
  const userName = useSelector(path(['api', 'login', 'user'])) || ''
  const deliveryDatum = useSelector(path(['api', 'env', 'data', 'date'])) || ''
  const address = useSelector(selectDeliveryAddress) || ''
  const signedUser = useSelector(selectSignedUser)
  const signedUserName = path(['name'], signedUser) || ''

  return (
    <div className="cart-content mobile-text-content">

      <div className="no-print-hide print-content-header d-inline-block">
        <Logo />
        <h1>{dictionary.cart.printH1}</h1>
      </div>

      <div className="d-flex-space-between mb-2 mt-1 print-hide">
        <BackToEshopButton />
        <ToOrderButton />
      </div>

      <div className="cart-order-info">
        <PrintPageButton />
        <h1> { dictionary.labels.order } </h1>
        <div className="mb-1">
          <i className="fas fa-suitcase" />
          {dictionary.labels.customer}: <strong>{userName as string}</strong>
        </div>

        <div className="mb-1">
          <i className="fas fa-user-circle" />
          {dictionary.labels.user}: <strong>{signedUserName as string}</strong>
        </div>

        <div className="mb-1">
          <i className="fas fa-calendar" />
          {dictionary.cart.datum}: <strong>{formatDatum(deliveryDatum as string)}</strong>
        </div>

        <div>
          <i className="fas fa-map-marker-alt" />
          {dictionary.cart.address}: <strong>{address as string}</strong>
        </div>
      </div>

      <div className="order-box position-relative">
        <div className="order-box-content">
          <OrderContent />
        </div>
      </div>

      <Help />
    </div>
  )
}

export default CartPage
