import React from 'react'
import { useDispatch } from 'react-redux'
import { useDictionary } from '../../dictionary'
import { useLanguage } from '../../hooks/api'
import { setUi } from '../../store/actions/ui'
import { switchSegmentLabel, switchSegmentLink, buildAbsoluteLink } from '../../config'

type Props = {
  instant?: boolean,
}

const ChangeEnvironmentButton = ({ instant }: Props): JSX.Element | null => {
  const dictionary = useDictionary()
  const language = useLanguage()
  const dispatch = useDispatch()

  if (!switchSegmentLabel(language)) {
    return null
  }

  return (
    <div
      className="link d-inline va-middle"
      onClick={() => {
        if (instant) {
          // const href = isRetail ? '/b' : '/r/autologin'
          // const href = buildAbsoluteLink(switchDomain(window.location.hostname))
          window.location.href = switchSegmentLink(language)
        } else {
          dispatch(setUi({ openedDialog: { id: 'change-environment' }}))
        }
      }}
    >
      <i className="fas fa-exchange-alt pr-1" />
      <span>
        {
          switchSegmentLabel(language)
        }
      </span>
    </div>
  )
}

export default ChangeEnvironmentButton
