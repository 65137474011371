import React from 'react'
import phoneImage from '../../images/phone.png'
import Phone from '../../contact/Phone'
import { useDictionary } from '../../dictionary'
import Email from '../../contact/Email'

const Help = () => {
  const dictionary = useDictionary()
  return (
    <div className="help">
      <h3> {dictionary.info.needHelp} </h3>
      <div className="d-inline-flex">
        <i className="fas fa-phone no-print-hide" />
        <img className="print-hide" src={phoneImage} alt="phone" />
        <div className="d-inline-block"><strong><Phone /></strong></div>
      </div>
      <div className="no-print-hide d-inline-flex">
        <i className="fas fa-envelope" />
        <strong><Email /></strong>
      </div>
    </div>
  )
}

export default Help
