import React, { useState } from 'react'
import { Link } from '../Link'
import MobileTopSubmenu from './MobileTopSubmenu'
import Dropdown from './DropDown'
import Email from '../../contact/Email'
import { usePhone } from '../../contact/Phone'
import { useConfigParam } from '../../hooks/config'
import { buildImageSource } from '../../config'

const logoStyle = {
  verticalAlign: 'middle',
  paddingBottom: '4px'
}

const MobileTopMenu = () => {
  const [toggled, toggle] = useState(false)
  const phone = usePhone()
  const logo = useConfigParam('logo_mobile')

  return (
    <div id="topMenu" className="menu-top position-relative menu-top--mobile" onClick={e => e.stopPropagation()}>
      <div className="tiles tiles--left">
        <Link to="/" className="tile">
          <img src={buildImageSource(logo)} alt="logo" width="32" height="32" style={logoStyle} />
        </Link>
      </div>
      <div className="tiles tiles--middle">
        <div className="tile">
          <a href={`tel:${phone}`}>
            <i className="fas fa-phone-alt" />
          </a>
        </div>
        <div className="tile">
          <Email>
            <i className="fas fa-envelope" />
          </Email>
        </div>
      </div>
      <div className="tiles tiles--right" onClick={() => toggle(!toggled)}>
        <div className="tile">
          <i className="fas fa-user-circle" />
        </div>
      </div>
      {
        !toggled
          ? null
          :
            <Dropdown open={toggle}>
              <MobileTopSubmenu />
            </Dropdown>
      }
    </div>
  )
}

export default MobileTopMenu
