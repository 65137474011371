import React from 'react'
import { useDictionary } from '../../dictionary'

const TableHead = () => {
  const dictionary = useDictionary()
  return (
    <tr>
      <th className="uppercase">{dictionary.footer.about}</th>
      <th className="uppercase"></th>
      <th className="uppercase">{dictionary.footer.actuality}</th>
    </tr>
  )
}

export default TableHead
