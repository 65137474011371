import React, { useCallback } from 'react'
import { path } from 'ramda'
import { connect, useDispatch } from 'react-redux'
import { formatDatum } from '../../utils'
import { setUi } from '../../store/actions/ui'

type Props = {
  datum: string | undefined,
  mobileVersion: boolean,
}

const DatumInput = ({ datum = '', mobileVersion }: Props) => {
  const dispatch = useDispatch()
  const onClick = useCallback(() => dispatch(setUi({ openedDialog: {id: 'change-calendar' } })), [])

  if (mobileVersion) {
    return (
      <div className="input-group w-50 d-inline-flex clickable" onClick={onClick}>
        <span className="input-prepend"><i className="fas fa-calendar" /></span>
        <input className="clickable" type="text" value={formatDatum(datum)} readOnly />
      </div>
    )
  } else {
    return (
      <div className="input-group clickable" onClick={onClick}>
        <input className="clickable" type="text" value={formatDatum(datum)} readOnly />
        <span className="input-append"><i className="fas fa-calendar" /></span>
      </div>
    )
  }
}

export default connect(state => ({
  datum: path(['api', 'env', 'data', 'date'], state),
  // @ts-ignore
}))(DatumInput)
