import React from 'react'
import { path } from 'ramda'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { selectCanProceedToOrder, selectCreatedOrder } from '../store/selectors/api'
import OrderForm from '../components/order/OrderForm'
// import OrderCompleted from '../components/order/OrderCompleted'
import { useDictionary } from '../dictionary'
import { resourcePaths } from '../store/actions/api'
import { createFetchStatusAlert } from '../hocs/alert'
import BackToEshopButton from '../components/buttons/BackToEshop'

type Props = {
  enabled: boolean,
  createdOrder: unknown,
}

const Alert = createFetchStatusAlert({ method: 'post', formPath: resourcePaths.createdOrder() })

const Order = ({ enabled, createdOrder }: Props) => {
  const dictionary = useDictionary()

  if (createdOrder) {
    const redir = path(['redir'], createdOrder)
    const action = path(['action'], createdOrder)
    const redirParam = redir ? `&redir=${global.encodeURIComponent(redir as string)}` : ''
    const actionParam = action ? `&action=${action}` : ''

    return (
      <Redirect to={`/payment?type=outgoing&pay_id=${path(['pay_id'], createdOrder)}&docnum=${path(['docnum'], createdOrder)}${actionParam}${redirParam}`} />
    )
    // return (
    //   <OrderCompleted />
    // )
  }

  if (!enabled) {
    return (
      <Redirect to={`/`} />
    )
  }

  return (
    <div className="mobile-text-content">
      <div className="d-flex-space-between mb-2 mt-1">
        <BackToEshopButton />
      </div>
      <div className="mr-2">
        <Alert />
      </div>
      <h1>{dictionary.forms.order.heading}</h1>
      <OrderForm />
    </div>
  )
}

export default connect(state => ({
  enabled: selectCanProceedToOrder(state),
  createdOrder: selectCreatedOrder(state),
}))(Order)
