import React from 'react'
import ReactDOM from 'react-dom'
import { path } from 'ramda'
// @ts-ignore
import { hexToCSSFilter } from 'hex-to-css-filter'
import { useConfigParam } from '../hooks/config'

// Changes styles according to given config parameters
const ConfigStyles = () => {
  const colorButtonEnabled = useConfigParam('colorButtonEnabled')
  const colorAlertInfo = useConfigParam('colorAlertInfo')
  const colorBg = useConfigParam('colorBg')
  const colorFg = useConfigParam('colorFg')
  const colorProdTag = useConfigParam('colorProdTag')
  const colorProdTagNew = useConfigParam('colorProdTagNew')
  const colorInputFocus = useConfigParam('colorInputFocus')
  const colorButtonDisabled = useConfigParam('colorButtonDisabled')
  const colorButtonEnabledHover = useConfigParam('colorButtonEnabledHover')
  const colorGrey300 = useConfigParam('colorGrey300')
  const colorGrey500 = useConfigParam('colorGrey500')
  const colorGrey600 = useConfigParam('colorGrey600')
  const colorGrey700 = useConfigParam('colorGrey700')
  const colorGrey800 = useConfigParam('colorGrey800')
  const colorErrorPrimary = useConfigParam('colorErrorPrimary')
  const colorErrorLight = useConfigParam('colorErrorLight')
  const colorErrorDark = useConfigParam('colorErrorDark')
  const colorH1 = useConfigParam('colorH1')
  const colorAccountBg = useConfigParam('colorAccountBg')
  const colorSegment = useConfigParam('colorSegment') || '#73152f'
  const colorSegmentFilter = path(['filter'], hexToCSSFilter(colorSegment))

  return ReactDOM.createPortal(
    <style>
      {
        `
        :root {
          --color-green: ${colorButtonEnabled};
          --color-green-light: ${colorAlertInfo};
          --color-white: ${colorBg};
          --color-black: ${colorFg};
          --color-badge: ${colorProdTag};
          --color-badge-new: ${colorProdTagNew};
          --color-focus: ${colorInputFocus};
          --color-button-secondary: ${colorButtonDisabled};
          --color-button-primary-selected: ${colorButtonEnabledHover};
          --color-light-grey: ${colorGrey300};
          --color-grey-yellow: #FCFCFC;
          --color-grey: ${colorGrey500};
          --color-grey-darker: ${colorGrey600};
          --color-grey-dark: ${colorGrey700};
          --color-grey-darkest: ${colorGrey800};
          --color-red: ${colorErrorPrimary};
          --color-red-light: ${colorErrorLight};
          --color-red-dark: ${colorErrorDark};
          --color-h1: ${colorH1};
          --color-account-page-background: ${colorAccountBg};
          --color-theme: ${colorSegment};
          --color-theme-filter: ${colorSegmentFilter};
        }

        `
      }
    </style>,
    document.head,
  )
}

export default ConfigStyles
