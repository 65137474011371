import { createLogger } from 'redux-logger'
import { isDevelopment } from '../../config'
import fetchMiddleware from './fetch'

export default () => {
  const middleware = [
    fetchMiddleware,
  ]
  
  // Create-react-app should set NODE_ENV to 'production'
  // Logger must be the last middleware in chain and only in development mode.
  if (isDevelopment) {
    const logger = createLogger({ collapsed: true })
    middleware.push(logger)
  }

  return middleware
}
