import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PasswordChange from '../components/account/PasswordChange'
import Account from '../components/account'
import Customers from '../components/account/Customers'
import History from '../components/account/History'
import Navigation from '../components/account/Navigation'
import OrderDetail from '../components/account/OrderDetail'
import OrderItem from '../components/account/OrderItem'

const MePage = () => {
  return (
    <div>
      <Navigation />
      <Switch>
        <Route path="/me/password">
          <PasswordChange />
        </Route>
        <Route path="/me/customers">
          <Customers />
        </Route>
        <Route path="/me/history/:orderId/:productUrl">
          <OrderItem />
        </Route>
        <Route path="/me/history/:orderId">
          <OrderDetail />
        </Route>
        <Route path="/me/history">
          <History />
        </Route>
        <Route path="/me">
          <Account />
        </Route>
      </Switch>
    </div>
  )
}

export default MePage
