import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { path } from 'ramda'
import { useDictionary } from '../dictionary'
import { createForm, createValidator } from '../hocs/form'
import { PutLoader } from '../components/Loading'
import { resourcePaths } from '../store/actions/api'
import { extractSearchParams } from '../utils'
import { PasswordResetForm } from './passwordResetRequest'
import { AppStateType } from '../store/types'
import jwtDecode from 'jwt-decode'
import moment from 'moment'

const formPath = ['password', 'reset']
const Form = createForm(formPath, {
  NewPassword: { key: 'new', type: 'password' },
})

const NewPasswordValidator = createValidator([...formPath, 'new'])

const resourcePath = resourcePaths.passwordReset()

const isValidToken = (token: string | undefined) => {
  try {
    const decoded = jwtDecode(token || '')
    // @ts-ignore
    const expired = moment(decoded.exp * 1000) < moment()
    
    if (expired) {
      return false
    }
    return true
  } catch (e) {
    console.warn(e)
    return false
  }

  return false
}

const PasswordResetPage = () => {
  const dictionary = useDictionary()
  const history = useHistory()
  const token = path(['token'], extractSearchParams(history.location.search)) as string | undefined
  const is401Response = useSelector((state: AppStateType) => path(['password', 'reset', 'code'], state.fetch.status.put) === 401)

  return (
    <div className="password-reset">
      <div className="dialog-overlay">
        <div className="centered position-absolute text-center">
          <div className="dialog-content">
            <div className="dialog-content-inner">
              <div className="heading mb-2">
                <h1> {dictionary.forms.password.resetTitle} </h1>
              </div>
              {
                !isValidToken(token) || is401Response
                ?
                <div>
                  <div className="mb-1"> {dictionary.errors.resetPasswordInvalidToken} </div>
                  <PasswordResetForm />
                </div>
                : <React.Fragment>
                  <label className="mb-decent d-block"> {dictionary.forms.password.newPassword} </label>
                  <NewPasswordValidator className="input-group d-block mb-2">
                    <Form.NewPassword />
                  </NewPasswordValidator>

                  <PutLoader path={resourcePath}>
                    <Form.Submit className="btn btn--primary w-100 ml-0">
                      {dictionary.forms.password.resetConfirm}
                    </Form.Submit>
                  </PutLoader>
                </React.Fragment>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PasswordResetPage
