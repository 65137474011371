import React from 'react'
import AboutCompanyList from './AboutCompanyList'
import ActualityList from './ActualityList'
import TableHead from './TableHead'
import Email from '../../contact/Email'
import { useFacebookLink } from '../../contact/Facebook'
import { useInstagramLink } from '../../contact/Instagram'
import Phone from '../../contact/Phone'
import { useConfigParam } from '../../hooks/config'
import { buildImageSource } from '../../config'

const Footer = () => {
  const address = useConfigParam('contact_addr')
  const company = useConfigParam('contact_company')
  const fbLink = useFacebookLink()
  const igLink = useInstagramLink()
  const backgroundImage = useConfigParam('bg_img_footer')
  const logo = useConfigParam('logo_footer')
  const copyright = useConfigParam('copyright')

  return (
    <footer
      style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url(${buildImageSource(backgroundImage)})`}}
    >
      <div className="content">
        <div className="footer-top">
          <div className="footer-logo-container">
            <img className="footer-logo" src={buildImageSource(logo)} alt="logo" />
            <p dangerouslySetInnerHTML={{ __html: company }} />
            <p>{address}</p>
          </div>
          <div className="footer-table-container text-left">
            <table>
              <thead>
                <TableHead />
              </thead>
              <tbody>
                <tr>
                  <AboutCompanyList />
                  <ActualityList />
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="d-inline-block">
            {copyright}
          </div>
          <div className="d-inline-block text-center">
            <Phone /> | <Email />
          </div>
          <div className="d-inline-block text-right">
            {
              fbLink &&
              <a className="circle" href={fbLink}>
                <i className="fab fa-facebook-f" />
              </a>
            }
            {
              igLink &&
              <a className="circle" href={igLink}>
                <i className="fab fa-instagram" />
              </a>
            }
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
