import React, { useState } from 'react'
import { map, path, filter } from 'ramda'
import { useSelector, useDispatch } from 'react-redux'
import Flag from '../Flag'
import DropDown from './DropDown'
import { postLanguage } from '../../store/actions/api'
import { selectLanguage } from '../../store/selectors/api'

const LanguageChanger = () => {
  const [opened, open] = useState(false)
  const { options, chosen } = useSelector(selectLanguage)
  const dispatch = useDispatch()
  return (
    <li className="position-relative no-separator clickable language-changer" onClick={(e) => {
      e.stopPropagation()
      open(!opened)
    }}>
      <span><Flag className="va-middle" language={chosen} /></span>
      <i className="fas fa-angle-down" />
      {
        !opened ? null :
        <DropDown open={open}>
          <div
            className="drop-down drop-down--right text-center language-changer-item"
            onClick={e => e.stopPropagation()}>
            {
              // @ts-ignore
              map(option =>
                <div key={path(['lang'], option)} onClick={() => {
                  // @ts-ignore
                  dispatch(postLanguage({
                    lang: path(['lang'], option) || '',
                  }, () => {
                    setTimeout(() => window.location.reload(), 200)
                  }))
                }}>
                <Flag language={path(['lang'], option)} />
                </div>,
                // @ts-ignore
                filter(option => path(['lang'], option) !== chosen, options || [])
              )
            }
          </div>
        </DropDown>
      }
    </li>
  )
}

export default LanguageChanger
