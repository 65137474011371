import React from 'react'
import { path, last, length } from 'ramda'
import { connect } from 'react-redux'
import { Link } from '../Link'
import { SearchResultCategoryType } from '../../store/types/categories'

type Props = {
  category: SearchResultCategoryType,
}

type ConnectedProps = {
  categoryPath: unknown,
  categoryRegister: unknown,
}

const CategorySearchResultItem = ({ category, categoryPath, categoryRegister }: Props & ConnectedProps) => {
  // @ts-ignore
  const categoryUrl = path([last(categoryPath || []), 'url'], categoryRegister)
  // @ts-ignore
  const link = length(categoryPath || []) > 1
    ? `/${categoryUrl}/category/${path([0], categoryPath)}/subcategory/${category.id}`
    : `/${categoryUrl}/category/${category.id}`
  return (
    <Link to={link} className="subcategory-item">
      {category.name}
    </Link>
  )
}

export default connect((state, props: Props) => ({
  categoryPath: path(['api', 'categories', 'categoryPaths', props.category.id], state),
  categoryRegister: path(['api', 'categories', 'register'], state),
}))(CategorySearchResultItem)
