import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useDictionary } from '../dictionary'
import { dismissError } from '../store/actions/fetch'

type Props = {
  message: string,
  type: 'error' | 'alert' | 'info',
  formPath: string[],
  status?: string | undefined,
}

const Alert = ({ message, status, type, formPath }: Props) => {
  const dispatch = useDispatch()
  const dictionary = useDictionary()
  useEffect(() => {
    return () => {
      // On unmount, remove alert
      dispatch(dismissError(formPath))
    }
  }, [])
  return (
    <div className={`alert alert--${type} position-relative`}>
      <div className="position-absolute close" onClick={() => dispatch(dismissError(formPath))}>
        <i className="fas fa-times" />
      </div>
      <i className="fas fa-exclamation-triangle mr-1" />
      {
        status === 'ERR-AUTH'
          ? dictionary.errors.AUTH :
        status === 'ERR-OTHER'
          ? dictionary.errors.OTHER :
        status === 'ERR-DATE'
          ? dictionary.errors.DATE :
        status === 'ERR-MAXQTY'
          ? dictionary.errors.MAXQTY :
        status === 'ERR-PAYNF'
          ? dictionary.errors.PAYNF :
        status === 'ERR-PAID'
          ? dictionary.errors.PAID :
        status === 'ERR-QTYLT'
          ? dictionary.errors.QTYLT :
        status === 'ERR-QTYGT'
          ? dictionary.errors.QTYGT :
        status === 'ERR-QTYDMG'
          ? dictionary.errors.QTYDMG :
        status === 'ERR-MAIL'
          ? dictionary.errors.MAIL :
        message
      }
    </div>
  )
}

export default Alert
