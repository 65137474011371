import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { path } from 'ramda'
// import { Link } from '../Link'
import { useDictionary } from '../../dictionary'
import OrderError from './OrderError'

const PayRedirect = ({ to, action }: { to: unknown, action: unknown }) => {
  const dictionary = useDictionary()
  const [seconds, update] = useState(5)
  useEffect(() => {
    let secs = seconds
    let interval: any = null

    const callback = () => {
      secs = secs - 1
      if (secs < 0) {
        clearInterval(interval)
        window.location.href = `${to}`
      } else {
        update(secs)
      }
    }
    interval = setInterval(callback, 1000)
    return () => clearInterval(interval)
  }, [to])
  return (
    <div className="thank-you">
      <h1>{dictionary.thankYou.heading}</h1>
      <div><i className="fas fa-check big-icon" /></div>
      <h2>{dictionary.thankYou.title}</h2>
      <p>{action === 'pgw-csob' ? dictionary.thankYou.paygateDescription : dictionary.thankYou.paygateDescriptionSodexo }</p>

      <div className="mt-2">
        <a className="btn btn--primary ml-0 bold" href={`${to}`}>
          { dictionary.buttons.paygateGo }
          { seconds > 0 ? ` (${seconds})` : null }
        </a>
      </div>
    </div>
  )
}

type OrderCompletedProps = {
  redir: unknown,
  action: unknown,
  payId: unknown,
  docnum: unknown,
  preventAutoRedirect?: boolean,
}

const OrderCompleted = ({ redir, action, payId, docnum, preventAutoRedirect }: OrderCompletedProps) => {
  const dictionary = useDictionary()

  if (redir && !preventAutoRedirect) {
    return (
      <div className="thank-you">
        <PayRedirect to={redir} action={action} />
      </div>
    )
  }

  if ((!redir && action && action !== 'none') || redir) {
    return (
      <OrderError
        infoType
        payId={`${payId}`}
        h1={dictionary.thankYou.headingError}
        h2={dictionary.thankYou.orderPaidError}
        p={dictionary.thankYou.orderPaidDescriptionError}
      />
    )
  }

  return (
    <Redirect to={`/thank-you?order=${docnum}`} />
  )
}

export default OrderCompleted
