import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { setUi } from '../../store/actions/ui'
import { DialogType } from '../../store/types/ui'

type Props = {
  setUi: (ui: { openedDialog: DialogType }) => void,
  children: any,
  className?: string | undefined,
}

const DialogWrapper = ({ setUi, children, className = 'dialog-content' }: Props) => {
  useEffect(() => {
    const callback = (e: KeyboardEvent) => {
      // Escape pressed?
      if (e.keyCode === 27) {
        setUi({ openedDialog: { id: null }})
      }
    }
    window.addEventListener('keyup', callback)

    return () => window.removeEventListener('keyup', callback)
  }, [])
  return (
    <div className="dialog-overlay">
      <div className={`${className} dialog-add-product position-relative`}>
        <div className="dialog-close" onClick={() => setUi({ openedDialog: { id: null } })}>
          ×
        </div>
        <div className="dialog-content-inner">
          { children }
        </div>
      </div>
    </div>
  )
}

export default connect(null, { setUi })(DialogWrapper)
