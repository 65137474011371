import React from 'react'
import { path, map, filter, addIndex, groupWith } from 'ramda'
import { connect } from 'react-redux'
import ListItem from './ListItem'
import { ProductType } from '../../store/types/products'
import { AppStateType } from '../../store/types'
import { convertArrayToObject } from '../../utils'
import MoreText from './MoreText'
import { useMobileUi } from '../../hooks/ui'
import ProductListAppearanceSwitch from './ProductListAppearanceSwitch'

const mapIndexed = addIndex(map)

type Props = {
  path: string[],
  list: ProductType[] | undefined,
  categoryId: string | number | undefined,
  categories: undefined | {id: number}[],
  filters?: {
    [prop: number]: boolean,
  },
  sectionRegister: {
    [prop: string]: {
      id: number,
      name: string,
    }
  }
  appearance: 'list' | 'grid' | undefined,
}

const ProductList = ({ list = [], filters, sectionRegister, categoryId, categories, appearance }: Props) => {
  const listToDisplay = !filters ? list : (filter(pr => !Boolean(path([pr.section || pr.cat_id], filters)), list) as ProductType[])
  const categoryMap = convertArrayToObject(path(['id']))(categories || [])
  const isMobile = useMobileUi()

  // @ts-ignore
  const groups = groupWith(
    (productA, productB) =>
      productA.section && productB.section ?
      (productA.section === productB.section) :
      (productA.cat_id === productB.cat_id),
    listToDisplay
  )

  return (
    <>
      <ProductListAppearanceSwitch />
      {
        map(group => {
          const heading = (path([0, 'section'], group) && path([path([0, 'section'], group) || '', 'name'], sectionRegister)) ||
            (path([0, 'cat_id'], group) && path([path([0, 'cat_id'], group) || '', 'name'], categoryMap))

          const text = path([path([0, 'cat_id'], group) || '', 'text'], categoryMap) || path([path([0, 'section'], group) || '', 'text'], sectionRegister)

          return (
            <React.Fragment key={path([0, 'id'], group)}>
              {heading ? <h2 className={`category-heading${text ? '' : ' hor-line-after hor-line-after--mt-2'}`}>{heading as string}</h2> : null}
              {
                text ?
                  <div className="category-description hor-line-after hor-line-after--mt-2">
                    <MoreText text={text as string} />
                  </div>
                  : null
              }
              <ul className={appearance === 'grid' && !isMobile ? 'grid' : 'list'}>
                {
                  mapIndexed((product, index: number) => {
                    return (
                      <li
                        key={`${path(['id'], product)}-${path(['section'], product)||''}`}
                        id={index === 0 ? `${path(['section'], product) || path(['cat_id'], product)}` : undefined}
                      >
                        <ListItem product={product as ProductType} section={path([0, 'section'], group) || path([0, 'cat_id'], group)} />
                      </li>
                    )
                  }, group)
                }
              </ul>
            </React.Fragment>
          )
        }, groups)
      }
    </>
  )
}

export default connect((state: AppStateType, { path: resourcePath, categoryId }: Props) => ({
  list: path(['api', ...resourcePath, 'data' ], state),
  categories: path(['api', 'categories', 'subRegister', categoryId || '', 'categories'], state),
  sectionRegister: path(['api', ...resourcePath, 'sectionRegister'], state),
  filters: path(['ui', 'filterCategory', categoryId || ''], state),
  appearance: state.ui.productListAppearance,
  // @ts-ignore
}))(ProductList)
