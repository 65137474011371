import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useDictionary } from '../../dictionary'
import { selectCart } from '../../store/selectors/api'
import { OrderBoxContent } from './Box'
import { fetchOnMount } from '../../hocs/fetch'
import { getOrder } from '../../store/actions/api'
import { setUi } from '../../store/actions/ui'
import { AppStateType } from '../../store/types'
import { formatPrice } from '../../utils'

const MobileOrder = () => {
  const dictionary = useDictionary()
  const dispatch = useDispatch()
  const cart = useSelector(selectCart)
  const opened = useSelector((state: AppStateType) => state.ui.mobileOrderOpened)

  return (
    <div>
      <div className="mobile-order-panel" onClick={() => dispatch(setUi({ mobileOrderOpened: !opened }))}>
        <div className="mobile-order-panel-content">
          <i className="fas fa-shopping-cart" />
          <span>
            <strong>{ dictionary.labels.order }</strong>
            {
              cart && cart.endpricenet
                ? <span>({formatPrice(cart.endpricenet)} Kč)</span>
                : null
            }
          </span>
          <i className={`fas fa-angle-${!opened ? 'down' : 'up'}`} />
        </div>
      </div>

      {
        !opened ? null :
        <OrderBoxContent hideHeading />
      }
    </div>
  )
}

export default fetchOnMount({
  fetchAction: () => getOrder()
})(MobileOrder)
