import React from 'react'
import { connect } from 'react-redux'
import { path, map, isEmpty, splitEvery } from 'ramda'
import Image from '../Image'
import { Link } from '../Link'

const ListItem = connect((state, props) => ({
  category: path(['api', 'categories', 'register', path(['id'], props) || ''], state)
  // @ts-ignore
}))(({ category }: {id: string, category: object | undefined }) => !category ? null :
  isEmpty(path(['categories'], category) || []) ? null :
  <div className="category-container">
    <h1> {path(['name'], category)} </h1>
    <div className="category-table">
      {
        map(group => {
          return (
            <div key={path([0, 'id'], group)} className="category-table-row">
            {
              map(cat => (
                <Link key={path(['id'], cat)} className="category-item" to={`/${path(['url'], category)}/category/${path(['id'], cat)}#topMenu`}>
                  <Image code={path(['image'], cat)} width="40" />
                  <div> {path(['name'], cat)} </div>
                </Link>
              ), group)
            }
            </div>
          )
        }, splitEvery(3, path(['categories'], category) || []))
      }
    </div>
  </div>
)

const IndexPageContent = ({ list = [] }: { list: number[] | undefined }) =>
  map(categoryId => <ListItem key={categoryId} id={categoryId} />, list)


export default connect(state => ({
  list: path(['api', 'categories', 'list'], state),
  // @ts-ignore
}))(IndexPageContent)
