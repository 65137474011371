import { createStore, applyMiddleware, compose } from 'redux'
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync'
import { persistStore, persistReducer } from 'redux-persist'
import { createFilter } from 'redux-persist-transform-filter'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import createReducer from './reducers/createReducer'
import createStoreMiddleware from './middleware/createMiddleware'
import { ActionType } from '../store/types/actions'
import * as Sentry from "@sentry/react"

const reduxStateSyncConfig = {
  predicate: (action: ActionType) => action.sync,
}

// remember info about user after login and TOKEN
// to be able to restore data after page reload
const apiFilter = createFilter(
  'api',
  undefined,
  ['login', 'env'],
)

const uiFilter = createFilter(
  'ui',
  undefined,
  ['rememberMe', 'lastNewDayOpened', 'cookieConsent', 'productListAppearance'],
)

const transforms = [
  apiFilter,
  uiFilter,
]

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['api', 'ui'],
  stateReconciler: autoMergeLevel2,
  transforms,
}

const createReduxStore = () => {
  const reducer = createReducer()
  // @ts-ignore @TODO solve typing here
  const persistedReducer = persistReducer(persistConfig, reducer)
  const middleware = createStoreMiddleware()
  // @ts-ignore
  const syncMiddleware = createStateSyncMiddleware(reduxStateSyncConfig)
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options
  })
  const storeEnhancer = compose(applyMiddleware(...middleware, syncMiddleware), sentryReduxEnhancer)
  const store = createStore(persistedReducer, {}, storeEnhancer)
  const persistor = persistStore(store)

  initMessageListener(store)

  return { store, persistor }
}

export default createReduxStore
