import React, { useState, useEffect } from 'react'
import { path, map, isEmpty, length } from 'ramda'
import { connect } from 'react-redux'
import { setUi } from '../../store/actions/ui'
import { postProductToOrder, resourcePaths } from '../../store/actions/api'
import { DialogType } from '../../store/types/ui'
import DialogWrapper from './DialogWrapper'
import { selectDeliveryTimes } from '../../store/selectors/api'
import { DeliveryTimeFrameType } from '../../store/types/api'
import Select from 'react-select'
import { PostLoader } from '../Loading'
import { convertArrayToObject } from '../../utils'
import Image from '../Image'
import Unit from '../Unit'
import { useDictionary } from '../../dictionary'
import { useRetail } from '../../hooks/api'
import { createFetchStatusAlert } from '../../hocs/alert'

type Props = {
  setUi: (ui: { openedDialog: DialogType }) => void,
  dialog: DialogType,
  amount: number | undefined,
  deliveryTimes: DeliveryTimeFrameType[],
  postProductToOrder: (params: { cartId: number, timeCode: string, productId: number, amount: number, [other: string]: any }) => void,
  cartId: number,
  activeDeliveryTimes: undefined | null | {timecode: string}[],
}

const loaderPath = resourcePaths.order()
const Alert = createFetchStatusAlert({ method: 'post', formPath: loaderPath })

const AddToOrderDialog = ({ setUi, dialog, amount, deliveryTimes = [], postProductToOrder, cartId, activeDeliveryTimes = [] }: Props) => {
  const dictionary = useDictionary()
  const isRetail = useRetail()
  const [chosen, setState] = useState('')
  const toAdd = !dialog.product ? 0 : (amount || dialog.product.props.minorder || 1)
  const timeMap = convertArrayToObject((t: DeliveryTimeFrameType) => t.id)(deliveryTimes)
  const setDefaultValue = length(activeDeliveryTimes || []) === 1 && !chosen

  // set default value
  useEffect(() => {
    if (setDefaultValue) {
      setState({
        // @ts-ignore
        value: path([activeDeliveryTimes![0].timecode, 'id'], timeMap) || '',
        label: path([activeDeliveryTimes![0].timecode, 'text'], timeMap) || '',
      })
    }
  }, [])

  return (
    !dialog.product ? null :
    <DialogWrapper className="dialog-content dialog-content--with-max-width">
      <h1>{dictionary.labels.insertProductIntoCart}</h1>

      <Alert />

      <div className="line-after pb-2">
        <div className="product-list-item">
          <div className="preview-container">
            <Image className="preview preview--detail" code={dialog.product.image} alt={dialog.product.label} small width="360"/>
          </div>
          <div className="product-content">
            <h2 className="bold">{dialog.product.label}</h2>
            <p> {toAdd} <Unit id={dialog.product.id_unit} /> </p>
          </div>
        </div>
      </div>

      <div>
        {
          isEmpty(activeDeliveryTimes || []) ? null :
          <React.Fragment>
            <div className="mb-decent"> {dictionary.labels.activeDeliveryTimes}: </div>
            {
              map(deliveryTime =>
                <div
                  key={deliveryTime.timecode}
                  className={`active-delivery${path(['value'], chosen) === deliveryTime.timecode ? ' is-selected' : ''}`}
                  onClick={() => {
                    setState({
                      // @ts-ignore
                      value: path([deliveryTime.timecode, 'id'], timeMap) || '',
                      label: path([deliveryTime.timecode, 'text'], timeMap) || '',
                    })
                  }}
                >
                  {
                    path([deliveryTime.timecode, 'text'], timeMap)
                  }
                </div>,
                activeDeliveryTimes || []
              )
            }
          </React.Fragment>
        }
        <div className="mb-decent"> {dictionary.labels.newDeliveryTime}: </div>
        {
          // @ts-ignore
          <Select
            options={map(time => ({ value: time.id, label: time.text }), deliveryTimes)}
            placeholder={dictionary.placeholders.deliveryTime}
            onChange={setState}
            value={chosen||''}
          />
        }
      </div>

      <div className="info position-relative">
        <i className="fas fa-info-circle" />
        <div dangerouslySetInnerHTML={{__html: isRetail ? dictionary.html.addProductToCartInfoRetail : dictionary.html.addProductToCartInfo }} />
      </div>

      <PostLoader path={loaderPath}>
        <div className="mt-2 text-center">
          <button
            className={`btn${chosen ? '' : ' btn--secondary'}`}
            disabled={!chosen}
            onClick={() => {
              postProductToOrder({
                cartId,
                amount: toAdd,
                timeCode: path(['value'], chosen) || '',
                productId: dialog.product!.id,
                onSuccess: () => {
                  setUi({ openedDialog: { id: null } })
                }
              })
            }}
          >
            {dictionary.buttons.addToOrder}
          </button>
        </div>
      </PostLoader>
    </DialogWrapper>
  )
}

export default connect((state, props: Props) => ({
  amount: props.dialog.product && path(['ui', 'amounts', props.dialog.product.id], state),
  deliveryTimes: selectDeliveryTimes(state),
  activeDeliveryTimes: path(['api', 'order', 'cart', 'dlv_times'], state),
  cartId: path(['api', 'order', 'cart', 'id'], state),
  // @ts-ignore
}), { setUi, postProductToOrder })(AddToOrderDialog)
