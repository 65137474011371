import React from 'react'
import { useDictionary } from '../../dictionary'
import NotCatalogContent from '../NotCatalogContent'
import { Link } from '../Link'

const MyAccountButton = () => {
  const dictionary = useDictionary()

  return (
    <NotCatalogContent>
      <Link to="/me/password" className="btn btn--secondary d-block">{dictionary.buttons.myAccount}</Link>
    </NotCatalogContent>
  )
}

export default MyAccountButton
