import React from 'react'
import { map } from 'ramda'

type Props = {
  options?: string[] | undefined,
  [prop: string]: any,
}

const Hint = ({ title, options, className, onChange, ...props }: Props) => {
  if (!options || options.length <= 0) {
    return null
  }
  return (
    <div className={`hint ${className || ''}`}>
      { title ? `${title}: ` : null }
      {
        map(option =>
          <span
            className="hint-option"
            key={option}
            onClick={() => onChange(option)}
          >
            {option}
          </span>,
          options
        )
      }
    </div>
  )
}

export default Hint
