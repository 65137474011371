import React from 'react'

type Props = {
  checked: boolean,
  onChange: (val: boolean) => void,
  [props: string]: any,
}

const Checkbox = ({ checked, onChange, ...props }: Props) => {
  return (
    <div className={`checkbox${checked ? ' checkbox--checked' : ''}`} onClick={() => onChange(!checked)}>
      {
        checked ? <i className="fas fa-check"/> : null
      }
    </div>
  )
}

export default Checkbox
