import React from 'react'
import { useSelector } from 'react-redux'
import { ProductType } from '../../../store/types/products'
import { useDictionary } from '../../../dictionary'
import { selectPriceType } from '../../../store/selectors/api'
import { formatPrice } from '../../../utils'

type Props = {
  product: ProductType
}

const Wrapper = ({ bold, children }: { bold: boolean, children: any }) =>
  bold
    ? <b> {children} </b>
    : <div> { children } </div>

const Price = ({ product }: Props) => {
  const dictionary = useDictionary()
  const priceType = useSelector(selectPriceType)
  return (
    <div className="d-inline-block m-d-block">
      <Wrapper bold={priceType === 'netprice'}> {formatPrice(product.netprice)} Kč {dictionary.labels.netPrice} </Wrapper>
      <Wrapper bold={priceType !== 'netprice'}> {formatPrice(product.grossprice)} Kč {dictionary.labels.grossPrice} </Wrapper>
    </div>
  )
}

export default Price
