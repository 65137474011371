import { useEffect } from 'react'
import { test, path } from 'ramda'

const DropDown = ({ open, ignoreClassName, children }: { children: any, open: (val: boolean) => void, ignoreClassName?: string }) => {
  useEffect(() => {
    const event = (e: MouseEvent) => {
      // @TODO this is hot-fix use better approach
      if (ignoreClassName) {
        const className = path(['className'], e.target) as string | undefined
        if (test(new RegExp(ignoreClassName), className || '')) {
          return
        }
      }
      open(false)
    }
    window.addEventListener('click', event)
    return () => window.removeEventListener('click', event)
  }, [open, ignoreClassName])
  return children
}

export default DropDown
