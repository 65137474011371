import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import ContentRouter from '../components/products/ContentRouter'
import OrderBox from '../components/order/Box'
import Navigation from '../components/Navigation'
import MobileOrder from '../components/order/MobileOrder'
import MobileTopFilters from '../components/header/MobileTopFilters'
import { useMobileUi } from '../hooks/ui'

const IndexPage = () => {
  const isMobile = useMobileUi()
  const matchMePage = useRouteMatch('/me')
  const matchCartPage = useRouteMatch('/cart')

  return (
    <div className="content content--mobile-wide">
      <div className="d-flex">
        <div className={`product-list-container${matchMePage || matchCartPage ? ' product-list-container--wide' : ''}`}>
          {
            isMobile
            ? <React.Fragment>
                {
                  !matchCartPage && <MobileOrder />
                }
                <MobileTopFilters />
              </React.Fragment>
            : <Navigation />
          }
          <ContentRouter />
        </div>
        {
          !isMobile
          ? (matchMePage || matchCartPage ? null : <OrderBox />)
          : null
        }
      </div>
    </div>
  )
}

export default IndexPage
