import React, { useState } from 'react'
import { useDictionary } from '../../dictionary'

type Props = {
  description: string
}

const ProductMiniDetail = ({ description }: Props) => {
  const [displayed, display] = useState(false)
  const dictionary = useDictionary()

  if (!displayed) {
    return (
      <div className="product-content-row text-center grey-background rounded p-1 link" onClick={() => display(true)}>
        {dictionary.actions.detailShow}
      </div>
    )
  }

  return (
    <div className="product-content-row grey-background rounded p-1">
      <div className="text-center link" onClick={() => display(false)}>
        {dictionary.actions.detailHide}
      </div>
      <div
        className="product-mini-detail product-detail-web-content"
        dangerouslySetInnerHTML={{__html: description}}
      />
    </div>
  )
}

export default ProductMiniDetail
