import React from 'react'
import { getApiPath, getInstanceId } from '../config'
import missing from '../images/missing.png'

type Props = {
  code: string | null | undefined,
  small?: boolean,
  middle?: boolean,
  [prop: string]: any
}

const Image = ({ code, small, middle, ...props }: Props) =>
  <img
    alt={code || 'missing'}
    src={!code ? missing : `${getApiPath()}/images/${getInstanceId()}/${small ? 'sml/' : middle ? 'mid/' : ''}${code}`}
    {...props}
  />

export default Image
