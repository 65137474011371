import { FetchActionType } from '../types/actions'
import { FETCH_RESOURCE_COMPLETE, SIGN_OUT } from '../actions/fetch'
import { RESET_ENV } from '../actions/ui'
import { assocPath, dissoc, path } from 'ramda'

const initialState = {
}

export default (state = initialState, action: FetchActionType) => {
  switch(action.type) {
    default: return state

    case FETCH_RESOURCE_COMPLETE:

      return assocPath(
        action.payload.resourcePath,
        action.payload.data,
        // If we add something into cart but we got sent order from before, remove it
        action.payload.id === 'product-to-cart' && path(['order-created'], state)
          ? dissoc('order-created', state)
          : action.payload.id === 'post-order' && path(['token'], action.payload.data)
          ? assocPath(['login', 'token'], path(['token'], action.payload.data), state)
          : state
      )

    case RESET_ENV:
      return assocPath(['env', 'data', 'lang'], path(['env', 'data', 'lang'], state) || '')(dissoc('env', state))

    case SIGN_OUT:
      return dissoc('env', dissoc('login', state))
  }
}
