import React from 'react'
import { replace } from 'ramda'
import { useHistory } from 'react-router-dom'
import { extractSearchParams } from '../../utils'
import { useDictionary } from '../../dictionary'

type ListItemProps = {
  children: any,
  name: string,
}

const ListItem = ({ children, name }: ListItemProps) => {
  const history = useHistory()

  return (
    <div className="mr-2 mb-1">
      { children }
      <i
        className="fas fa-times pl-1 clickable mr-decent"
        onClick={() => {
          history.push(
            replace(/\?&/, '?',
              replace(new RegExp(`&?offset=[^&]+`), '',
                replace(new RegExp(`&?${name}=[^&]+`), '', history.location.search || ''))))
        }}
      />
    </div>
  )
}

const HistoryFilterList = () => {
  const dictionary = useDictionary()
  const history = useHistory()
  const params = extractSearchParams(history.location.search)

  return (
    <div>
      {
        params.ddate_from
          ? <ListItem name="ddate_from"><strong>{dictionary.tables.orderHistoryList.delivery}:</strong> {dictionary.labels.from}: {params.ddate_from}</ListItem>
          : null
      }
      {
        params.ddate_to
          ? <ListItem name="ddate_to"><strong>{dictionary.tables.orderHistoryList.delivery}:</strong> {dictionary.labels.to}: {params.ddate_to}</ListItem>
          : null
      }
      {
        params.grossprice_from
          ? <ListItem name="grossprice_from"><strong>{dictionary.labels.price} {dictionary.labels.grossPrice}:</strong> {dictionary.labels.from}: {params.grossprice_from} Kč</ListItem>
          : null
      }
      {
        params.grossprice_to
          ? <ListItem name="grossprice_to"><strong>{dictionary.labels.price} {dictionary.labels.grossPrice}:</strong> {dictionary.labels.to}: {params.grossprice_to} Kč</ListItem>
          : null
      }
      {
        params.netprice_from
          ? <ListItem name="netprice_from"><strong>{dictionary.labels.price} {dictionary.labels.netPrice}:</strong> {dictionary.labels.from}: {params.netprice_from} Kč</ListItem>
          : null
      }
      {
        params.netprice_to
          ? <ListItem name="netprice_to"><strong>{dictionary.labels.price} {dictionary.labels.netPrice}:</strong> {dictionary.labels.to}: {params.netprice_to} Kč</ListItem>
          : null
      }
    </div>
  )
}

export default HistoryFilterList
