import React from 'react'
import { path } from 'ramda'
import FetchAndDisplayProductDetail from '../products/FetchAndDisplayProductDetail'

const OrderItem = () => {

  return <FetchAndDisplayProductDetail />
}

export default OrderItem
