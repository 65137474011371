import { path, replace } from 'ramda'
export const isDevelopment = process.env.NODE_ENV !== 'production'

// API config
// -----------------------------------------------------------------------
export const getApiPath = () => global.window.__CATERMAT_CONFIG__.api
export const getHost = () => global.window.__CATERMAT_CONFIG__.host || window.location.hostname
export const getSegment = () => global.window.__CATERMAT_CONFIG__.segment
export const getInstanceId = () => global.window.__CATERMAT_CONFIG__.instance
export const getExtsys = () => global.window.__CATERMAT_CONFIG__.extsys
export const anonymousLoginEnabled = () => path(['anonymous_login'], global.window.__CATERMAT_CONFIG__.params)
// -----------------------------------------------------------------------

export const switchSegmentLabel = (language) =>
  path(['lnkd_eshop_label', language], global.window.__CATERMAT_CONFIG__.params_lang)

export const switchSegmentLink = (language) =>
  path(['lnkd_eshop_url', language], global.window.__CATERMAT_CONFIG__.params_lang)

export const buildAbsoluteLink = hostname =>
  isDevelopment
    ? `http://${hostname}:3000`
    : `https://${hostname}`

// For development purpose we need to redirect images to the production server
export const buildImageSource = link =>
  isDevelopment
    ? `https://${getHost()}/${link}`
    : link
