import React from 'react'
import { path } from 'ramda'
import { useLocation } from 'react-router-dom'
import { Link } from '../components/Link'
import { useDictionary } from '../dictionary'
import { extractSearchParams } from '../utils'

const OrderSuccessPage = () => {
  const dictionary = useDictionary()
  const location = useLocation()
  const params = extractSearchParams(location.search || '')

  return (
    <div className="thank-you">
      <h1>{dictionary.thankYou.heading}</h1>
      <div><i className="fas fa-check big-icon" /></div>
      <h2>{dictionary.thankYou.title}</h2>
      <p>{dictionary.thankYou.description}</p>
      <p>{dictionary.thankYou.orderNumber}: <strong>{path(['order'], params) || ''}</strong></p>
      <div className="mt-2">
        {
          <Link to="/reset" className="btn btn--secondary ml-0">
            { dictionary.buttons.createNewOrder }
          </Link>
        }
      </div>
    </div>
  )
}

export default OrderSuccessPage
