import React from 'react'
import { connect } from 'react-redux'
import { Link } from '../Link'
import { setUi } from '../../store/actions/ui'
import { DialogType } from '../../store/types/ui'
import DialogWrapper from './DialogWrapper'
import { useDictionary } from '../../dictionary'

type Props = {
  dialog: DialogType,
  type: 'address' | 'time',
  setUi: (ui: { openedDialog: DialogType }) => void,
}

const ChangeWholeDelivery = ({ dialog, setUi, type }: Props) => {
  const dictionary = useDictionary()

  const dictDialog = type === 'address' ? dictionary.dialogs.changeDeliveryAddress : dictionary.dialogs.changeDeliveryTime

  return (
    <DialogWrapper className="dialog-content dialog-content--large">
      <div>
        <h1>{dictDialog.heading}</h1>
        <p>{dictDialog.info}</p>

        <div className="mt-2 text-center">
          <Link to="/reset" className="link link--underline mr-1" onClick={() => setUi({ openedDialog: { id: null } })}>
            {dictDialog.yes}
          </Link>

          <button className="btn btn--primary ml-1" onClick={() => setUi({ openedDialog: { id: null } })}>
            {dictDialog.no}
          </button>
        </div>
      </div>
    </DialogWrapper>
  )
}

export default connect(null, { setUi })(ChangeWholeDelivery)
