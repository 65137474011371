import React, { useEffect } from 'react'
import { path } from 'ramda'
import { connect } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { getContentData } from '../store/actions/api'
import PageContent from '../components/content/PageContent'
import ActualityList from '../components/content/ActualityList'

type Props = {
  getContentData: (a: string, b: string) => void,
}

const Content = ({ getContentData }: Props) => {
  const contentMatch = useRouteMatch('/content/:category')
  const match = useRouteMatch('/content/:category/:url')
  const url = path(['params', 'url'], match)
  const category = path(['params', 'category'], contentMatch)

  useEffect(() => {
    if (category && url) {
      getContentData(`${category}`, `${url}`)
    }
  }, [category, url, getContentData])

  if (category === 'news' && !url) {
    return <ActualityList />
  }

  if (!category || !url) {
    return null
  }

  return (
    <PageContent category={`${category}`} url={`${url}`} />
  )
}

export default connect(null, { getContentData })(Content)
