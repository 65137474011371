import React from 'react'
import { map } from 'ramda'

type ValueType = {
  label: string,
  value: string,
}

type Props = {
  value: string,
  onChange: any,
  options: ValueType[],
  hideIfOnlyOption?: boolean,
}

const SelectRadios = ({ value, onChange, options = [], hideIfOnlyOption, ...props}: Props) => map(option => {
  if (value && options.length === 1 && hideIfOnlyOption) {
    return null
  }
  return (
    <div key={option.value} className="d-block">
      <input
        type="radio"
        id={option.label}
        name="gender"
        value={option.value}
        onChange={onChange}
        checked={option.value === value}
        {...props}
      />
      <label htmlFor={option.label}>{option.label}</label>
    </div>
  )
}, options)

export default SelectRadios
