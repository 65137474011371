import React, { useEffect } from 'react'
import { map, path } from 'ramda'
import { connect } from 'react-redux'
import { Link } from '../Link'
import { getFooterData, GetFooterDataParams } from '../../store/actions/api'
import moment from 'moment'
import { useDictionary } from '../../dictionary'

const category = 'news'

type Props = {
  getFooterData: (params: GetFooterDataParams) => void,
  items: unknown,
}

const ActualityList = ({ getFooterData, items }: Props) => {
  const dictionary = useDictionary()
  useEffect(() => {
    getFooterData({
      limit: 5,
      category
    })
  }, [getFooterData])

  return (
    <td>
      {
        // @ts-ignore
        map((item: unknown) => (
          <div key={path(['id'], item)} className="mb-1">
            <div>{moment(path(['published'], item)).format('DD. MM. YYYY')}</div>
            <div><Link className="bold" to={`/content/${category}/${path(['url'], item)}#topMenu`}>{path(['name'], item)}</Link></div>
          </div>
        ), items || [])
      }
      <div className="mb-1">
        <Link to="/content/news#topMenu">
          {dictionary.footer.actualityMore}
        </Link>
      </div>
    </td>
  )
}

export default connect(state => ({
  items: path(['api', 'footer', category, 'data'], state),
}), { getFooterData })(ActualityList)
