import { useConfigParam } from '../hooks/config'

export const usePhone = (): string => {
  return useConfigParam('contact_phone')
}

export default (): any => {
  const phone = usePhone()
  return `${phone}`
}
