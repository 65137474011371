import React from 'react'
import { connect } from 'react-redux'
import { DialogType } from '../../store/types/ui'
import { AppStateType } from '../../store/types'
import AddToOrderDialog from './AddToOrderDialog'
import ProductPreviewDialog from './ProductPreview'
import ChangeDeliveryTimeDialog from './ChangeDeliveryTimeDialog'
import ChangeDatum from './ChangeDatum'
import ActualityDialog from './ActualityDialog'
import TermsAndConditionsDialog from './TermsAndConditions'
import ChangeWholeDelivery from './ChangeWholeDelivery'
import ChangeEnvironmentDialog from './ChangeEnvironmentDialog'
import PayOrderDialog from './PayOrderDialog'

type Props = {
  openedDialog: DialogType,
}

const Dialogs = ({ openedDialog }: Props) =>
  openedDialog.id === 'add-to-order'
  ? <AddToOrderDialog dialog={openedDialog} />
  : openedDialog.id === 'terms-and-conditions'
  ? <TermsAndConditionsDialog dialog={openedDialog} />
  : openedDialog.id === 'product-detail-zoom'
  ? <ProductPreviewDialog dialog={openedDialog} />
  : openedDialog.id === 'change-calendar'
  ? <ChangeDatum dialog={openedDialog} />
  : openedDialog.id === 'change-address'
  ? <ChangeWholeDelivery dialog={openedDialog} type={'address'} />
  : openedDialog.id === 'change-delivery-time'
  ? <ChangeDeliveryTimeDialog dialog={openedDialog} />
  : openedDialog.id === 'pay-order'
  ? <PayOrderDialog dialog={openedDialog} />
  : openedDialog.id === 'change-environment'
  ? <ChangeEnvironmentDialog />
  : openedDialog.id === 'first-actuality'
  // @ts-ignore
  ? <ActualityDialog dialog={openedDialog} />
  : null

export default connect((state: AppStateType) => ({
  openedDialog: state.ui.openedDialog,
}))(Dialogs)
